import { observer } from "mobx-react";
import {Box, Button, Table, Tbody, Td, Text, Th, Thead, Tr} from '@chakra-ui/react';
import { useTable } from 'react-table';
import { useMemo } from "react";
import { entries, isBoolean, isNull } from "lodash";
import {
  INIT_BUTTON_WIDTH,
  COLUMNS_WIDTH,
  SERVICES_COLUMNS,
  URL_COLUMN_NAME
} from "./constants";
import TextElementWithCopyButton from "src/components/TextElementWithCopyButton";
import Progress from "src/components/Progress";

const ServicesInfoTable = observer(({ servicesInfoData, handleRegisterConfigs, error }) => {
  const servicesInfoTableData = useMemo(() => servicesInfoData
    ? entries(servicesInfoData).map(([key, value]) => ({
        service: key,
        ...value
      }))
    : [], [servicesInfoData]);

  const columns = useMemo(() => {
    return (
      SERVICES_COLUMNS.map((field) => ({
        Header: field,
        accessor: field,
        Cell: ({ value }) => (
          isBoolean(value) ? value.toString() : value
        )
      })) || []
    );
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows
  } = useTable({
    columns,
    data: servicesInfoTableData.length ? servicesInfoTableData :  [],
    initialState: {
      hiddenColumns: ['canRegisterConfigs']
    }
  });

  return (
    <Box minHeight={"150px"} position="relative">
      {servicesInfoData
        ? <Table {...getTableProps()}>
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
                  {headerGroup.headers.map((column) => (
                    <Th
                      key={column}
                    >
                      {column.render('Header')}
                    </Th>
                  ))}
                  <Th />
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                const isCanRegisterConfigs = row.values.canRegisterConfigs;
                return (
                  <Tr {...row.getRowProps()} key={row.id}>
                    {row.cells.map((cell) => {
                      const columnName = cell.column.Header;
                      const isUrlColumn = columnName === URL_COLUMN_NAME;
                      const cellValue = cell.value;
                      return <Td
                        {...cell.getCellProps()}
                        key={`${cell.value}`}
                        w={COLUMNS_WIDTH[columnName] || 'auto'}
                      >
                        {isUrlColumn
                          ? <TextElementWithCopyButton
                              textValue={cellValue}
                              copyValue={cellValue}
                              label="Copy to buffer"
                              justifyContent="space-between"
                            />
                          : cell.render('Cell')
                        }
                      </Td>
                    })}
                    <Td textAlign="end">
                      {!isNull(isCanRegisterConfigs)
                        ? <Button
                            size='xs'
                            width={INIT_BUTTON_WIDTH}
                            colorScheme='green'
                            variant='solid'
                            onClick={() => handleRegisterConfigs([row.values.service])}
                            isDisabled={!isCanRegisterConfigs}
                          >
                            Init service
                          </Button>
                        : null
                      }
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        : error ? <Text color='tomato'>{error || null} </Text> : <Progress />
      }
    </Box>
  )
})

export default ServicesInfoTable
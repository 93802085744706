import { observer } from "mobx-react";
import { Tooltip, Box } from "@chakra-ui/react";
import { EyeOff } from "iconoir-react";

const ChangeVisibilityIcon = observer(({
  visibleColumns,
  updateVisibleColumns,
  column,
  ml,
  fieldName="Header"
}) => {

  return (
    <Box ml={ml}>
      <Tooltip label="Change visibility">
        <EyeOff
          cursor="pointer"
          onClick={(e) => {
            e.stopPropagation();
            const columnsUpdate = visibleColumns.filter(columnEl => columnEl !== column[fieldName]);
            updateVisibleColumns(columnsUpdate)
          }}
        />
      </Tooltip>
    </Box>
  )
})

export default ChangeVisibilityIcon
import {
  Box, Button,
  Td, Tr, Accordion,
  Checkbox,
  Flex, Text
} from '@chakra-ui/react';
import { ClipboardCheck, NavArrowDown, NavArrowUp, DatabaseRestore, Rocket, Cancel, DoubleCheck } from 'iconoir-react';
import { observer } from "mobx-react";
import { useEffect, Fragment, useState } from "react";
import ActionConfirmation from "../ActionConfirmation";
import { DeployStore, PermissionsStore, ProgressStore } from "../../../stores";
import {
  ENABLE_DEPLOY_BUTTON,
  STATIC_COLUMNS,
  VALID_ERRORS_BLOCK,
  VALID_STATE_COLUMN_NAME,
  VALID_STATE_WIDTH,
  WIDTH_FOR_BUTTON
} from './constants';
import {
  STATUS_GREEN_COLOR,
  INVALID_ERROR_STATUS,
  STATUS_RED_COLOR,
  STATUSES_FOR_REQUEST_ERRORS,
  VALID_STATUS,
  WARNING_STATUS,
  STATUS_YELLOW_COLOR
} from 'src/constants';
import ButtonWithTooltip from 'src/components/ButtonWithTooltip';
import DeployTagConfigInfo from './DeployTagConfigInfo';
import { getJSONstringify } from 'src/helpers/JSONSstringifyHelper';
import { copyToBuffer } from 'src/helpers/copyToBuffer';

const DeployTagConfigs = observer(({tag, state}) => {
  const [expandedIndex, setExpandedIndex] = useState([]);
  const { tagNames } = DeployStore;

  const [namesForTag, setNamesForTag] = useState(tagNames);

  useEffect(() => {
    if (STATUSES_FOR_REQUEST_ERRORS.includes(state)) {
      setNamesForTag([...tagNames, VALID_ERRORS_BLOCK])
    }
  }, [state, tagNames])

  return (
    <Accordion allowMultiple onChange={(index) => {
      setExpandedIndex(index)}}>
      {namesForTag.length
        ? namesForTag.map((tagName, index) => {
            return(
              <DeployTagConfigInfo
                tag={tag}
                tagName={tagName}
                isExpanded={expandedIndex.includes(index)}
              />
            )
          })
        : null
      }
    </Accordion>
  )
})

const DeployTableRow = observer(({row, setConfirmedDeployInfo, updateTagConfigs, visibleColumns, isExpanded, tag}) => {
  const { permissions } = PermissionsStore;
  const { showProgress } = ProgressStore;
  const { getConfigTagsJSON, restoreConfigTags, removeTag, validateTag, validationDataForTags } = DeployStore;

  const restoreConfig = async ( tag ) => {
    await restoreConfigTags( tag );
  };

  const [state, setState] = useState(row.values.state);

  const getBackroundColor = (state) => {
    switch (state) {
      case INVALID_ERROR_STATUS:
        return STATUS_RED_COLOR
      case VALID_STATUS:
        return STATUS_GREEN_COLOR
      case WARNING_STATUS:
        return STATUS_YELLOW_COLOR
      default:
        return 'inherit'
    }
  }

  const copyConfig = async ( tag ) => {
    const configJSON = await getConfigTagsJSON( tag );

    if(configJSON) {
      copyToBuffer(getJSONstringify(configJSON, '\t'))
    }
  };

  const restoreTagBody = (tag) => {
    return <div>
      {`Restore configs from `}
      <><b>'{tag}'</b> tag</>
    </div>
  }

  const tagRemove = tag => removeTag(tag);

  const validationState = validationDataForTags.getState(tag);

  useEffect(() => {
    setState(validationState || row.values.state)
  }, [validationState, row]);

  return (
    <Fragment>
      <Tr
        {...row.getRowProps()}
        backgroundColor={getBackroundColor(state)}
      >
         <Td>
          <Checkbox
            isChecked={row.isSelected}
            onChange={() => row.toggleRowSelected(!row.isSelected)}
          />
        </Td>
        <Td >
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ?
              <NavArrowDown /> :
              <NavArrowUp
                disabled={!permissions?.includes("can_view_tag_content")}
              />
            }
          </span>
        </Td>
        {row.cells.map((cell) => (
          <Td
            {...cell.getCellProps()}
          >
            {cell.column.Header === VALID_STATE_COLUMN_NAME
              ? <Flex flexWrap="wrap">
                  <Text width={VALID_STATE_WIDTH} whiteSpace="nowrap">{state}</Text>
                </Flex>
              : cell.render('Cell')}
          </Td>
        ))}

        <Td width={600} >
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            width='100%'
          >
            <Button
              size='xs'
              minW={WIDTH_FOR_BUTTON}
              colorScheme='green'
              variant='solid'
              leftIcon={<DoubleCheck />}
              onClick={() => validateTag(tag)}
            >
              Validate
            </Button>
            <Button
              size='xs'
              colorScheme='green'
              variant='solid'
              leftIcon={<ClipboardCheck />}
              onClick={() => copyConfig(tag)}
              isDisabled={showProgress}
              w={WIDTH_FOR_BUTTON}
            >
              Copy to buffer
            </Button>
            <ButtonWithTooltip
              size='xs'
              marginRight='0'
              colorScheme='green'
              variant='solid'
              leftIcon={<Rocket />}
              onClick={() => setConfirmedDeployInfo({tag: tag, state: state})}
              isDisabled={!(ENABLE_DEPLOY_BUTTON.includes(state)) || showProgress}
              w={WIDTH_FOR_BUTTON}
              accessPermission="can_upload_configs"
            >
              Deploy
            </ButtonWithTooltip>
            <ActionConfirmation
              size='xs'
              marginRight='0'
              variant='solid'
              isDisabled={showProgress}
              handleClick={() => restoreConfig(tag, updateTagConfigs)}
              dialogHeader='Restore'
              dialogBody={restoreTagBody(tag)}
              colorScheme='green'
              icon={<DatabaseRestore/>}
              value='Restore'
              w={WIDTH_FOR_BUTTON}
              accessPermission={"can_restore_from_tag"}
            />
            <ActionConfirmation
              handleClick={() => tagRemove(tag)}
              dialogHeader={`Remove`}
              dialogBody={`Tag name:  ${tag}`}
              colorScheme='red'
              icon={<Cancel/>}
              isDisabled={showProgress}
            />
          </Box>
        </Td>
      </Tr>

      {isExpanded ? (
        <Tr>
          <Td colSpan={visibleColumns.length + STATIC_COLUMNS}>
            <DeployTagConfigs tag={tag} state={state} />
          </Td>
        </Tr>
      ) : null}

    </Fragment>
  )
})

export default DeployTableRow
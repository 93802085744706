import { Box, Button, Spacer, Stack, Tooltip } from '@chakra-ui/react';
import { useColor } from '@gjn/react-uikit';
import { FastArrowLeft, FastArrowRight } from 'iconoir-react';
import { observer } from 'mobx-react';
import SidebarHeader from './SidebarHeader';
import SidebarMenu from './SidebarMenu';

const Sidebar = observer((props) => {
  const { panelBg } = useColor();
  const { size, isFullScreen, isCollapse, setIsCollapse } = props;

  return (
    <Box
      w={size}
      h="full"
      top="0"
      left="0"
      bg={panelBg}
      position="fixed"
      style={{ zIndex: isFullScreen ? 0 : 1000 }}
      id="sidebar"
    >
      <Stack h="full" gap="2">
        <SidebarHeader isCollapse={isCollapse} />
        <SidebarMenu isCollapse={isCollapse} />
        <Spacer />

        <Tooltip label={`${isCollapse ? 'Expand' : 'Collapse'} sidebar`}>
          <Button
            size="md"
            variant="none"
            onClick={() => setIsCollapse(!isCollapse)}
          >
            {isCollapse ? <FastArrowRight /> : <FastArrowLeft />}
          </Button>
        </Tooltip>
      </Stack>
    </Box>
  )
})

export default Sidebar
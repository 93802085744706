import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Select,
  FormErrorMessage,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton
} from "@chakra-ui/react"
import {observer} from "mobx-react";
import { useState, useRef, useEffect, useMemo } from 'react';
import { deployActions } from "src/components/Main/Deploy/deployActions";
import { DeployStore, FilterStore } from "src/stores";
import ConfirmOverwriteModal from "./ConfirmOverwrite";
import { json } from "@codemirror/lang-json";
import CodeMirror from '@uiw/react-codemirror';
import { materialDark } from 'cm6-theme-material-dark';
import { getJsonParse } from "src/helpers/JSONparseHelper";
import { activeLineColor } from "src/helpers/themeExtension";
import Combobox from "src/components/Combobox";
import "./style.scss";
import { Validator } from "jsonschema";
import { isArray } from "lodash";

const getSchemaFromPath = require('json-schema-from-path');
const TAG_DATA_TYPE_CURRENT = 'current';
const TAG_DATA_TYPE_IMPORT = 'import';

const ERROR_MESSAGE_FOR_INVALID_TAG = 'Tag is invalid';

const AddUpdateTagModal = observer(({ isOpen, setIsOpen }) => {
  const { tags, loadTagsSchema, tagsSchema, savedErrors, clearSavedErrors  } = DeployStore;
  const { clearFilter } = FilterStore;
  const initialRef = useRef(null);
  const [tagName, setTagName] = useState('');
  const [editorText, setEditorText] = useState('');
  const [tagDataType, setTagDataType] = useState(TAG_DATA_TYPE_CURRENT);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isOverwrite, setIsOverwrite] = useState(false);
  const [tagError, setTagError] = useState('');
  const [dataError, setDataError] = useState(false);

  const tagsList = useMemo(() => {
    return tags?.data?.map(el => ({value: el.data.tag})).reverse()
  }, [tags]);

  useEffect(() => {
    if (isOpen) {
      setTagName('');
      setIsOverwrite(false);
      setEditorText('');
      setDataError(false);
      setTagError('');
      loadTagsSchema();
      clearSavedErrors()
    }
  }, [isOpen, loadTagsSchema, clearSavedErrors]);

  const changeInsertData = (value) => {
    setTagError('');
    setEditorText(value)
  };

  const closeModals = () => {
    setIsOpen(false);
    setIsOpenConfirmModal(false);
    clearFilter()
  }

  const changeTag = async () => {
    if (tagName) {
      const actionName = deployActions(isOverwrite).saveAction;
      const res = await actionName(tagName);
      if (res) {
        closeModals();
      } else {
        setIsOpenConfirmModal(false);
      }
    }
  }

  const isValidJson = useMemo(() => {
    return getJsonParse(editorText)
  }, [editorText]);

  useEffect(() => {
    if (editorText.length) {
      setDataError(!isValidJson);
    }
  }, [editorText, isValidJson]);

  const isValid = useMemo(() => tagDataType === TAG_DATA_TYPE_CURRENT || isValidJson, [isValidJson, tagDataType]);
  const isValidTag = useMemo(() => tagName && !tagError, [tagName, tagError]);

  const changeTagWithInsertData = async () => {
    if (!tagName) {
      setTagError(true)
    } else if (isValidJson) {
      const configs = getJsonParse(editorText);
      const importAction = deployActions(isOverwrite).importAction;
      const res = await importAction( tagName, configs );
      if (!res) {
        setDataError(true);
        setIsOpenConfirmModal(false);
      } else {
        closeModals();
      }
    }
  };

  const changeTagName = (value) => {
    const validator = new Validator();
    const schemaForTag = getSchemaFromPath(tagsSchema, 'tag');
    const validResult = validator.validate(
      {tag: value},
      {properties: {tag: schemaForTag}}
    );
    if (schemaForTag.invalid_values.includes(value) || validResult.errors.length) {
      const errorsMessage = validResult.errors.map(el => el.message);
      setTagError(errorsMessage.length ? errorsMessage.join('; ') : ERROR_MESSAGE_FOR_INVALID_TAG);
    } else {
      setTagError('');
    }
    setIsOverwrite(!!tags.data.find(el => el.data.tag === value))
    setTagName(value)
  };

  const handleConfirmAtModal = tagDataType === TAG_DATA_TYPE_CURRENT ? changeTag : changeTagWithInsertData;

  return (
    <Modal
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      size="large"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create tag</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl isInvalid={tagError} pr={2}>
            <FormLabel>Enter tag name to create, or choose an existing tag to rewrite</FormLabel>
            {tagsList
              ? <Combobox
                maxW='100%'
                items={tagsList}
                placeholderMsg='Tag name'
                isApplyOtherSymbols={true}
                key='tag'
                value={tagName}
                setValue={changeTagName}
              />
              : null}
            {tagError ? <FormErrorMessage>{tagError}</FormErrorMessage> : null}
          </FormControl>
          <FormControl mt={5}>

            <Select onChange={(e) => setTagDataType(e.target.value)} value={tagDataType}>
              <option value={TAG_DATA_TYPE_CURRENT}>Use Current Configs</option>
              <option value={TAG_DATA_TYPE_IMPORT}>Import tag data</option>
            </Select>
          </FormControl>
          <FormControl isInvalid={dataError} className="overflow-code-editor">
            <FormLabel mt={5}>Insert data</FormLabel>
            <CodeMirror
              className={tagDataType === TAG_DATA_TYPE_CURRENT ? "disabled-code-editor" : ""}
              theme={materialDark}
              readOnly={tagDataType === TAG_DATA_TYPE_CURRENT}
              extensions={[
                json(),
                activeLineColor
              ]}
              value={'\n'}
              onChange={changeInsertData}
            />
            {dataError ? <FormErrorMessage>Data is invalid</FormErrorMessage> : null}
          </FormControl>
          {savedErrors?.length
            ? <Accordion mt={5} allowToggle>
                <AccordionItem>
                <AccordionButton>Error details</AccordionButton>
                <AccordionPanel>
                  {isArray(savedErrors)
                    ? savedErrors.join(';')
                    : savedErrors}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            : null
          }
        </ModalBody>
        <ModalFooter>
          <ConfirmOverwriteModal
              isOpenConfirm={isOpenConfirmModal}
              setIsOpenConfirm={setIsOpenConfirmModal}
              tagName={tagName}
              handleClickOk={handleConfirmAtModal}
            />
          <Button
            onClick={() => setIsOpenConfirmModal(true)}
            colorScheme='red'
            mr={3}
            isDisabled={!isValidTag || !isValid || !isOverwrite}
          >
            Overwrite
          </Button>
          <Button
            onClick={handleConfirmAtModal}
            colorScheme='blue'
            mr={3}
            isDisabled={!isValidTag || !isValid || isOverwrite}
          >
            Save
          </Button>
          <Button onClick={() => setIsOpen(false)}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
})

export default AddUpdateTagModal
import { Box } from "@chakra-ui/react";
import { observer } from 'mobx-react';
import { Fragment, useEffect, useState } from 'react';
import { Outlet, unstable_useBlocker as useBlocker, useLocation, useNavigate } from "react-router-dom";
import { Scrollbar } from 'react-scrollbars-custom';
import { COLLAPSE_SIDEBAR_WIDTH, SIDEBAR_WIDTH } from "src/constants/style";
import { AuthStore, ProgressStore } from "../../stores";
import Header from "../Header";
import Modals from "../Modals";
import Progress from "../Progress";
import ResponsesConsole from "../ResponsesConsole";
import Sidebar from "../Sidebar";
import UpdateNotificationMessage from "../UpdateNotificationMessage";

export const MainLayout = observer(() => {
  let navigate = useNavigate();
  const { user } = AuthStore;
  const { showProgress } = ProgressStore;

  const [isCollapse, setIsCollapse] = useState(false);

  useEffect(() => {
    if (!user) {
      navigate('/')
    }
  }, [user, navigate]);

  if (!user)
    return null;

  return (
    <Fragment>
      <Box h="100%">
        <Sidebar
          size={isCollapse ? COLLAPSE_SIDEBAR_WIDTH : SIDEBAR_WIDTH}
          isFullScreen={showProgress}
          isCollapse={isCollapse}
          setIsCollapse={setIsCollapse}
        />
        <Box ml={isCollapse ? COLLAPSE_SIDEBAR_WIDTH : SIDEBAR_WIDTH} h="100%" overflow="hidden">
          <Header />
          <Scrollbar style={{ height: "calc(100% - 56px)", zIndex: 3 }} id="main-layout">
            <UpdateNotificationMessage />
            <Box p={6}>
              <Outlet />
            </Box>
          </Scrollbar>
        </Box>
        <ResponsesConsole ml={isCollapse ? COLLAPSE_SIDEBAR_WIDTH : SIDEBAR_WIDTH} />
      </Box>
      <PagePreloader />
      <Modals />
      {showProgress
        ? <Progress isFullScreen = {showProgress} />
        : null
      }
    </Fragment>
  )
});

export const PagePreloader = observer(() => {
  const blocker = useBlocker(true);
  const location = useLocation();

  useEffect(() => {
    if (blocker.state === "blocked")
    {
      blocker.proceed();
    }
  }, [location, blocker]);

  return <Fragment />
});

export default MainLayout
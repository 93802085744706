/**
* Function for find parameter in object
* @param obj - object
* @param target - name of parameter
*/
export const findParameterInObject = <T extends object, K extends keyof T>(obj: T, target: K): undefined | T[K] => {
  return target && target in obj
    ? obj[target]
    : Object.values(obj).reduce((acc, val) => {
        if (acc !== undefined) return acc;
        if (typeof val === "object") return findParameterInObject(val as T, target as K);
        return undefined
      }, undefined);
}
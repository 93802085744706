import { observer } from 'mobx-react';
import Layout from './components/Layout';
import Authorization from './components/Authorization';

const App = observer(() => (
<Authorization>
  <Layout />
</Authorization>
))

export default App
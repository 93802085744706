import { Box, Divider, Heading } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useCallback, useEffect, useState } from "react";
import UserManagementTree from "./UserManagementTree";
import { UserManagementStore, GamesStore, PermissionsStore } from "../../../stores";
import { CLASSNAME_FOR_SCROLL_BLOCK } from "./constants";

const UserManagement = observer(() => {
  const { games_loaded, projectid, environment } = GamesStore;
  const { permissions } = PermissionsStore;
  const { clearUserProfile, loadUserInfoAndServices, getFromStorage, getAllStorageData } = UserManagementStore;

  const [isLoad, setIsLoad] = useState(true);
  const [error, setError] = useState('');

  const scrollToPosition = useCallback(() => {
    setTimeout(() => {
      const scrollElement = document.getElementsByClassName(CLASSNAME_FOR_SCROLL_BLOCK)[0];
      if (scrollElement) {
        scrollElement.scrollTop = getFromStorage('scrollTopValue') || 0;
      }
    }, 1000);
  }, [getFromStorage]);

  const fetchData = useCallback((typeForRequest, requestData) => {
    if (requestData) {
      setIsLoad(false)
      loadUserInfoAndServices(typeForRequest, requestData).then((res) => {
        setIsLoad(true)
        if (res) {
          setError(res);
        } else {
          scrollToPosition();
        }
      })
    }
  }, [scrollToPosition, loadUserInfoAndServices]);

  useEffect(() => {
    const storagesData = getAllStorageData();
    if (!storagesData && games_loaded) {
      clearUserProfile();
    }
    if (storagesData && games_loaded) {
      const requestData = storagesData.requestData;
      const typeForRequest = storagesData.typeForRequest;

      if (requestData && typeForRequest && permissions.length) {
        fetchData(typeForRequest, requestData);
      } else {
        if (!requestData) {
          clearUserProfile();
        }
      }
    }
  }, [
    permissions,
    getAllStorageData,
    clearUserProfile,
    fetchData,
    games_loaded,
    projectid,
    environment
  ]);

  return (
    <Box>
      <Heading as="h3" size="lg">
        User management
      </Heading>
      <Divider orientation="horizontal" />
        <Box mt={3}>
          <UserManagementTree
            fetchData={fetchData}
            scrollToPosition={scrollToPosition}
            isLoad={isLoad}
            error={error}
          />
        </Box>
    </Box>
  );
});

export default UserManagement;
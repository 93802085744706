import Rpc from '../services/Rpc';

class AuthApi {
  exchangeToken = ({ jwt }) =>
    Rpc.sendRequest('exchange_token', {
      jwt
    })

  signIn = ({ jwt }) =>
    Rpc.sendRequest('sign_in', {
      "jwt": jwt
    })
}

export default new AuthApi();
import { observer } from "mobx-react";
import { useMemo, useRef } from "react";
import { MultiSelect } from 'chakra-multiselect';
import { Box, Button, Collapse, Input, Text } from "@chakra-ui/react";
import { useOutsideClick } from '@chakra-ui/react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './style.scss';
import ColumnSettings from "./ColumnSettings";
import { loadFromDataToCsvFile } from "src/helpers/loadFromDataToCsvFile";
import { getJsonParse } from "src/helpers/JSONparseHelper";
import { entries } from "lodash";
import { NAME_FOR_CSV_FILE } from "./constants";

const ChangeVisibilityForColumns = observer(({
  columns,
  isOpen,
  fieldName = 'Header',
  onClose,
  className,
  visibleColumns,
  updateVisibleColumns,
  isMergeColumns = false,
  columnColors,
  setColor,
  isUseCsv = false
}) => {
  const Papa = require('papaparse');
  const collapseBlockRef = useRef();
  const loadCsvRef = useRef();

  useOutsideClick({
    ref: collapseBlockRef,
    handler: () => onClose(),
  })

  const servicesOptions = useMemo(() => {
    if (!columns.length) {
      return []
    }
    return columns.map((header, index) => {
      const headerValue = header[fieldName];
      return {id: index, value: headerValue, label: headerValue}
    })
  }, [columns, fieldName]);


  const handlerChangeVisibilityColumns= (a, change) => {
    let result;
    if (change.action === "multiRemove") {
      result = visibleColumns.filter(el => el !== change.value);
    }
    if (change.action === 'multiSelect') {
      result = [...visibleColumns, change.value.value];
    }
    if (change.action === "multiClear") {
      result = [];
    }
    updateVisibleColumns(result)
  }

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const reorderItems = Array.from(visibleColumns);
    const [move] = reorderItems.splice(result.source.index, 1);
    reorderItems.splice(result.destination.index, 0, move).map(el => ({...el}));

    updateVisibleColumns([...reorderItems])
  }

  const csvFileToArray = string => {
    const { data } = Papa.parse(string, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
    });
    data.forEach(dataElement => {
      const columns = dataElement.columns;
      const colors = dataElement.colors;
      if (columns) {
        updateVisibleColumns(getJsonParse(columns));
      }
      if (colors) {
        entries(getJsonParse(colors)).forEach(([key, value]) => {
          setColor(value, key)
        })
      }
    })
  };

  const handleOnChange = (e) => {
    const file = e.target.files[0];
    const fileReader = new FileReader();
    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };
      fileReader.readAsText(file);
    }
    loadCsvRef.current.value = '';
  };

  const handleExportToCsv = () => {
    const obj = {
      columns: visibleColumns, colors: columnColors
    }
    loadFromDataToCsvFile(obj, NAME_FOR_CSV_FILE)
  }

  return (
    <Box className={`change-visibility-block ${className}`}>
      <Collapse
        in={isOpen}
        animateOpacity
        ref={collapseBlockRef}
      >
        <Box
          mt={1}
          rounded='md'
          shadow='md'
          className="change-visibility-elements"
          p={1}
        >
          <MultiSelect
            options={servicesOptions}
            value={visibleColumns}
            onChange={handlerChangeVisibilityColumns}
            label="Change visibility"
          />
          <Box mt={2}>
            <Text fontSize='sm'>Change order</Text>
            <Text fontSize='xs' as="u">To reorder the columns, drag and drop them to desired position</Text>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="visibleItems" direction="horizontal">
                {(provided) => (
                  <Box
                    mt={4}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    p={4}
                    display="flex"
                    alignItems="self-start"
                    width="100%"
                    overflow="auto"
                    className="droppable-block"
                  >
                    {visibleColumns.map((item, index) => (
                      <Draggable key={item} draggableId={item} index={index}>
                        {(provided) =>(
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            mr={2}
                          >
                            {item}
                            {isMergeColumns
                              ? <ColumnSettings
                                  index={index}
                                  item={item}
                                  visibleColumns={visibleColumns}
                                  updateVisibleColumns={updateVisibleColumns}
                                  columnColors={columnColors}
                                  setColor={!!setColor ? setColor : null}
                                />
                              : null}
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>

          </Box>
          {isUseCsv ? <>
            <Button mb={3} onClick={handleExportToCsv}>Export to csv</Button>
            <Button mb={3} ml={3} position="relative">
              Load From CSV
              <Input
                type="file"
                onChange={handleOnChange}
                ref={loadCsvRef}
              />
            </Button>
          </> : null}
        </Box>
      </Collapse>
    </Box>
  )
})

export default ChangeVisibilityForColumns
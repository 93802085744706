import { DialogIsUnsavedChangesStore } from "src/stores";


export const handleEventWithChanges = (isChanges: boolean, event: () => void): void => {
  const { setDialogIsUnsavedChangesConfirm } = DialogIsUnsavedChangesStore;
  if (isChanges) {
    setDialogIsUnsavedChangesConfirm(event)
  } else {
    event()
  }
}
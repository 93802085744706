import { observer } from "mobx-react";
import { useForm } from "react-hook-form";
import { Flex, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { PermissionsStore, UserManagementStore } from "../../../stores";
import './style.scss';
import { getJsonParse } from "src/helpers/JSONparseHelper";
import CodeEditor from "src/components/CodeEditor";
import { useCallback, useEffect, useState } from "react";
import $RefParser from "@apidevtools/json-schema-ref-parser";
import { entries } from "lodash";
import { getDefaultParameterValue } from "src/helpers/makeOptionsFromData";
import { getJSONstringify } from "src/helpers/JSONSstringifyHelper";
import { getDefaultValueByLang } from "src/helpers/configLanguageHelper";
import { NAME_FOR_JSON_ACTION_DATA_STORAGE } from "./constants";
import { getJSONInt64Parse } from "src/helpers/getJSONInt64Parse";

const ActionItemForJSON = observer(({actionElement, actionJSONData, setActionJSONData}) => {
  const [schema, setSchema] = useState(null);

  useEffect(() => {
    const paramsSchema = actionElement.paramsSchema;
    if (paramsSchema) {
      $RefParser.dereference(paramsSchema).then(res => {
        setSchema(res);
      })
    }
  }, [actionElement]);

  useEffect(() => {
    if (schema && !actionJSONData) {
      const defaultInitObject = {};
      entries(schema.properties).forEach(([key, value]) => {
        if (value.first_init) {
          const defaultValue = value.init;
          const defaultParameterValue = getDefaultParameterValue(value, defaultValue);
          defaultInitObject[key] = defaultParameterValue;
        }
      });
      setActionJSONData(getJSONstringify(defaultInitObject));
    }
  }, [schema, setActionJSONData, actionJSONData])

  return (
    <CodeEditor
      schema={schema}
      newData={actionJSONData}
      data={getDefaultValueByLang()}
      isCheckChanges={false}
      updateChanges={setActionJSONData}
    />
  )
})

const ActionForm = observer(({elementId, actionElement, action, service}) => {
  const { callServiceApi, putToStorage, getFromStorage } = UserManagementStore;
  const { permissions } = PermissionsStore;
  const { register, handleSubmit } = useForm();
  const actionJSONStorage = getFromStorage(NAME_FOR_JSON_ACTION_DATA_STORAGE) || {};
  const [actionJSONData, setActionJSONData] = useState(actionJSONStorage[action] || '');

  const handleBeforeUnload = useCallback((actionJSONData) => {
    if (actionJSONData) {
      const actionJSONStorage = getFromStorage(NAME_FOR_JSON_ACTION_DATA_STORAGE) || {};
      actionJSONStorage[action] = actionJSONData;
      putToStorage(NAME_FOR_JSON_ACTION_DATA_STORAGE, actionJSONStorage)
    }
  }, [action, putToStorage, getFromStorage]);

  useEffect(() => {
    window.addEventListener('beforeunload', () => handleBeforeUnload(actionJSONData))
    return () => {
      handleBeforeUnload(actionJSONData);
      window.removeEventListener('beforeunload', () => handleBeforeUnload(actionJSONData))
    }
  }, [actionJSONData, handleBeforeUnload])

  const getInputType = (type) => {
    switch (type) {
      case 'int':
        return 'number';
      default:
        return 'text';
    }
  };

  const setParamsByType = (type, params) => {
    switch (type) {
      case 'string':
        return params
      default:
        return getJSONInt64Parse(params) || params
    }
  }

  const handleActionSubmit = (data) => {
    const {...actionParams} = data;
    callServiceApi(service, action, getJsonParse(actionJSONData) || actionParams)
  }

  const isEnableSubmitButton = !permissions.includes(`can_call_${service}_api`);

  return (
    <form
      id={elementId}
      className="service-actions__form"
      onSubmit={handleSubmit((data) => handleActionSubmit(data))}
    >
      {actionElement.paramsSchema
        ? <ActionItemForJSON
            actionElement={actionElement}
            actionJSONData={actionJSONData}
            setActionJSONData={setActionJSONData}
          />
        : actionElement.args?.map(arg =>  (
          <FormControl mt='10px'>
            <FormLabel>{arg.name}</FormLabel>
            <Flex gap='1'>
              <Input
                size="md"
                variant="outline"
                type={getInputType(arg.type)}
                placeholder={arg.type}
                {...register(arg.name, {
                  required: true,
                  setValueAs: (value) => setParamsByType(arg.type, value)})
                }
              />
            </Flex>
          </FormControl>)
        )}
      <Input
        size="md"
        variant="outline"
        type="submit"
        cursor='pointer'
        mt="10px"
        value="Send action"
        disabled={isEnableSubmitButton}
      />
    </form>
  )
})

export default ActionForm
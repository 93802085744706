import { Flex, IconButton, Text, Tooltip } from '@chakra-ui/react';
import { observer } from "mobx-react";
import { ClipboardCheck } from 'iconoir-react';
import { copyToBuffer } from 'src/helpers/copyToBuffer';

const TextElementWithCopyButton = observer(({textValue, copyValue, label, justifyContent = "initial"}) => {
  return (
    <Flex alignItems="center" justifyContent={justifyContent}>
      <Text fontSize='sm'>
        {textValue}
      </Text>
      <Tooltip label={label}>
        <IconButton
          ml={1}
          size='xs'
          variant='solid'
          icon={<ClipboardCheck />}
          onClick={() => copyToBuffer(copyValue)}
        />
      </Tooltip>
    </Flex>
  )
})

export default TextElementWithCopyButton
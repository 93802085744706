import {
  Button,
  Flex
} from "@chakra-ui/react";
import { observer } from 'mobx-react';
import { NotificationsStore } from "src/stores";
import './style.scss';

const UpdateNotificationMessage = observer(() => {
  const {showNotification, message, updateHandler} = NotificationsStore
  return (
    showNotification
      ? <Flex
          className="notificate-block"
          alignItems="center"
          justifyContent="space-between"
        >
        {message}
        <Button onClick={() => updateHandler()}>Update</Button>
      </Flex>
      : null
  )
})
export default UpdateNotificationMessage
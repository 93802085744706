import { DeployStore } from "src/stores";

export const deployActions = (overwrite: boolean) => {
  const {
    saveConfigsFromJson,
    overwriteConfigsFromJson,
    overwriteTag,
    addTag
  } = DeployStore;
 return {
  importAction: overwrite ? overwriteConfigsFromJson : saveConfigsFromJson,
  saveAction: overwrite ? overwriteTag : addTag
 }
}
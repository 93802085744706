import { Text } from "@chakra-ui/react";
import { observer } from "mobx-react";
import TextElementWithCopyButton from "src/components/TextElementWithCopyButton";
import { formatCreateTime } from "src/helpers/formatCreateTime";

const DeployTableCell = observer(({
  value: initialValue,
  row,
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {

  if (id === 'create_t' || id === 'update_t') {
    return (
      <TextElementWithCopyButton
        textValue={formatCreateTime(initialValue)}
        copyValue={initialValue}
        label="Copy as timestamp"
      />
    )
  }

  if (id === 'tag') {
    return (
      <Text whiteSpace="nowrap" fontSize='sm'>{initialValue}</Text>
    )
  }

  return initialValue
})

export default DeployTableCell
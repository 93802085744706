import { observer } from "mobx-react";
import { useState, forwardRef, Fragment } from "react";
import { useCombobox } from "downshift";
import { Input, List, ListItem, Flex, IconButton, Badge, Spacer } from "@chakra-ui/react";
import { NavArrowUp, NavArrowDown } from "iconoir-react";

const ComboboxInput = forwardRef(({ getInputProps, isApplyOtherSymbols, defaultValue, ...props }, ref) => {
  const value = !isApplyOtherSymbols ? defaultValue : props.value;
  return <Input
    style={{margin: 0}}
    {...props}
    value={value}
    ref={ref}
    data-testid={props.datatestid}
  />;
});

const ComboboxList = forwardRef(({ isOpen, ...props }, ref) => {
  const width = document.getElementById('combobox-input')?.offsetWidth || 300;
  return <List
    style={{
      position: 'absolute',
      marginTop: '35px',
      zIndex: '10',
      background: '#2d3748',
      borderRadius: '3px',
      width,
      borderStyle: 'solid',
      padding: '0'
    }}
    display={isOpen ? null : "none"}
    py={2}
    {...props}
    ref={ref}
  />;
});

const ComboboxItem = forwardRef(
  ({ itemIndex, highlightedIndex, ...props }, ref) => {
    const isActive = itemIndex === highlightedIndex;

    return (
      <ListItem
        transition="background-color 220ms, color 220ms"
        bg={isActive ? "rgb(61, 71, 86)" : null}
        px={4}
        py={2}
        cursor="pointer"
        {...props}
        ref={ref}
      />
    );
  }
);

const getItemsFilter = (item, inputValue) => {
  const lowerCasedInputValue = inputValue.toString().toLowerCase();

  const itemsFilter = !inputValue ||
      item.value.toString().toLowerCase().includes(lowerCasedInputValue);

  return itemsFilter;
}

const Combobox = observer((props) => {
  const {
    items,
    placeholderMsg,
    setValue,
    value,
    isDisabled,
    maxW,
    h,
    isApplyOtherSymbols,
    datatestid
  } = props;

  const [ inputItems, setInputItems ] = useState(items);

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps
  } = useCombobox({
    items: inputItems,
    onInputValueChange: ({inputValue}) => {
      const findItem = items.filter((item) => getItemsFilter(item, inputValue));
      if (isApplyOtherSymbols || findItem.length) {
        setInputItems(findItem);
        setValue(inputValue);
      }
    },
    itemToString(item) {
      return item ? item.value : ''
    },
    onSelectedItemChange: ({selectedItem: newSelectedItem}) => {
      setValue(newSelectedItem?.value);
    }
  });

  const handleToggleClick = () => {
    setInputItems(items);
  };

  return (
    <Flex direction="column" align="center" width={!maxW ? props?.w || '49%' : 'inherit'}>
      <Flex direction="column" flex="1 1 auto" width="100%">
        <Flex direction="row" alignItems="center" marginRight="1rem" >
          <ComboboxInput
            {...getInputProps({ onFocus: handleToggleClick })}
            placeholder={placeholderMsg}
            flex="0 0 auto"
            width="calc(100% - 30px)"
            mt={3}
            id="combobox-input"
            defaultValue={value}
            getInputProps={getInputProps}
            height={h}
            isDisabled={isDisabled}
            isApplyOtherSymbols={isApplyOtherSymbols}
            datatestid={datatestid}
          />
          <IconButton
            {...getToggleButtonProps({ onClick: handleToggleClick })}
            aria-label="combobox"
            height={h}
            colorScheme="gray"
            icon={isOpen ? <NavArrowUp /> : <NavArrowDown />}
          />
        </Flex>
        <ComboboxList
          isOpen={isOpen}
          {...getMenuProps()}
          flex={1}
          overflowY="auto"
          mt={0}
          className="combobox-list"
        >
          {inputItems.map((item, index) => (
            <ComboboxItem
              key={`${item.value}_${index}`}
              {...getItemProps({ item, index })}
              itemIndex={index}
              highlightedIndex={highlightedIndex}
            >
              <Flex>
                <Badge colorScheme='green'  textTransform="none">{item?.value}</Badge>
                <Spacer/>
                <Fragment>
                  {item?.context && (
                    <Badge colorScheme='purple'>
                      {item.context}
                    </Badge>
                  )}
                </Fragment>
              </Flex>
            </ComboboxItem>
          ))}
        </ComboboxList>
      </Flex>
    </Flex>
  )
});

export default Combobox;
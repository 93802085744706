import { Filter } from "iconoir-react";
import { observer } from "mobx-react";
import { FilterStore } from "../../stores";
import ButtonWithTooltip from "../ButtonWithTooltip";

const ShowFilterButton = observer(({onFilter, isFilter, variant, heightIcon, fill="inherit"}) => {
  const { queryValue, setIsShowFilters, isShowFilters } = FilterStore;
  const defaultHandler = () => setIsShowFilters(!isShowFilters);
  const defaultIsFilter = !!queryValue;

  return (
    <ButtonWithTooltip
      tooltipValue="Open filter"
      variant={variant}
      colorScheme={(isFilter || defaultIsFilter) ? "green" : "gray"}
      cursor="pointer"
      ml={1}
      onClick={onFilter || defaultHandler}
    >
      <Filter height={heightIcon}  fill={fill} />
    </ButtonWithTooltip>
  );
})

export default ShowFilterButton;
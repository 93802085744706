import { Button, Center, Heading, VStack } from '@chakra-ui/react';
import { GaijinLogo } from '@gjn/react-uikit';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthStore } from "../stores";
import { observer } from 'mobx-react';
import Storages from 'src/services/Storages';
import { DEFAULT_LINK } from 'src/constants';

const IndexScene = observer(() => {
  let navigate = useNavigate();
  const { user } = AuthStore;

  useEffect(() => {
    if (user?.token) {
      const current_pathname = Storages.get('current_pathname');
      const pathname = (current_pathname !== '/' && current_pathname) || DEFAULT_LINK;
      navigate(pathname);
    }
  })

  if (user)
    return null;

  const goToLogin = () => window.GSEA?.open();

  return (
    <Center h="100vh">
      <VStack gap="6">
        <GaijinLogo w="24" h="24" />
        <Heading as='h3' size='lg'>Gaijin Application Platform</Heading>
        <Button size="md" onClick={goToLogin}>Get started</Button>
      </VStack>
    </Center>
  )
})

export default IndexScene;
import { CircularProgress, Flex } from "@chakra-ui/react";
import { observer } from "mobx-react";
import './style.scss';

const Progress = observer(({isFullScreen, size = 50, justifyContent = "center"}) => {
  return (
    <Flex
      justifyContent={justifyContent}
      alignItems="center"
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      right={0}
      zIndex={999}
      className={isFullScreen ? "progress-block" : 'progress-block__small'}
    >
      <CircularProgress isIndeterminate color='gray.500' size={size} />
  </Flex>
  );
});

export default Progress;
import { Box, Button, Tooltip } from '@chakra-ui/react';
import { useColor } from '@gjn/react-uikit';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';

const SidebarMenuItem = observer((props) => {
  const { contrast } = useColor();
  const { icon, title, link, exact = false, transformIcon, isCollapse, isDisabled } = props;

  return (
    <NavLink to={link} end={exact}>
      {({ isActive }) => (
        <Tooltip label={isCollapse ? title : ''} placement="right">
          <div>
            <Button
              w="full"
              size="md"
              variant="ghost"
              colorScheme="gray"
              justifyContent="start"
              iconSpacing="4"
              h="12"
              borderLeftWidth={4}
              borderColor={isActive ? 'teal.500' : 'transparent'}
              rounded="none"
              isActive={isActive}
              onClick={() => {}}
              isDisabled={isDisabled}
              leftIcon={
                <Box as={icon} color={isActive ? contrast : 'gray.400'} size="20" transform={transformIcon} />
              }
            >
              {isCollapse ? '' : title}
            </Button>
          </div>
        </Tooltip>
      )}
    </NavLink>
  )
})

export default SidebarMenuItem
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  FormLabel,
  FormControl,
  FormHelperText
} from '@chakra-ui/react';

import { observer } from "mobx-react";
import { useRef, useState } from "react";
import { MenuPageSelectorsStore } from 'src/stores';

const AddPageSelectorModal = observer(({isOpenModal, setIsOpenModal}) => {
  const [error, setError] = useState(false);
  const {
    addPageSelector,
    pageSelectors,
    nameForAddingNewPageSelector,
    handleAddPageSelector
  } = MenuPageSelectorsStore;

  const initialRef = useRef();

  const onClose = () => setIsOpenModal(false);

  const handleAdd = () => {
    const {current} = initialRef;
    if (current) {
      const value = current.value;
      if (!pageSelectors.find(el => el.name === value)) {
        addPageSelector(value, true, value, true);
        onClose();
      } else {
        setError(true)
      }
      if (handleAddPageSelector) {
        handleAddPageSelector(value);
      }
    }
  }

  return (
    <Modal
      size='xl'
      blockScrollOnMount={true}
      isOpen={isOpenModal}
      onClose={onClose}
    >
      <ModalContent>
        <ModalHeader>{`Add ${nameForAddingNewPageSelector} item`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl>
            <FormLabel>{`${nameForAddingNewPageSelector} item name`}</FormLabel>
            <Input
              ref={initialRef}
              isInvalid={error}
              placeholder={`Enter ${nameForAddingNewPageSelector} item name`}
            />
            {error ? <FormHelperText>{`${nameForAddingNewPageSelector} type name already exist`}</FormHelperText>: null}
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme='blue' mr={3} onClick={handleAdd}>
            Ok
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
})

export default AddPageSelectorModal;
import { observer } from "mobx-react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  FormLabel
} from "@chakra-ui/react";
import './style.scss';
import ActionForm from "./ActionForm";
import { useEffect, useState } from "react";
import { entries } from "lodash";
import { UserManagementStore } from "src/stores";
import { MAIN_BLOCK_DEFAULT_MARGIN } from "./constants";

const serviceActionForm = (serviceActionElement, service) => {
  const { getFromStorage, putToStorage } = UserManagementStore;
  const error = serviceActionElement?.error;
  if (error !== undefined)
    return <FormLabel>{error}</FormLabel>

  const actionAccordionName = `${service}_action_accordion_id`;
  const actionAccordionNameFromStorage = getFromStorage(actionAccordionName);
  return (
    <Accordion
      allowMultiple
      allowToggle
      defaultIndex={
        actionAccordionNameFromStorage
          ? [...actionAccordionNameFromStorage]
          : [0]
      }
      onChange={(index) => putToStorage(actionAccordionName, index)}
    >
      {entries(serviceActionElement).map(([action, actionElement], index) => {
        const elementId = `${index}_${action}`;
        return (
          <AccordionItem key={elementId}>
            <AccordionButton
              width="100%"
              mb='5px'
            >
              <Flex flex='1' textAlign='left' className="service-actions__element-name">
                <span>{`${action}`}</span>
              </Flex>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <ActionForm
                elementId={elementId}
                actionElement={actionElement}
                action={action}
                service={service}
              />
            </AccordionPanel>
          </AccordionItem>
        )
      }
    )}
  </Accordion>
)}

const ServiceActions = observer(({serviceActions, service, userManagementBlockRef}) => {
  const [maxHeight, setMaxHeight] = useState(0);
  const { showWatchedBlock } = UserManagementStore;

  useEffect(() => {
    if (userManagementBlockRef.current) {
      const { height } = userManagementBlockRef.current.getBoundingClientRect();
      setMaxHeight(height - MAIN_BLOCK_DEFAULT_MARGIN)
    }
  }, [userManagementBlockRef, showWatchedBlock]);

  return (
    <div className="service-actions">
      <Box maxH={maxHeight}>
        {serviceActionForm(serviceActions, service)}
      </Box>
    </div>
  )
})

export default ServiceActions
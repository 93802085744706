
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Flex, IconButton, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { toast, ToastContainer, cssTransition } from 'react-toastify';
import {
  ERROR_STATUS,
  WARNING_STATUS,
  SUCCESS_STATUS
} from 'src/constants';
import { ClipboardCheck, NavArrowDown, NavArrowUp } from "iconoir-react";
import { ProgressStore, ResponsesHistoryStore } from 'src/stores';
import './style.scss';
import HistorySearch from './HistorySearch';
import {
  IN_PROGRESS_STATUS_FOR_STRING,
  PROGRESS_STATUS,
  SCROLL_TO_BOTTOM_DEFAULT,
  OPENED_DEFAULT_HEIGHT,
  SUCCESS_STATUS_FOR_STRING,
  RIGHT_PADDING,
  SKIPPED_STATUS,
  TIMER_FOR_CLEAR_CURRENT_CONSOLE_ERROR
} from './constants';
import { List, CellMeasurer } from 'react-virtualized';
import { getJsonParse } from 'src/helpers/JSONparseHelper';
import { isObject, toString } from 'lodash';
import JSONPretty from 'react-json-pretty';
import ButtonWithTooltip from '../ButtonWithTooltip';
import * as copy from "copy-to-clipboard";


const ResponsesConsole = observer(({ml}) => {
  const {
    responsesHistory,
    responsesErrors,
    cosoleWidth,
    setConsoleWidth,
    cache
  } = ResponsesHistoryStore;
  const { showProgress } = ProgressStore;
  const [isOpen, setIsOpen] = useState(false);
  const toastId = useRef(null);
  const listRef = useRef(null);

  const getWidthConsole = useCallback(() => {
    if (document.getElementById('sidebar')) {
      const countWidth = window.innerWidth - document.getElementById('sidebar').clientWidth;
      setConsoleWidth(countWidth);
    }
  }, [setConsoleWidth]);

  useEffect(() => {
    window.addEventListener('resize', getWidthConsole);
    return () => window.removeEventListener('resize', getWidthConsole);
  }, [getWidthConsole]);

  useEffect(() => {
    getWidthConsole();
  }, [getWidthConsole]);

  const scrollToBottom = useCallback(() => {
    if (listRef && listRef.current && responsesHistory.length) {
      listRef.current.scrollToRow(responsesHistory.length - 1)
    }
  }, [listRef, responsesHistory.length]);

  const clearToast = () => {
    if (toastId.current) {
      toast.dismiss(toastId.current);
      toastId.current = null;
      setIsOpen(false);
    }
  };

  const fade = useMemo(() => cssTransition({
    enter: "fade-in",
    exit: "fade-out"
  }), []);

  const options = useMemo(() => ({
    position: "bottom-center",
    className: 'toast-messages',
    theme: "dark",
    onClose: clearToast,
    autoClose: false,
    closeOnClick: false,
    closeButton: false,
    transition: fade
  }), [fade]);

  const rowRenderer = ({
    key,
    index,
    isVisible,
    style,
    parent
  }) => {
    const { getHistoryElement, removeResponsesErrors } = ResponsesHistoryStore;
    const element = getHistoryElement(index);

    if(isVisible) {
      removeResponsesErrors(element.responseId);
    }

    return (
      <CellMeasurer
        key={key}
        parent={parent}
        cache={cache}
        columnIndex={0}
        rowIndex={index}
      >
        {({measure, registerChild}) => (
          <div style={style} onLoad={measure} ref={registerChild}>
            <ConsoleInfoElement data={element} index={index} />
          </div>
        )}
      </CellMeasurer>
    );
  }

  const ConsoleItem = observer(() => {
    const { responsesHistory, cosoleWidth } = ResponsesHistoryStore;

    return <List
      ref={listRef}
      width={cosoleWidth - RIGHT_PADDING}
      height={OPENED_DEFAULT_HEIGHT}
      deferredMeasurementCache={cache}
      rowCount={responsesHistory.length}
      rowHeight={cache.rowHeight}
      rowRenderer={(props) => rowRenderer(props, responsesHistory)}
    />
  });

  const renderConsole = (consoleData) =>
   <ConsoleItem consoleData={consoleData} />;

  const handleClick = useCallback((delay) => {
    if (!toastId.current) {
      toastId.current = toast(renderConsole(responsesHistory), options);
      setIsOpen(true)
      setTimeout(() => {
        scrollToBottom();
      }, delay || SCROLL_TO_BOTTOM_DEFAULT);
    } else {
       !showProgress && clearToast()
    }
  }, [responsesHistory, options, showProgress, scrollToBottom]);

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, SCROLL_TO_BOTTOM_DEFAULT);
  }, [responsesHistory, scrollToBottom])

  useEffect(() => {
    if (showProgress) {
      handleClick();
    }
  }, [showProgress, handleClick]);


  const getColorsClass = (status) => {
    switch (status) {
      case SUCCESS_STATUS:
        return 'console-info-block_success'
      case WARNING_STATUS:
          return 'console-info-block_warning'
      case ERROR_STATUS:
        return 'console-info-block_error'
      case SKIPPED_STATUS:
        return 'console-info-block_skipped'
      default:
        return ''
    }
  };

  const getStatusString = (status) => {
    switch (status) {
      case SUCCESS_STATUS:
        return SUCCESS_STATUS_FOR_STRING
      case PROGRESS_STATUS:
        return IN_PROGRESS_STATUS_FOR_STRING
      default:
        return status
    }
  };

  const getIndentStr = (level) => {
    let result = '';
    for (let i = 1; i <= level; i++) {
      result += '>>'
    }
    return result
  }

  const infoStringElement = (data) => {
    const status = data.type;
    const isProgressStatus = status === PROGRESS_STATUS;
    return (
      <span key={`string-${data.action}-${status}-${data.source}`}>
        <>
          {isProgressStatus ? data.source : ''}
          <span className={getColorsClass(status)}>
            {`${getStatusString(status)}`}
           </span>
          {!isProgressStatus ? '; ' : ''}
        </>
      </span>
    )
  }

  const consoleInfoString = (data, level) => {
    return (
      <span className='console-info-string' key={`string-${data.action}-${data.type}-${data.source}`}>
         <>
          {`${getIndentStr(level)} ${data.action}: `}
          {infoStringElement(data)}
          {getJsonParse(data.autocompleteStringValue)?.map(el => {
            return infoStringElement(el)
          })}
        </>
      </span>
    )
  }

  const ConsoleInfoElement = observer(({data, showInfo = true}) => {
    const errorInfoInResponse = data?.subStatus;

    useEffect(() => {
      let timer;
      if (!showInfo && data.type === ERROR_STATUS) {
        const { removeResponsesErrors } = ResponsesHistoryStore;
        timer = setTimeout(() => {
          removeResponsesErrors(data.responseId);
        }, TIMER_FOR_CLEAR_CURRENT_CONSOLE_ERROR)
      };
      return () => {
        timer && clearTimeout(timer)
      }
    }, [showInfo, data]);

    const getTableView = (data) => {
      return (
        <TableContainer>
          <Table variant="simple" width="auto">
            <Thead>
              <Tr>
                {data.titles.map(title => {
                  return <Th key={title}>
                    <span>{title}</span>
                  </Th>
                })}
              </Tr>
            </Thead>
            <Tbody>
              {data.items.map((item, index) => {
                return <Tr key={`${index}_item`}>
                  {item.map(itemValue => {
                    return <Td key={itemValue}>
                      <span>{itemValue}</span>
                    </Td>
                  })}
                </Tr>
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )
    }

    const renderResponse = (data) => {
      if (data.info && showInfo) {
        const dataInfo = data.info;
        const isObjectInfo = isObject(dataInfo);
        const info = isObjectInfo ? JSON.stringify(dataInfo, null, 2) : toString(dataInfo);

        if (!data.isTableResponse) {
          return <Box
            className={`response-text ${errorInfoInResponse ? `response-text-${errorInfoInResponse}` : ''}`}
          >
            <span>response:</span>
            {isObjectInfo
              ? <JSONPretty
                style={{lineHeight: "16px"}}
                data={info} />
              : toString(info)
            }
            <Flex
              position="absolute"
              top={0}
              right="10px"
              justifyContent="center"
              alignItems="center"
            >
              <ButtonWithTooltip
                tooltipValue="Copy response to buffer"
                onClick={() => copy(info)}
                size="xs"
              >
                <ClipboardCheck />
              </ButtonWithTooltip>
            </Flex>
          </Box>
        } else {
          return getTableView(getJsonParse(info))
        }
      }
      return null;
    }

    return data.autocompleteString
      ? consoleInfoString(data, data.level)
      : <div
          key={`viewport-${data.name}-${data.type}-${data.date}`}
        >
          <Flex
            key={`${data.name}-${data.type}-${data.date}`}
            justifyContent="center"
            className={`console-info-block ${getColorsClass(data.type)}`}
          >
            {data.date && showInfo
              ? <span className='console-info-block__date-time'>{data.date}</span>
              : null
            }
            <span>
              {`${getIndentStr(data.level)} ${data.action}: ${data.type}`}
              <span className={errorInfoInResponse ? `console-info-block__response-${errorInfoInResponse}` : ''}>
                {` ${data?.errorInfo || ''}`}
              </span>
            </span>
            {renderResponse(data)}
          </Flex>
        </div>
    });

  const lastHistoryElement = responsesHistory[responsesHistory.length - 1];

  return (
    <Box ml={ml} className="console-main-block">
      <Flex
        pl="2"
        pr="2"
        h={35}
        id="console"
        flexDirection="column"
        className={responsesErrors.length ? 'with-error' : ''}
      >
        {responsesHistory.length && !isOpen
          ? <ConsoleInfoElement data={lastHistoryElement} showInfo={false} />
          : <HistorySearch
              isOpen={isOpen}
              toastId={toastId}
              scrollToBottom={scrollToBottom}
            />
        }
        <IconButton
          className='console-main-block__button'
          onClick={() => handleClick(1)}
          aria-label="combobox"
          variantColor={"teal"}
          disabled={!isOpen && toastId.current}
          icon={isOpen ? <NavArrowDown /> : <NavArrowUp />}
        />
      </Flex>
      <ToastContainer className="toast-console" draggable={false} limit={1} style={{width: cosoleWidth}} />
    </Box>
)});

export default ResponsesConsole

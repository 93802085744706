import { action, computed, flow, makeObservable, observable } from "mobx";
import { LANGUAGE, getNameFromFunction } from "src/helpers/configLanguageHelper";
import { ConfigElementStore, ConfigsViewStore, GamesStore, SchemaStore } from ".";
import configsApi from "../api/configs";
import { entries, keys, omit } from "lodash";

class ConfigsStore {
  constructor() {
    this.configs = null;
    this.successUpdateTime = -1;
    this.jsonErrors = {
      errors: {},
      set(key, value) {
        this.errors = {
          ...this.errors,
          [key]: value
        };
      },

      get(key) {
        return this.errors[key]?.length > 0;
      },

      clear() {
        this.errors = {};
      }
    };
    this.configsChanges = {
      changes: {},
      set(key, isChanged, changedData, changedLines) {
        this.changes = {
          ...this.changes,
          [key]: {isChanged, changedData, changedLines}
        };
      },

      get(key) {
        return this.changes[key];
      },

      remove(key) {
        delete this.changes[key]
      },

      clear() {
        this.changes = {};
      }
    };

    this.namespaceList = [];
    this.isNeedUpdateConfigs = false;

    makeObservable(this, {
      configs: observable,
      successUpdateTime: observable,
      jsonErrors: observable,
      configsData: computed,
      loadConfigs: flow.bound,
      clearConfigs: action.bound,
      findNewElements: computed,
      loadNamespaceList: flow.bound,
      namespaceList: observable,
      saveAllConfigList: flow.bound,
      updateUniqueValues: action.bound,
      removeChecked: flow.bound,
      setFields: action.bound,
      updateSuccessUpdateTime: action.bound,
      isConfigChanges: computed,
      setIsNeedUpdateConfigs: action.bound,
      isNeedUpdateConfigs: observable,
      configsChanges: observable,
      getChangedFields: action.bound,
      loadConfigList: flow.bound,
      uploadConfigList: flow.bound,
      getLoadAction: action.bound,
      getUploadAction: action.bound,
      setConfigs: action.bound
    });
  }

  updateSuccessUpdateTime(value) {
    this.successUpdateTime = value
  }

  setIsNeedUpdateConfigs(value) {
    this.isNeedUpdateConfigs = value
  }

  get configsData() {
    return this.configs?.data || [];
  }

  get findNewElements() {
    return this.configs?.data?.filter(dataElement => dataElement.isNewAddedElement)
  }

  updateUniqueValues() {
    const { updateUniqueValuesFromData } = SchemaStore;
    let dataToUpdate = this.configs.data;
    if (this.isConfigChanges) {
      dataToUpdate = this.configs.data.map(el => {
        const { key } = el.internal;
        const changedData = this.configsChanges.get(key)?.changedData;
        if (changedData) {
          return {...el, data: this.setFields(changedData)}
        }
        return el
      })
    }

    updateUniqueValuesFromData({data: dataToUpdate});
  }

  setFields(fields) {
    const { language } = ConfigsViewStore;
    switch (language) {
      case LANGUAGE.JS:
        return { body: fields, name: getNameFromFunction(fields) }
      default:
        return JSON.parse(fields)
    }
  }

  *loadConfigs( configName, namespace, query, abortController ) {
    const { projectid, environment } = GamesStore;
    const { setKeys, setAbortController } = ConfigsViewStore;
    setAbortController(abortController.current);

    try {
      const { data } = yield configsApi.getConfig({ projectid, environment, configName, namespace, query });
      this.configs = setKeys(data) || [];
      this.successUpdateTime = data.successUpdateTime;
      this.updateUniqueValues();
      this.isNeedUpdateConfigs = false;
      return data
    } catch (error) {
      this.configs = [];
    }
  }


  *removeChecked( elementName ) {
    const { projectid, environment } = GamesStore;
    const { getConfigElement, getElementDataByKey } = ConfigElementStore;
    const { checkedConfigs, clearConfigsChecked, setExpendedRows } = ConfigsViewStore;

    const changedFields = [...checkedConfigs].map(el => {
      const config = getElementDataByKey(el);
      if (config) {
        const { hash, _id } = config.internal;
        return {
          id: _id,
          hash,
          projectid,
          environment,
          successUpdateTime: this.successUpdateTime,
          action: `remove_${elementName}`,
        }
      }
      return []
    });

    try {
      const { data } = yield configsApi.removeAll({ changedFields });

      data.forEach((dataElement) => {
        if (dataElement.result && !dataElement.result.error) {
          const config = getConfigElement(dataElement.id);
          this.configs.data = this.configs.data.filter((el) => el.internal._id !== config.internal._id);
          this.successUpdateTime = dataElement.result.successUpdateTime;
        }
      })
      this.jsonErrors.clear();
      this.configsChanges.clear();
      this.updateUniqueValues();
      clearConfigsChecked();
      setExpendedRows({});
    } catch (error) {
      console.log(error);
    }
  }


  *loadNamespaceList( elementName ) {
    const { environment, projectid } = GamesStore;
    try {
      const { data } = yield configsApi.getNamespaceList({ projectid, environment, elementName });
      this.namespaceList = data;
      return data
    } catch (error) {
      console.log(error)
    }
  }

  getChangedFields(key, fields, elementName) {
    const { projectid, environment } = GamesStore;
    const { getElementDataByKey } = ConfigElementStore;

    const element = getElementDataByKey(key);
    if (!element) {
      return {}
    }
    const { _id: id, hash } = element.internal;
    return {
      fields,
      id: element.isNewAddedElement ? undefined : id,
      hash,
      projectid,
      environment,
      successUpdateTime: this.successUpdateTime,
      action: id && hash ? `change_${elementName}` : `add_${elementName}`,
      key: element.internal.key
    }
  }

  *saveAllConfigList( elementName, changesData = null ) {
    const { updateConfigValues, getElementDataByKey } = ConfigElementStore;

    try {
      let changedFields = [];
      if (!changesData) {
        changedFields = entries(this.configsChanges.changes).map(([key, value]) => {
          return this.getChangedFields(key, this.setFields(value.changedData), elementName)
        })
      } else {
        changedFields = changesData.map(el => {
          return this.getChangedFields(el.key, omit(el, 'key'), elementName)
        })
      }
      const { data } = yield configsApi.saveAll({ changedFields });

      changedFields.forEach((changeElement) => {
        const dataElement = data.find(el => el.id === changeElement.key || el.id === changeElement.id);

        if (dataElement.result && !dataElement.result.error) {
          const config = getElementDataByKey(changeElement.key);
          updateConfigValues(config, dataElement.result, changeElement.fields);
        }
      })

      this.configsChanges.clear();
    } catch (error) {
      console.log(error);
    }
  }

  get isConfigChanges() {
    return keys(this.configsChanges.changes)?.length > 0 || this.findNewElements?.length
  }

  *loadConfigList( elementName, groupByNamespace, configName ) {
    const { projectid, environment } = GamesStore;

    try {
      const action = this.getLoadAction();
      const { data } = yield action({
        projectid,
        environment,
        elementName,
        groupByNamespace,
        configName
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  *uploadConfigList( config, elementName, configName) {
    const { projectid, environment } = GamesStore;

    try {
      const action = this.getUploadAction();
      const { status } = yield action(
        { projectid, environment, elementName, config, configName });

      return status;
    } catch (error) {
      console.log(error);
    }
  }

  getUploadAction() {
    const { language } = ConfigsViewStore;
    switch (language) {
      case LANGUAGE.JS:
        return configsApi.uploadConfigListJS
      default:
        return configsApi.setConfigList
    }
  }

  getLoadAction() {
    const { language } = ConfigsViewStore;
    switch (language) {
      case LANGUAGE.JS:
        return configsApi.downloadConfigJS
      default:
        return configsApi.getConfigList
    }
  }

  setConfigs(configs) {
    this.configs = configs
  }

  clearConfigs() {
    const { clearConfigElement } = ConfigElementStore;
    const { clearConfigsConfiguration } = ConfigsViewStore;
    this.configs = null;
    this.jsonErrors.clear();
    this.configsChanges.clear();
    clearConfigsConfiguration();
    clearConfigElement();
  }
}

export default new ConfigsStore();
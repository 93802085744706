import { flow, makeObservable, observable, action } from "mobx";
import singleConfig from "src/api/singleConfig";
import { GamesStore } from ".";
import { LANGUAGE } from "src/helpers/configLanguageHelper";

class SingleConfigStore {
  constructor() {
    this.configData = null;
    this.schema = null;
    this.isSingleConfigChanged = false;
    this.language = LANGUAGE.JSON;

    makeObservable(this, {
      configData: observable,
      schema: observable,
      isSingleConfigChanged: observable,
      setIsSingleConfigChanged: action.bound,
      loadSingleConfig: flow.bound,
      loadSingleConfigSchema: flow.bound,
      changeSingleConfig: flow.bound,
      clearSingleConfig: action.bound,
      language: observable,
      setLanguage: action.bound
     });
  }

  setIsSingleConfigChanged(value) {
    this.isSingleConfigChanged = value;
  }

  *loadSingleConfig(elementName) {
    const { projectid, environment } = GamesStore;
    try {
      const { data } = yield singleConfig.getConfigData({ projectid, environment, elementName });
      this.configData = data || [];

      return data
    } catch (e) {
      this.configData = [];
    }
  }

  *changeSingleConfig( fields, elementName ) {
    const { projectid, environment } = GamesStore;

    try {
      const { status} = yield singleConfig.setConfigData({ projectid, environment, fields, elementName});
      this.configData = fields;
      this.isSingleConfigChanged = false;

      return status
    } catch (error) {
      console.log(error);
    }
  }

  *loadSingleConfigSchema(elementName) {
    const { projectid, environment } = GamesStore;
    try {
      const { data } = yield singleConfig.getSchema({ projectid, environment, elementName });
      this.schema = data || [];
    } catch (e) {
      this.schema = [];
    }
  }

  setLanguage(lang) {
    this.language = lang;
  }

  clearSingleConfig() {
    this.configData = null;
    this.schema = null;
    this.isSingleConfigChanged = false;
  }
}

export default new SingleConfigStore();
const SPLITTER = ':';

export const replaceExtraSymbols = (string: string): string => {
  return string?.replace(/[",\s]/ig, "");
}

const _substringString = (string: string, indexA: number, indexB?: number): string => {
  return replaceExtraSymbols(string.substring(indexA, indexB));
}

// TODO: add to errorMarker
// export const getParameterPair = (parametrString: string): string[] => {
//   const index = parametrString.indexOf(SPLITTER);
//   return [_substringString(parametrString, 0, index), _substringString(parametrString, index + 1)];
// }

/**
* Function for get parameter name without special symbols
*/
export const getParameterName = (parametrString: string): string => {
  return _substringString(parametrString, 0, parametrString.indexOf(SPLITTER));
}

/**
* Function for get parameter value without special symbols
*/
export const getParameterValue = (parametrString: string): string => {
  return _substringString(parametrString, parametrString.indexOf(SPLITTER) + 1);
}
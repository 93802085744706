import { observer } from "mobx-react"
import { Tooltip } from "@chakra-ui/react";
import { EyeEmpty } from "iconoir-react";
import { MAX_WATCHED_VALUES_LENGTH } from "../constants";

const EyeEmptyIcon = observer(({watchedVariables, setWatchedVariables, watchedElement}) => (
  <Tooltip label="watched value">
    <EyeEmpty
      className="watched-value-icon"
      onClick={() => {
        if (watchedVariables.length < MAX_WATCHED_VALUES_LENGTH) {
          setWatchedVariables([...watchedVariables, watchedElement])
        }
      }}
    />
  </Tooltip>
));

export default EyeEmptyIcon;
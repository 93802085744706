import { observer } from "mobx-react";
import {
  Button,
  Center,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  SimpleGrid,
  Input,
  Flex
} from "@chakra-ui/react";
import { COLORS } from "./constants";

const ColorPicker = observer(({
color,
setColor,
coloredElement
}) => {

  return (
    <Flex alignItems="center" ml={3} mr={3} mb={2}>
      <Text fontSize='sm' mr={2}>{`Select color ${coloredElement || ''}`}</Text>
      <Popover variant="picker" placement="left-start">
        <PopoverTrigger>
          <Button
            aria-label={color}
            background={color}
            height="22px"
            width="22px"
            padding={0}
            minWidth="unset"
            borderRadius={3}
          />
        </PopoverTrigger>
        <PopoverContent width="200px">
          <PopoverArrow bg={color} />
          <PopoverCloseButton color="white" />
          <PopoverHeader
            height="100px"
            backgroundColor={color}
            borderTopLeftRadius={5}
            borderTopRightRadius={5}
            color="white"
          >
            <Center height="100%">{color}</Center>
          </PopoverHeader>
          <PopoverBody height="120px">
            <SimpleGrid columns={5} spacing={2}>
              {COLORS.map((colorElement) => (
                <Button
                  key={colorElement}
                  aria-label={colorElement}
                  background={colorElement}
                  height="22px"
                  width="22px"
                  padding={0}
                  minWidth="unset"
                  borderRadius={3}
                  _hover={{ background: colorElement }}
                  onClick={() => {
                    setColor(colorElement);
                  }}
                />
              ))}
            </SimpleGrid>
            <Input
              borderRadius={3}
              marginTop={3}
              placeholder="red.100"
              size="sm"
              value={color}
              onChange={(e) => {
                setColor(e.target.value);
              }}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  )
})

export default ColorPicker
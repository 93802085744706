import { entries, isArray, isObject, setWith } from 'lodash';

export const searchByTree = (tree: object, val: string): object => {
  const result = {};

  const findPath = (branch: object, str: string, val: string) => {
    entries(branch).forEach(([key, value]) => {
      const newStr = str ? `${str}.${key}` : key;
      const objectKey = key as keyof object;
      const findInKey = String(objectKey).toLowerCase().includes(val);
      const item = branch[objectKey];
      if (!findInKey && (isArray(item) || isObject(item))) {
        findPath(item, newStr, val);
      } else {
        if (findInKey || String(value).toLowerCase().includes(val)) {
          setWith(result, newStr, value, Object)
        }
      }
    });
  }

  findPath(tree, '', val);

  return result;
}

import { flow, makeObservable, observable, action } from "mobx";
import { GamesStore } from ".";
import userManagementApi from "../api/userManagement";
import Storages from "src/services/Storages";
import { GET_USER_PROFILE_ERROR_MESSAGE, PROFILE_IS_EMPTY } from "src/components/Main/UserManagement/constants";
import { values, isEmpty, omit } from "lodash";
import { v4 as uuidv4 } from 'uuid';
import { GROUP_COLORS } from "src/components/Main/UserManagement/HistoryInfiniteLoader/constants";

const RELOAD_API_AFTER_ACTION = 'create_profile';

class UserManagementStore {
  constructor() {
    this.userid = 0;

    this.services = [];
    this.servicesWithHistory = [];
    this.data = {};
    this.actions = {};
    this.userInfo = {};
    this.reloadServicesAfterAction = [];
    this.userServiceError = {};
    this.showWatchedBlock = !!this.getFromStorage('watchedBlockHeight') || false;
    this.startPos = [];
    this.historyColumns = [];
    this.startTimestamp = [];
    this.hasDetails = {};
    this.columnsValues = [];

    makeObservable(this, {
      clearUserProfile: action.bound,
      userid: observable,
      loadUserInfoAndServices: flow.bound,
      services: observable,
      servicesWithHistory: observable,
      getUserProfile: flow.bound,
      data: observable,
      getServiceApi: flow.bound,
      callServiceApi: flow.bound,
      actions: observable,
      userInfo: observable,
      putToStorage: action.bound,
      getFromStorage: action.bound,
      userManagementInfoForStorage: action.bound,
      getAllStorageData: action.bound,
      reloadServicesAfterAction: observable,
      setReloadServicesAfterAction: action.bound,
      reloadService: action.bound,
      showWatchedBlock: observable,
      setShowWatchedBlock: action.bound,
      getUserHistory: flow.bound,
      getUserHistoryDetails: flow.bound,
      startPos: observable,
      historyColumns: observable,
      clearStartParameters: action.bound,
      hasDetails: observable,
      columnsValues: observable,
      flattenData: action.bound
    });
  }

  *loadUserInfoAndServices( typeForRequest, requestData ) {
    const { projectid, environment } = GamesStore;
    try {
      const { data } = yield userManagementApi.getUserInfoAndServices({
        projectid,
        environment,
        [typeForRequest]: typeForRequest === 'userid' ? Number(requestData) : requestData
      });
      this.services = data?.services || [];
      this.servicesWithHistory = data?.servicesWithHistory || [];
      const reloadServicesAfterAction =  this.getFromStorage('reloadServicesAfterAction');
      this.reloadServicesAfterAction = reloadServicesAfterAction?.length
        ? reloadServicesAfterAction
        : this.services;
      const userInfo = data?.userInfo;
      if (userInfo) {
        if (userInfo.error) {
          throw userInfo.error
        }
        const userid = userInfo?.data.userid || 0;
        this.userid = userid;
        this.userInfo = userInfo
      }
    } catch (e) {
      this.services = [];
      return e?.message || ''
    }
  }


  *getUserProfile( service ) {
    const { projectid, environment } = GamesStore;

    try {
      const { data } = yield userManagementApi.getUserProfileForService({
        projectid,
        environment,
        service,
        userid: this.userid
      });

      this.data = {
        ...this.data,
        [service]: data
      }

      if (this.data?.error) {
        this.userServiceError[service] = `${GET_USER_PROFILE_ERROR_MESSAGE} see details in console`
      } else if (isEmpty(data)) {
        this.userServiceError[service] = PROFILE_IS_EMPTY
      } else if (this.userServiceError[service]) {
        this.userServiceError[service] = ''
      }
    } catch (e) {
      console.log(e)
      this.userServiceError[service] = `${GET_USER_PROFILE_ERROR_MESSAGE} ${e.error?.message || ''}`
      return e
    }
  }


  *getServiceApi( service ) {
    const { projectid, environment } = GamesStore;
    try {
      const { data } = yield userManagementApi.getServiceApi({
        projectid,
        environment,
        service,
        userid: this.userid
      });

      this.actions = {
        ...this.actions,
        [service]: data
      }
    } catch (e) {
      return e
    }
  }

  *callServiceApi( service, action, actionParams ) {
    const { projectid, environment } = GamesStore;
    try {
      yield userManagementApi.callServiceApi({
        projectid,
        environment,
        userid: this.userid,
        service,
        action,
        actionParams
      });
      if (action === RELOAD_API_AFTER_ACTION) {
        this.services.forEach(service => {
          this.getServiceApi(service)
        })
      }
      this.reloadServicesAfterAction.forEach(reloadService => {
        this.reloadService(reloadService)
      })

    } catch (e) {
      return e
    }
  }

  userManagementInfoForStorage() {
    const { projectid, environment } = GamesStore;
    return `user-management-info_${projectid}_${environment}`;
  }
  putToStorage(name, value) {
    const USER_MANAGEMENT_INFO_CURRENT = this.userManagementInfoForStorage();

    Storages.put(
      USER_MANAGEMENT_INFO_CURRENT,
      {...Storages.get(USER_MANAGEMENT_INFO_CURRENT), [name]: value}
    );
  }

  getFromStorage(name) {
    const USER_MANAGEMENT_INFO_CURRENT = this.userManagementInfoForStorage();

    return Storages.get(USER_MANAGEMENT_INFO_CURRENT)
      ? Storages.get(USER_MANAGEMENT_INFO_CURRENT)[name]
      : null;
  }

  getAllStorageData() {
    const USER_MANAGEMENT_INFO_CURRENT = this.userManagementInfoForStorage();
    return Storages.get(USER_MANAGEMENT_INFO_CURRENT)
  }

  setReloadServicesAfterAction(service, isReload) {
    const savedReloadServices = this.getFromStorage('reloadServicesAfterAction') || this.services;
    this.reloadServicesAfterAction = isReload
      ? [...savedReloadServices, service]
      : savedReloadServices.filter(savedService => savedService !== service);
    this.putToStorage('reloadServicesAfterAction',  this.reloadServicesAfterAction);
  }

  reloadService(service) {
    this.getUserProfile(service);
  }

  setShowWatchedBlock(value) {
    this.showWatchedBlock = value
  }

  flattenData (data) {
    const flatData = [];
    let currentGroupColorIndex = 0;

    data.forEach(row => {
      flatData.push({...row, isSubRow: false});

      if (row.subEntries) {
        row.subEntries.forEach((subRow, index) => {
          const isMoreThanOneSub = row.subEntries.length > 1;
          const isFirstSubRow = isMoreThanOneSub ? index === 0 : null;

          if (isMoreThanOneSub && isFirstSubRow) {
            currentGroupColorIndex = currentGroupColorIndex === 0 ? 1 : 0;
          }
          const color = GROUP_COLORS[currentGroupColorIndex];
          flatData.push({
            ...subRow,
            ...omit(row, 'subEntries'),
            isSubRow: true,
            parentId: row.id,
            isFirstSubRow,
            isLastSubRow: isMoreThanOneSub ? index === row.subEntries.length - 1 : null,
            bgColor: isMoreThanOneSub ? color : 'inherit'
          })
        })
      }
    })
    return flatData
  }

  *getUserHistory(from, to) {
    const { projectid, environment } = GamesStore;

    const getUserServiceHistory = (service) => {
      const props = {
        projectid,
        environment,
        service,
        userid: this.userid,
        startTimestamp: to,
        endTimestamp: from
      }

      return userManagementApi.getUserHistory(props).then(res => {
        const result = res?.data?.entries;
        if (result.length) {
          this.historyColumns[service] = res.data.columns;
          const lastElement = result[result.length - 1];
          this.startPos[service] = lastElement.id || '';
          this.startTimestamp[service] = lastElement.timestamp || null;
          this.hasDetails[service] = res.data?.hasDetails || false;
        }
        return result || []
      })
      .catch(err => err)
    }

    try {
      const responseByServices = yield Promise.all(this.servicesWithHistory.map(service => {
        return getUserServiceHistory(service).then(res => {
          return res.length ? res.map(entrie => {
            const uuid = uuidv4();
            return {...entrie, service, uuid}}) : []
        })
      })).then((reponse) => {
        const historyColumnsValues = values(this.historyColumns);
        const columnsValues = [...new Set(historyColumnsValues.flat())];
        this.columnsValues = columnsValues;
        return reponse
      });

      const response = responseByServices.flat();

      return this.flattenData(response).sort((a, b) => b.timestamp - a.timestamp)
    } catch (e) {
      return e
    }
  }

  *getUserHistoryDetails(service, id) {
    const { projectid, environment } = GamesStore;

    try{
      const { data } = yield userManagementApi.getUserHistoryDetails({
        projectid,
        environment,
        service,
        userid: this.userid,
        id
      });

      return data;
    } catch(e) {
      return e
    }
  }

  clearStartParameters() {
    this.startPos = [];
    this.startTimestamp = [];
  }

  clearUserProfile() {
    this.services = [];
    this.data = {};
    this.actions = {};
    this.userInfo = {};
    this.reloadServicesAfterAction = [];
    this.hasDetails = {};
    this.historyColumns = {};
    this.columnsValues = [];
  }
}

export default new UserManagementStore();
import { flow, makeObservable, observable, action } from "mobx";
import { GamesStore } from ".";
import servicesInfo from "../api/servicesInfo";

class ServicesInfoStore {
  constructor() {
    this.servicesInfo = null;
    this.serverVersion = "";
    this.error = '';

    makeObservable(this, {
      servicesInfo: observable,
      loadServicesInfo: flow.bound,
      clearServicesInfo: action.bound,
      loadPortalServerVersion: flow.bound,
      serverVersion: observable,
      registerConfigs: flow.bound,
      error: observable
    });
  }

  *loadServicesInfo() {
    const { projectid, environment } = GamesStore;
    try {
      const { data } = yield servicesInfo.getServicesInfo({ projectid, environment });
      this.servicesInfo = data || {};
    } catch (e) {
      this.servicesInfo = null;
      this.error = e?.data || ''
    }
  }

  *loadPortalServerVersion() {
    try {
      const { data } = yield servicesInfo.getPortalServerVersion();
      this.serverVersion = data
    } catch (e) {
      this.servicesInfo = null;
    }
  }

  *registerConfigs(services) {
    const { projectid, environment } = GamesStore;
    try {
      yield servicesInfo.registerConfigs({ projectid, environment, services });
    } catch (e) {

    }
  }

  clearServicesInfo() {
    this.servicesInfo = null;
    this.error = '';
  }
}

export default new ServicesInfoStore();
import { observer } from "mobx-react";
import ReactDiff2Html from "../../../helpers/reactDiff2Html";
import { getTextByLang } from "src/helpers/configLanguageHelper";

const CompareTagsDiff = observer(({beforeTag, afterTag, paramsBefore, paramsAfter, lang})  => {
    return <ReactDiff2Html
      options={{
        originalFileName: beforeTag,
        updatedFileName: afterTag
      }}
      past={ getTextByLang(lang, paramsBefore, '\t') }
      current={ getTextByLang(lang, paramsAfter, '\t') }
    />
})

export default CompareTagsDiff
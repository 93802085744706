export const CURRENT = 'current';
export const WIDTH_FOR_BUTTON = '120px';
export const DEPLOY_BUTTONS_GROUP_WIDTH = '600px';
export const VALID_STATE_WIDTH = "150px";

export const ENABLE_DEPLOY_BUTTON = ['WARNING', 'VALID'];

export const STATIC_COLUMNS = 3;

export const VALID_ERRORS_BLOCK = 'validation errors';
export const VALID_STATE_COLUMN_NAME = 'validation state';
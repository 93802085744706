import { EditorView } from "@codemirror/view";

export const activeLineColor = EditorView.theme({
  ".cm-line.cm-activeLine": {
    backgroundColor: "#99eeff33"
  }
});

export const cursorType = EditorView.theme({
  ".cm-content[contenteditable=true]": {
    cursor: 'text !important'
  }
});
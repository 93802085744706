import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter
} from "@chakra-ui/react";
import { observer } from 'mobx-react';

const Dialog = observer(({
  showDialog,
  cancel,
  confirm,
  title,
  subtitle,
  isDisabledOkButton
}) => {
  return (
    <AlertDialog
      isOpen={showDialog}
      onClose={cancel}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
          {title}
        </AlertDialogHeader>

        <AlertDialogBody>
          {subtitle}
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button onClick={cancel}>
            Cancel
          </Button>
          <Button
            colorScheme='green'
            onClick={confirm}
            ml={3}
            isDisabled={isDisabledOkButton}
          >
            Ok
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialogOverlay>
  </AlertDialog>

  )
})
export default Dialog
import { action, flow, makeObservable, observable } from "mobx";
import { GamesStore, ProgressStore } from ".";
import historyApi from "../api/deploy";
import { set } from "lodash";

const REQUEST_PROGRESS_DELAY = 1000;
const FIRST_PROGRESS_DELAY = 1;

class DeployStore {
  constructor() {
    this.tags = null;
    this.interval = 0;
    this.error = false;
    this.progressInfo = [];
    this.tagNames = [];
    this.tagsSchema = null;
    this.savedErrors = [];

    this.validationDataForTags = {
      data: {},
      setErrors(tag, errors) {
        set( this.data, `${tag}.errors`, errors)
      },
      setState(tag, state) {
        set( this.data, `${tag}.state`, state)
      },
      getErrors(tag) {
        return this.data[tag]?.errors || []
      },
      getState(tag) {
        return this.data[tag]?.state || ''
      },
      get(tag) {
        return this.data[tag] || {}
      },
      clear() {
        this.data = {}
      }
    };

    makeObservable(this, {
      tags: observable,
      loadConfigTags: flow.bound,
      updateConfigsTag: flow.bound,
      getConfigTagsJSON: flow.bound,
      restoreConfigTags: flow.bound,
      saveConfigsFromJson: flow.bound,
      removeTag: flow.bound,
      addTag: flow.bound,
      clearTags: false,
      getUpdateProgress: flow.bound,
      setUpdateProgressInterval: action.bound,
      loadProgressInfo: action.bound,
      updateProgressFinalStatus: action.bound,
      clearProgressInterval: action.bound,
      getTagsHistory: flow.bound,
      overwriteTag: flow.bound,
      overwriteConfigsFromJson: flow.bound,
      validateTag: flow.bound,
      validateAllTags: flow.bound,
      removeSelectedTags: flow.bound,
      validationDataForTags: observable,
      getConfigTagsNames: flow.bound,
      tagNames: observable,
      loadTagsSchema: flow.bound,
      tagsSchema: observable,
      savedErrors: observable,
      clearSavedErrors: action.bound,
      getErrorsAsJSON: flow.bound
    });
  }

  *loadConfigTags(query) {
    const { projectid, environment } = GamesStore;

    try {
      const { data } = yield historyApi.getConfigTags({ projectid, environment, query });
      this.tags = data || [];
    } catch (e) {
      this.tags = [];
    }
  }

  *getUpdateProgress() {
    const { projectid, environment } = GamesStore;

    try {
      const data = yield historyApi.getUpdateProgress({ projectid, environment });
      return data
    } catch (error) {
      this.clearProgressInterval();
    }
  }

  clearProgressInterval() {
    const { setShowProgress } = ProgressStore;
    if (this.interval) {
      setShowProgress(false);
      clearInterval(this.interval);
    }
  }

  setUpdateProgressInterval() {
    const { setShowProgress } = ProgressStore;
    if (!this.error) {
      setShowProgress(true);
      this.interval = setInterval(() => {
        this.getUpdateProgress();
      }, REQUEST_PROGRESS_DELAY)
    }
  }
  loadProgressInfo() {
    this.clearProgressInterval();
    setTimeout(() => {
      this.getUpdateProgress().finally(() => {
       this.setUpdateProgressInterval();
      });
    }, FIRST_PROGRESS_DELAY);
  }

  updateProgressFinalStatus() {
    this.getUpdateProgress().finally(() => {
      this.clearProgressInterval();
      this.loadConfigTags();
      this.validationDataForTags.clear();
    });
  }

  *addTag( tag ) {
    const { projectid, environment } = GamesStore;

    try {
      const { data } = yield historyApi.addTag({ projectid, environment, tag });
      this.loadConfigTags();
      return data
    } catch (err) {
      this.savedErrors = err?.error?.data
      console.log(err);
    }
  }

  *updateConfigsTag( tag, ignoreWarnings ) {
    const { projectid, environment } = GamesStore;
    this.loadProgressInfo();
    this.error = false;
    try {
      yield historyApi.updateConfigsTag({ projectid, environment, tag, ignoreWarnings });
    } catch (error) {
      this.error = true;
      this.clearProgressInterval();
    }
    this.updateProgressFinalStatus();
  }

  *getConfigTagsJSON( tag, configNames ) {
    const { projectid, environment } = GamesStore;

    try {
      const { data } = yield historyApi.getConfigsJSON({ projectid, environment, tag, configNames });
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  *getConfigTagsNames() {
    const { projectid, environment } = GamesStore;

    try {
      const { data } = yield historyApi.getConfigsNames({ projectid, environment });
      this.tagNames = data;
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  *getErrorsAsJSON(tag) {
    const { projectid, environment } = GamesStore;

    try {
      const { data } = yield historyApi.getErrorsAsJSON({ projectid, environment, tag });
      this.validationDataForTags.setErrors(tag, data.errors)
      return data;
    } catch (error) {
      console.log(error);
    }
  }


  *restoreConfigTags( tag ) {
    const { projectid, environment } = GamesStore;

    try {
      const { data } = yield historyApi.restoreConfigTags({ projectid, environment, tag });
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  *saveConfigsFromJson( tag, configs ) {
    const { projectid, environment } = GamesStore;

    try {
      const { data } = yield historyApi.saveConfigsFromJson({ projectid, environment, tag, configs });
      this.loadConfigTags();
      return data;
    } catch (err) {
      this.savedErrors = err?.error?.data
      console.log(err);
    }
  }

  *removeTag( tag ) {
    const { projectid, environment } = GamesStore;

    try {
      yield historyApi.removeTag({ projectid, environment, tag });
      this.loadConfigTags();
    } catch (error) {
      console.log(error);
    }
  }

  *removeSelectedTags( selectedTags ) {
    const { projectid, environment } = GamesStore;

    try {
      const tagsData = this.tags.data.filter((tag, index) => selectedTags[index]).map(tag => (
        {tag: tag.data.tag, environment, projectid, action: 'delete_configs'}
      ))
      yield historyApi.removeSelectedTags({ tags: tagsData });
      this.loadConfigTags();
    } catch (error) {
      console.log(error);
    }
  }

  *getTagsHistory( tag, fromTime, toTime, query ) {
    const { projectid, environment } = GamesStore;

    try {
      const { data } = yield historyApi.getTagsHistory({ projectid, environment, tag, fromTime, toTime, query });
      return data
    } catch (error) {
      console.log(error);
    }
  }

  *overwriteTag( tag ) {
    const { projectid, environment } = GamesStore;

    try {
      const { data } = yield historyApi.overwriteConfigs({ projectid, environment, tag });
      this.loadConfigTags();
      return data
    } catch (err) {
      this.savedErrors = err?.error?.data
      console.log(err);
    }
  }

  *overwriteConfigsFromJson( tag, configs ) {
    const { projectid, environment } = GamesStore;

    try {
      const { data } = yield historyApi.overwriteConfigsFromJson({ projectid, environment, tag, configs });
      this.loadConfigTags();
      return data;
    } catch (err) {
      this.savedErrors = err?.error?.data;
      console.log(err);
    }
  }

  *validateTag( tag ) {
    const { projectid, environment } = GamesStore;

    try {
      const { data } = yield historyApi.validateTag({ projectid, environment, tag });
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  *validateAllTags() {
    const { projectid, environment } = GamesStore;

    try {
      const tagsData = this.tags.data.map(tag => (
        {tag: tag.data.tag, environment, projectid, action: 'validate_tag'}
      ))
      const { data } = yield historyApi.validateAllTags({tags: tagsData});
      return data
    } catch (error) {
      console.log(error);
    }
  }

  *loadTagsSchema() {
    try {
      const { projectid, environment } = GamesStore;
      const { data } = yield historyApi.getSchema({ projectid, environment });
      this.tagsSchema = data;
    } catch (error) {
      console.log(error);
    }
  }

  clearSavedErrors() {
    this.savedErrors = [];
  }

  clearTags() {
    this.tags = null;
    this.error = false;
    this.clearProgressInterval();
    this.validationDataForTags.clear();
  }
}

export default new DeployStore();
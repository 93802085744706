import { handlerSendRequest } from './handlerSendRequest';

class SingleConfigApi {
  getConfigData = ({ projectid, environment, elementName }) => {
    // legacy custom postfix for contacs and externals
    const actionName = elementName.endsWith('_config') ? `get_${elementName}` : `get_${elementName}_config`;
    return handlerSendRequest(actionName, { projectid, environment });
  }

  setConfigData = ({ projectid, environment, fields, elementName }) => {
    // legacy custom postfix for contacs and externals
    const actionName = elementName.endsWith('_config') ? `set_${elementName}` : `set_${elementName}_config`;
    return handlerSendRequest(actionName, { projectid, environment, fields });
  }

  getSchema = ({ elementName, projectid, environment }) =>
    handlerSendRequest(`get_${elementName}_schema`, { projectid, environment });
}

export default new SingleConfigApi();
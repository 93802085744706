export const SCROLL_TO_BOTTOM_DEFAULT = 100;
export const SUCCESS_STATUS_FOR_STRING = "OK";
export const IN_PROGRESS_STATUS_FOR_STRING = "...";
export const PROGRESS_STATUS = "IN_PROGRESS";
export const SKIPPED_STATUS = "SKIPPED";

export const OPENED_DEFAULT_HEIGHT = 400;
export const RIGHT_PADDING = 15;
export const INFO_ELEMENT_HEIGH = 70;

export const TIMER_FOR_CLEAR_CURRENT_CONSOLE_ERROR = 3000;

import Rpc from '../services/Rpc';

class TokenManagemenApi {
  getTokenManagementData = ({ jwt }) =>
    Rpc.sendRequest('get_token_management_data', {
      jwt
    })

  getTokenPayload = ({ jwt, token }) =>
    Rpc.sendRequest('get_token_payload', {
      jwt,
      token
    })
}

export default new TokenManagemenApi();
import { MINUTE_IN_MS } from "src/constants";

export function convertUTCToLocalDate(date: Date) {
  if (!date) {
    return date
  }

  const dateValue = new Date(date);
  const utcDate = new Date(dateValue.getTime() + dateValue.getTimezoneOffset() * MINUTE_IN_MS);

  return utcDate
}

import {
  Box,
  Button,
  Divider,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Flex,
  Switch
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useEffect, useMemo } from "react";
import { GamesStore, MatchingInfoStore } from "src/stores";
import { useTable } from 'react-table';
import { isBoolean, keys } from "lodash";
import Progress from "src/components/Progress";

const MatchingInfoTable = observer(() => {
  const { matchingInfo, getMatchingInfo, error, setMaintenanceOnMatching, clearMatchingInfo } = MatchingInfoStore;
  const { games_loaded } = GamesStore

  useEffect(() => {
    if (games_loaded) {
      getMatchingInfo();
    }
    return () => clearMatchingInfo()
  }, [games_loaded, getMatchingInfo, clearMatchingInfo]);

  const matchingInfoTableData = useMemo(() => matchingInfo ? [matchingInfo] : [], [matchingInfo])

const columns = useMemo(() => {
  return (
   keys(matchingInfo).map((field) => ({
      Header: field,
      accessor: field,
      Cell: ({ value }) => (
        isBoolean(value) ? value.toString() : value
      )
    })) || []
  );
}, [matchingInfo]);

const handleReloadTable = () => {
  clearMatchingInfo()
  getMatchingInfo()
}

const {
  getTableProps,
  getTableBodyProps,
  headerGroups,
  prepareRow,
  rows
} = useTable({
  columns,
  data:  matchingInfoTableData
});

  return (
    <Box mt={5} mb={5}>
      <Heading as="h3" size="lg">
        Matchmaking
      </Heading>
      <Divider orientation="horizontal" />
      <Flex justifyContent="flex-end" mt={2} mb={2}>
        <Button onClick={handleReloadTable}>Refresh</Button>
      </Flex>
      <Box position="relative">
        {matchingInfo
          ? <Table {...getTableProps()}>
              <Thead>
                {headerGroups.map((headerGroup) => (
                  <Tr {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
                    {headerGroup.headers.map((column) => (
                      <Th
                        key={column}
                      >
                        {column.render('Header')}
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()} key={row.id}>
                      {row.cells.map((cell) => {
                        return <Td
                          {...cell.getCellProps()}
                          key={`${cell.value}`}
                        >
                          {cell.column.Header === 'Maintenance on'
                            ? <Switch
                              colorScheme='green'
                              defaultChecked={cell.value}
                              onChange={(e) => setMaintenanceOnMatching(e.target.checked)}
                            />
                            : cell.render('Cell')}
                        </Td>
                      })}
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          : error ? <Text color='tomato'>{error || null} </Text> : <Progress />
        }
      </Box>
    </Box>
  );
});

export default MatchingInfoTable;
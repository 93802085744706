import { Box, Checkbox, IconButton, Table, Tbody, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import {
  NavArrowLeft,
  NavArrowRight
} from 'iconoir-react';
import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { useExpanded, usePagination, useSortBy, useTable } from 'react-table';
import SortColumnIcon from 'src/components/SortColumnIcon';
import { scrollTopAfterEvent } from 'src/helpers/scrollTopAfterEvent';
import { ConfigElementStore, ConfigsViewStore } from 'src/stores';
import ConfigsTableElement from './ConfigsTableElement';
import { isString } from 'lodash';
import { DEFAULT_SORTIG_COLUMN_FOR_TABLE } from 'src/constants';
import ChangeVisibilityIcon from 'src/components/ChangeVisibilityForColumns/ChangeVisibilityIcon';
import { getColumnsWithSeparator } from 'src/helpers/getColumnsWithSeparator';

const ConfigsTable = observer(({
  columns,
  data,
  schema,
  elementName,
  specCompletionsPath,
  visibleColumns,
  updateVisibleColumns,
  configName
}) => {
  const controlledPageSize = 100;
  const total = data?.length;

  const {
    setConfigCheckedAll,
    configCheckedAll,
    setExpendedRows,
    expendedRows,
    putToStorage,
    getFromStorage
  } = ConfigsViewStore;

  const { getElementDataByKey } = ConfigElementStore;

  const customSortType = (rowA, rowB, name, desc) => {
    const rowAValue = rowA.values[name];
    const rowBValue = rowB.values[name];
    const isNewA = getElementDataByKey(rowA.values.key)?.isNewAddedElement;
    const isNewB = getElementDataByKey(rowB.values.key)?.isNewAddedElement;
    if (isNewA) return desc ? 1 : -1;
    if (isNewB) return desc ? -1 : 1;

    if (isString(rowAValue)) {
      return rowAValue.localeCompare(rowBValue)
    }
    return rowAValue - rowBValue
  }

  const [columnsList, setColumnsList] = useState([]);

  useEffect(() => {
    if (visibleColumns.length && columns.length) {
      const cols = [
        ...getColumnsWithSeparator(visibleColumns, columns),
        {
          Header: "_id",
          accessor: "_id"
        },
        {
          Header: "key",
          accessor: "key"
        }
      ];
      setColumnsList(cols)
    } else {
      setColumnsList([])
    }
  }, [columns, visibleColumns]);

  const hiddenColumns = ["_id", "hash", "key"];
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { sortBy, expanded }
  } = useTable({
    columns: columnsList,
    data,
    autoResetPage: false,
    initialState: {
      pageIndex: 0,
      pageSize: controlledPageSize,
      sortBy:  getFromStorage([elementName, 'sortBy']) || DEFAULT_SORTIG_COLUMN_FOR_TABLE,
      expanded: expendedRows,
      hiddenColumns
    },
    sortTypes: {
      alphanumeric: customSortType
    }
  }, useSortBy, useExpanded, usePagination);

  const staticColumns = 2;
  const tableRef = useRef(null);

  const currentElementName = useRef(elementName)

  useEffect(() => {
    putToStorage([currentElementName.current, 'sortBy'], sortBy);
    currentElementName.current = elementName;
  }, [sortBy, putToStorage, elementName])

  useEffect(() => {
    setExpendedRows(expanded)
  }, [expanded, setExpendedRows]);

  return (
    <Box overflowY="auto" className="configs-table">
      <Table {...getTableProps()} ref={tableRef}>
        <Thead>
          {headerGroups.map(headerGroup => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              <Th w={5}>
                <Tooltip label={'Select all'}>
                  <div>
                    <Checkbox
                      isChecked={configCheckedAll}
                      onChange={() => setConfigCheckedAll(!configCheckedAll)}
                    />
                  </div>
                </Tooltip>
              </Th>
              <Th
                w="100px"
                maxW="100px"
                minW="100px"
              >
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  textTransform: 'none'
                }}>
                  code editor
                </div>
              </Th>
              {headerGroup.headers.map(column => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  onClick={() => column.toggleSortBy(!column.isSortedDesc)}
                  w={column.columnWidth || 'inherit'}
                  maxW={column.columnWidth || 'inherit'}
                  minW={column.columnWidth || 'inherit'}
                >
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    textTransform: 'none',
                    cursor: column.canSort ? 'pointer' : 'auto'
                  }}>
                    {column.render('Header')}
                    <SortColumnIcon
                      sortedIndex={column.sortedIndex}
                      canSort={column.canSort}
                      isSortedDesc={column.isSortedDesc}
                    />
                    <ChangeVisibilityIcon
                      visibleColumns={visibleColumns}
                      updateVisibleColumns={updateVisibleColumns}
                      column={column}
                    />
                  </div>
                </Th>
              ))}
              <Th
                w="150px"
                minW="150px"
                maxW="150px"
              >
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  textTransform: 'none',
                  justifyContent: 'end'
                }}>
                  action
                </div>
              </Th>
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <ConfigsTableElement
                elementName={elementName}
                schema={schema}
                row={{...row}}
                visibleColumns={visibleColumns}
                staticColumns={staticColumns}
                specCompletionsPath={specCompletionsPath}
                configName={configName}
              />
            )
          })}
        </Tbody>
      </Table>

      {!!total && (
        <Box>
          <IconButton
            variant='outline'
            icon={<NavArrowLeft />}
            onClick={() => scrollTopAfterEvent(previousPage, tableRef)}
            isDisabled={!canPreviousPage}
            m={3}
          />

          <IconButton
            variant='outline'
            icon={<NavArrowRight />}
            onClick={() => scrollTopAfterEvent(nextPage, tableRef)}
            isDisabled={!canNextPage}
            m={3}
          />
        </Box>
      )}
    </Box>
  )
})

export default ConfigsTable;
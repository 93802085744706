export const DEFAULT_ELEMENTS = 10;
export const DEFAULT_PREV_SEASON = false;
export const DEFAULT_PAGE_INDEX = 0;

export const DEFAULT_TAB_NAME = 'New Leaderboard Table';

export const KEY_WITH_DEFAULT_EMPTY_SELECT_PARAMETER = 'platform';

export const DEFAULT_EMPTY_SELECT_PARAMETER = {
  "value": "",
  "context": ""
}


export const COLUMNS_WITH_FIXED_WIDTH = {
  idx: '50px',
  userid: '150px',
  nick: '150px'
}


export const DISABLE_FOR_SORTING_LB_FIELD = ["idx", "nick", "userid"];
import { handlerSendRequest } from './handlerSendRequest';

class LeaderboardApi {
  getLeaderboard = ({ projectid, environment, params }) =>
    handlerSendRequest('get_leaderboard_page', { projectid, environment, ...params })

  getLeaderboardDescription = ({ projectid, environment }) =>
    handlerSendRequest('get_leaderboard_description', { projectid, environment })
}

export default new LeaderboardApi();
export enum TypesEnum {
  String = 'string',
  Boolean = 'boolean',
  Array = 'array',
  Object = 'object',
  Integer = 'integer',
  Number = "number",
  Float = 'float',
  Enum = 'enum'
}

export type SchemaElement = {
  first_init?: boolean,
  invalid_values?: string[],
  maxLength?: number,
  minLength?: number,
  type: TypesEnum,
  unique?: boolean,
  pattern?: string,
  init?: string,
  enum?: string[],
  description?: string,
  title?: string,
  allOf?: SchemaElement[],
  anyOf?: SchemaElement[],
  items?: SchemaElement,
  properties?: SchemaType,
  required?: string[];
  additionalProperties?: SchemaElement
}

export type SchemaType = {
  [key: string]: SchemaElement
}

export type SchemaEnum = string[];

export type DefaultValuesTypes = string | boolean | [] | {} | number;
import { MINUTE_IN_MS } from "src/constants";

export function convertLocalToUTCDate(date: Date) {
  if (!date) {
    return date
  }

  const dateValue = new Date(date)
  const localDate = new Date(dateValue.getTime() - dateValue.getTimezoneOffset() * MINUTE_IN_MS);

  return localDate
}

import { observer } from 'mobx-react';
import DialogIsUnsavedChanges from "./DialogIsUnsavedChanges";

const Modals = observer(() => {

  return (
    <>
      <DialogIsUnsavedChanges />
    </>
  )
})

export default Modals
import { observer } from 'mobx-react';
import {
  createBrowserRouter,
  createRoutesFromElements, Route, RouterProvider
} from 'react-router-dom';
import IndexScene from '../IndexScene';
import ServicesInfo from "../Main/ServicesInfo";
import SingleConfig from "../Main/SingleConfig";
import Configs from '../Main/Configs';
import Deploy from '../Main/Deploy';
import Leaderboard from '../Main/Leaderboard';
import MainLayout from '../Main/MainLayout';
import Scripts from '../Main/Scripts';
import UserManagement from '../Main/UserManagement';
import NotFound from "../NotFound";
import TokenManagement from '../Main/TokenManagement';
import { layoutConfig } from './config';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route index element={<IndexScene />} />
      <Route path="*" element={<MainLayout />}>
        <Route path="stats" element={<Configs {...layoutConfig['stat']} />} />
        <Route path="unlocks" element={<Configs {...layoutConfig['unlock']} />} />
        <Route path="modes" element={<Configs {...layoutConfig['mode']} />} />
        <Route path="tables" element={<Configs {...layoutConfig['table']} />} />
        <Route path="triggers" element={<Configs {...layoutConfig['trigger']} />} />
        <Route path="items" element={<Configs {...layoutConfig['item']} />} />
        <Route path="currencies" element={<Configs {...layoutConfig['currencies']} />}  />

        <Route path="contacts" element={<SingleConfig headerName='Contacts' elementName='contacts' isUpload={true} />} />
        <Route path="externals" element={<SingleConfig headerName='Externals' elementName='externals'/>} />

        <Route path="leaderboard" element={<Leaderboard />} />
        <Route path="deploy_tags" element={<Deploy type="tags" />} />
        <Route path="deploy_history" element={<Deploy type="history" />} />
        <Route path="deploy_compare" element={<Deploy type="compare" />} />
        <Route path="profile_scripts" element={<Scripts elementName='profile_script' />} />
        <Route path="userManagement" element={<UserManagement />} />
        <Route path="servicesInfo" element={<ServicesInfo />} />
        <Route path="tokenManagement" element={<TokenManagement />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Route>
  )
);

const Layout = observer(() => <RouterProvider router={router} />)

export default Layout
import {
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement
} from "@chakra-ui/react";
import { DeleteCircledOutline } from "iconoir-react";
import { observer } from "mobx-react";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { ConfigsStore, FilterStore } from "../../../stores";
import { handleEventWithChanges } from "src/helpers/handleEventWithChanges";
import "./FiltersBlock/style.scss";
import FiltersBlockHelp from "./FiltersBlock/FiltersBlockHelp";

const FilterBlock = observer(({ handleLoadConfigs }) => {
  const { queryValue, setQueryValue, isShowFilters } = FilterStore;
  const {isConfigChanges} = ConfigsStore;
  const { register, handleSubmit, reset, watch } = useForm();

  useEffect(() => {
    if (!queryValue) {
      reset({
        query: "",
      })
    }
  }, [queryValue, reset])

  const submitFilter = (data) => {
    if (data.query) {
      handleLoadConfigs(data.query);
      setQueryValue(data.query);
    }
  }

  const handleSubmitFilter = (data) => {
    handleEventWithChanges(isConfigChanges, () => submitFilter(data))
  }

  const handleClearFilter = () => {
    handleLoadConfigs("");

    reset({
      query: "",
    })
    setQueryValue('')
  }

  const watchQuery = watch("query");

  return (
    isShowFilters
      ? <Flex mb={2} w="100%" pt={2}>
          <form
            style={{width: '100%', display: 'flex', alignItems: "center"}}
            onSubmit={handleSubmit((data) => handleSubmitFilter(data))}
          >
            <FiltersBlockHelp />
            <InputGroup pl={1}>
              <Input
                placeholder='Enter filter'
                {...register('query', {required: false})}
                defaultValue={queryValue}
              />
              <InputRightElement>
                <DeleteCircledOutline onClick={() => handleClearFilter()} cursor="pointer" />
              </InputRightElement>
            </InputGroup>
            <Button ml={2} disabled={!watchQuery} type="submit">Filter</Button>
          </form>
        </Flex>
      : null
  );
})

export default FilterBlock;
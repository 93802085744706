import { WidgetType, Decoration } from "@codemirror/view";
import { getLines } from "../../helpers/codeMirrorHelper";
import { ViewPlugin } from "@codemirror/view";
import { getParameterValue, getParameterName, replaceExtraSymbols } from "../../helpers/parameterStringHelper";
import { getTextArrayForCodeEditor } from "src/helpers/getTextArrayForCodeEditor";
import { getParamaterTypes } from "src/helpers/getParamaterTypes";
import { TYPE_NAME_FOR_BOOL } from "src/constants";

const CONST_TRUE = 'true';
const CONST_FALSE = 'false';

class CheckboxWidget extends WidgetType {
  constructor(checked) {
    super()
    this.checked = checked;
  }

  // create checkbox element
  toDOM() {
    const wrap = document.createElement("span");
    wrap.setAttribute("aria-hidden", "true");
    wrap.className = "cm-boolean-toggle";
    const box = wrap.appendChild(document.createElement("input"));
    box.type = "checkbox";
    box.checked = this.checked;
    return wrap
  }

  ignoreEvent() { return false }
}

const checkboxes = (view, schema) => {
  let widgets = [];
  const viewText = getTextArrayForCodeEditor(view);
  viewText.forEach((element, index) => {
    // position from text for current text element
    // index - current line from lines array
    const fromPosition = getLines(view.state, index).from;
    const parameterName = getParameterName(element);
    const parameter = schema.properties[parameterName];
    if (getParamaterTypes(parameter).includes(TYPE_NAME_FOR_BOOL)) {
      const parameterValue = getParameterValue(element);
      // we get parameter value for check that we can insert checkBox
      if (parameterValue && parameterValue !== 'null') {
        const isTrue = parameterValue === CONST_TRUE;
        const deco = Decoration.widget({
          widget: new CheckboxWidget(isTrue),
          side: 1
        });
        // we need full length for json parameter name (with spaces and quotes)
        const parameterNameLength = element.indexOf(':');
        const range = fromPosition + parameterNameLength + 1;
        widgets.push(deco.range(range))
      }
    }
  })
  return Decoration.set(widgets)
}


// if we have parameter with comma after it value we must add comma after replacement
const insertText = (isComma, text) => {
  return isComma ? ` ${text},` : ` ${text}`;
}

const toggleBoolean = (view, pos) => {
  // get text after checkbox from full string from current line
  const textAfterCheck = view.state.doc.sliceString(pos, view.lineBlockAt(pos).to);
  // end position for current line
  const positionTo = view.lineBlockAt(pos).to;

  let change;
  const textAfterCheckValue = replaceExtraSymbols(textAfterCheck);
  const isComma = textAfterCheck.includes(',');
  const isFalse = textAfterCheckValue === CONST_FALSE;
  const isTrue = textAfterCheckValue === CONST_TRUE;
  if (!isFalse && !isTrue) {
    return false
  }
  const insert = insertText(isComma, isFalse ? CONST_TRUE : CONST_FALSE);
  change = {from: pos, to: positionTo, insert }
  view.dispatch({changes: change})
  return true
}

export const checkboxPlugin = (schema) => {
  return ViewPlugin.fromClass(class {
    decorations

    constructor(view) {
      this.decorations = checkboxes(view, schema)
    }

    update(update) {
      if (update.docChanged || update.viewportChanged)
        this.decorations = checkboxes(update.view, schema)
    }
  }, {
    decorations: v => v.decorations,

    eventHandlers: {
      mousedown: (e, view) => {
        const target = e.target;
        if (target.nodeName === "INPUT" &&
            target.parentElement?.classList.contains("cm-boolean-toggle"))
          return toggleBoolean(view, view.posAtDOM(target))
      }
    }
  })
}
import { Flex, Accordion, AccordionItem, AccordionButton, AccordionPanel, Button } from "@chakra-ui/react";
import { observer } from "mobx-react";
import moment from "moment";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Minus, Plus } from 'iconoir-react';
import { DeployStore } from "../../../stores";
import Combobox from "../../Combobox";
import { isEqual, keys, orderBy } from "lodash";
import { CURRENT } from "./constants";
import Progress from "src/components/Progress";
import { getParamsByTabName } from "src/helpers/configLanguageHelper";
import { getConfigZIPAndUnpack } from "src/helpers/downloadConfig";
import DeployTagArchives from "./DeployTagArchives";
import CompareTagsDiff from "./CompareTagsDiff";


const CompareTags = observer(() => {
  const { tags, getConfigTagsJSON } = DeployStore;
  const [isLoading, setIsLoading] = useState(true);
  const [beforeTag, setBeforeTag] = useState('');
  const [afterTag, setAfterTag] = useState('');
  const [afterArchive, setAfterArchive] = useState(null);
  const [beforeArchive, setBeforeArchive] = useState(null);

  const [tagContent, setTagContent] = useState({})

  useEffect(() => {
    setTagContent({
      before: null,
      after: null
    })
  }, [])

  const tagNames = useMemo(() => {
    let values = [];

    if (tags?.data) {
      const sortedTags = orderBy(tags.data, ['data.create_t'], ['desc']);
      values = sortedTags.map((tag) => (
        {
          "value": tag.data.tag,
          "context": moment(tag.data.create_t * 1000).format('DD/MM/YYYY, h:mm:ss')
        }
      ));
      values.unshift({ "value": CURRENT, "context": "" });
    }

    return values;
  }, [tags]);

  const getConfigsTag = async (tag, type, setArchive) => {
    if (tag) {
      const configsTag = await getConfigTagsJSON(tag);
      if (configsTag?.profile_scripts?.archive) {
        await getConfigZIPAndUnpack(configsTag.profile_scripts).then(config => {
          setArchive({...config})
          delete configsTag.profile_scripts.archive
        })
      }

      if (configsTag) {
        setTagContent(prev => ({...prev, [type]: configsTag}))
      }
    }
  }

  const handlerToCompare = () => {
    setIsLoading(false);
    Promise.all([
      getConfigsTag(beforeTag, 'before', setBeforeArchive),
      getConfigsTag(afterTag, 'after', setAfterArchive)
    ]).then(() => {
      setIsLoading(true);
    })
  }

  return (
    <Fragment>
      {tagNames && (
        <Flex marginBottom={5} justifyContent="space-between">
          <Combobox
            items={tagNames}
            placeholderMsg='Before tag'
            key={0}
            value={beforeTag}
            setValue={setBeforeTag}
          />
          <Combobox
            items={tagNames}
            placeholderMsg='After tag'
            key={1}
            value={afterTag}
            setValue={setAfterTag}
          />
          <Button onClick={handlerToCompare}>
            Compare
          </Button>
        </Flex>
      )}
      {isLoading ?
        (tagContent?.before &&
        tagContent?.after) ? (
          <Accordion allowMultiple>
            {keys(tagContent.before).map(tabName => {
              const { paramsBefore, paramsAfter, language } = getParamsByTabName(tabName, tagContent);
              const equal = isEqual(paramsBefore, paramsAfter);
              return <AccordionItem isDisabled={equal} key={tabName}>
                {({ isExpanded }) => (
                  <>
                    <AccordionButton>
                      {!equal
                        ? <Flex alignItems={"center"}>
                            {isExpanded
                              ? <Minus fontSize='12px' />
                              : <Plus fontSize='12px' />
                            }
                            {` ${tabName}`}
                          </Flex>
                        : <Flex>
                            {`${tabName} are equal`}
                          </Flex>
                      }
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <CompareTagsDiff
                        beforeTag = {beforeTag}
                        afterTag = {afterTag}
                        paramsBefore={paramsBefore}
                        paramsAfter = {paramsAfter}
                        lang = {language}
                      />
                      <DeployTagArchives
                        currentArchive={beforeArchive}
                        diffArchive={afterArchive}
                        tabName={tabName}
                        isDiff={true}
                      />
                    </AccordionPanel >
                  </>
                )}
              </AccordionItem>
            })}
          </Accordion>
        ) : null
      : <Progress />}
      </Fragment>
   )
  });

export default CompareTags;
import { handlerSendRequest } from './handlerSendRequest';

class MatchingInfoApi {
  getMatchingStatus = ({ projectid, environment }) =>
    handlerSendRequest('get_matching_status', { projectid, environment })


  setMaintenanceOnMatching = ({ projectid, environment, value }) => (
    handlerSendRequest('set_maintenance_on_matching', { projectid, environment, value })
  )
}

export default new MatchingInfoApi();
const { LANGUAGE } = require("src/helpers/configLanguageHelper");

export const layoutConfig =
  {
    "stat": {
      elementName: 'stat',
      specCompletions: [
        {
          path: "triggers.name",
          from: "trigger"
        },
        {
          path: "condition",
          from: "stat",
          specSymbol: 's.'
        }
      ],
      tableFields: ['name'],
      configName: 'stats',
      headerName: 'Stats'
    },
    "unlock": {
      elementName: 'unlock',
      specCompletions: [
        {
          path: "stages.updStats.name",
          from: "stat"
        },
        {
          path: "stages.updStats.mode",
          from: "mode"
        },
        {
          path: "condition",
          from: "stat",
          specSymbol: 's.'
        }
      ],
      tableFields: ['name'],
      configName: 'unlocks',
      headerName: 'Unlocks'
    },
    "mode": {
      elementName: 'mode',
      tableFields: ['name'],
      configName: 'modes',
      headerName: 'Modes'
    },
    "table": {
      elementName: 'table',
      tableFields: ['name'],
      configName: 'tables',
      headerName: 'Tables'
    },
    "trigger": {
      elementName: 'trigger',
      tableFields: ['name'],
      lang: LANGUAGE.JS,
      isUseNamespace: false,
      configName: 'triggers',
      headerName: 'Triggers'
    },
    "item": {
      elementName: 'item',
      tableFields: ['itemdefid', 'name_english'],
      csvEditable: ['bundle'],
      configName: 'items',
      headerName: 'Items'
    },
    "currencies": {
      elementName: 'currency',
      tableFields: ['code'],
      configName: 'currencies',
      headerName: 'Сurrencies'
    }
  }
import { getJSONstringify } from "./JSONSstringifyHelper";

type CurrentSelectorParamType = {[key: string]: JsLangObjectType[]};

type CurrentSelectorType =  {
  before: CurrentSelectorParamType,
  after: CurrentSelectorParamType
};

export enum LANGUAGE {
  JS = 'JS',
  JSON = 'JSON'
}

type JsLangObjectType = {
  name: string,
  body: string
}

type  ConfigElementType = {
  data: JsLangObjectType
}

const TRIGGERS_TAB = "triggers";

export const isJSLang = (lang: LANGUAGE) => {
  return lang === LANGUAGE.JS;

}

export const isJSONLang = (lang: LANGUAGE) => {
  return lang === LANGUAGE.JSON;
}

export const getDefaultValueByLang = (lang: LANGUAGE) => {
  switch (lang) {
    case LANGUAGE.JS:
      return ''
    default:
      return '{\n \n}'
  }
}

export const getTextByLang = (lang: LANGUAGE, data: object, split?: string) => {
  switch (lang) {
    case LANGUAGE.JS:
      return String(data)
    default:
      return getJSONstringify(data, split)
  }
}

export const parseDataByLang = (result: string, lang: LANGUAGE): string => {
  switch (lang) {
    case LANGUAGE.JS:
      return result
    default:
      return JSON.parse(result)
  }
}

export const getFileByLang = (lang: LANGUAGE) => {
  switch (lang) {
    case LANGUAGE.JS:
      return {
        fileName: "config.nut",
        accept: "application/nut"
      }
    default:
      return {
        fileName: "config.json",
        accept: "application/json"
      }
  }
}

export const getTriggersDataForDiff = (params: JsLangObjectType[]) => {
  return params.map((el: JsLangObjectType, index: number) => {
    return `${index !== 0 ? '\n' : ''}${el.body}`
  })
}

export const getParamsByTabName = (tabName: string, params: CurrentSelectorType) => {
  const before = params.before[tabName];
  const after = params.after[tabName];
  switch (tabName) {
    case TRIGGERS_TAB:
      return {
        paramsBefore: getTriggersDataForDiff(before),
        paramsAfter: getTriggersDataForDiff(after),
        language: LANGUAGE.JS
      }
    default:
      return {
        paramsBefore: before,
        paramsAfter: after,
        language: LANGUAGE.JSON
      }
  }
}

export const getConfigDataByLang = (lang: LANGUAGE, configElement: ConfigElementType) => {
  switch (lang) {
    case LANGUAGE.JS:
      return configElement?.data?.body
    default:
      return getJSONstringify(configElement?.data)
  }
}

export const getNameFromFunction = (functionStr: string): string => {
  const str = functionStr.replace(/[\r\n\s]+/g, ' ').match(/function(.*?)\(/);
  return str ? str[1].trim() : ''
}

export const getDataForValidateJS = (data: string): JsLangObjectType => {
  return {
    name: getNameFromFunction(data),
    body: data
  }
}
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Td,
  Tr,
  Tbody,
  Th,
  Table,
  Thead,
  IconButton
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import moment from "moment";
import { Fragment, useEffect, useMemo, useRef, useState, useCallback } from "react";
import { NavArrowLeft, NavArrowRight } from 'iconoir-react';
import { DeployStore } from "../../../../stores";
import Combobox from "../../../Combobox";
import { usePagination, useSortBy, useTable, useExpanded } from 'react-table';
import DeployTableCell from "./../DeployTableCell";
import SortColumnIcon from "src/components/SortColumnIcon";
import DatePicker from "react-datepicker";
import "./style.scss";
import { scrollTopAfterEvent } from "src/helpers/scrollTopAfterEvent";
import {
  STATUS_BLUE_COLOR,
  DEFAULT_SORTIG_COLUMN_FOR_TABLE,
  STATUS_GREEN_COLOR,
  STATUS_RED_COLOR,
  STATUS_YELLOW_COLOR
} from "src/constants";
import ShowFilterButton from "src/components/Filter/ShowFilterButton";
import FilterBlock from "../../Configs/FiltersBlock";

const DATE_FORMAT = 'dd/MM/yyyy HH:mm:ss';
const INITIAL_PAGE_SIZE = 100;

const HistoryTags = observer(() => {
  const { tags, getTagsHistory } = DeployStore;

  const [historyData, setHistoryData] = useState([]);

  const fromDefaultDate = moment().subtract('months', 1).toDate();
  const toDefaultDate = moment().toDate();

  const [fromDate, setFromDate] = useState(fromDefaultDate);
  const [toDate, setToDate] = useState(toDefaultDate);
  const [currentTag, setCurrentTag] = useState('');

  const tableColumns = useMemo(
    () => [
      "create_t",
      "name",
      "type",
      "author",
      "info"
    ],
    []
  );

  const columns = useMemo(() => {
    return (
      tableColumns.map((field) => ({
        Header: field,
        accessor: field,
        Cell: DeployTableCell,
      })) || []
    );
  }, [tableColumns]);

  const tagNames = useMemo(() => {
    let values = [];

    if (tags?.data) {
      values = tags?.data?.map((tag) => (
        {
          "value": tag.data.tag
        }
      ));
      values.unshift({ "value": 'none' });
    }
    return values;
  }, [tags]);

  useEffect(() => {
    setCurrentTag('none');
  }, [tags]);

  const getHistory = useCallback((query = "") => {
    const from = Number(moment(fromDate).format("X"));
    const to =  Number(moment(toDate).format("X"));
    const currentTagValue = currentTag === 'none' ? null : currentTag;
    getTagsHistory(currentTagValue, from, to, query).then(data => setHistoryData(data || []))
  }, [currentTag, fromDate, toDate, getTagsHistory]);

  useEffect(() => {
    if (currentTag) {
      getHistory()
    }
  }, [currentTag, getHistory]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage
  } = useTable({
    columns,
    data: historyData,
    initialState: {
      pageIndex: 0,
      pageSize: INITIAL_PAGE_SIZE,
      sortBy: DEFAULT_SORTIG_COLUMN_FOR_TABLE
    }
  }, useSortBy, useExpanded, usePagination);


  const total = historyData?.length;

  const setRowColor = (type) => {
    const textColor = "rgba(255, 255, 255, 0.92);";
    const deleteColor = STATUS_RED_COLOR;
    const deployColor = STATUS_BLUE_COLOR;
    const addColor = STATUS_GREEN_COLOR;
    const failedColor = STATUS_YELLOW_COLOR;

    switch (type) {
      case 'DEPLOY_FAILED':
        return {bg: failedColor, color: textColor}
      case 'DELETE':
        return {bg: deleteColor, color: textColor}
      case 'DEPLOY':
        return {bg: deployColor, color: textColor}
      case 'ADD':
        return {bg: addColor, color: textColor}
       default:
        return {bg: 'inherit', color: 'inherit'}
    }
  }
  const tableRef = useRef(null);

  const getWidth = (columnName) => {
    return columnName === 'name' ? "auto" : "200px"
  }

  return (
    <Fragment>
      <Box>
      <Flex marginBottom={5} justifyContent="space-between" alignItems="self-end">
        <FormControl>
          <FormLabel>Tag</FormLabel>
          <Combobox items={tagNames} placeholderMsg='Tag' value={currentTag} key={0} setValue={setCurrentTag} w="90%" />
        </FormControl>
        <FormControl>
          <FormLabel>From time</FormLabel>
          <DatePicker
            selected={fromDate}
            onChange={setFromDate}
            showTimeSelect
            dateFormat={DATE_FORMAT}
          />
        </FormControl>
        <FormControl>
          <FormLabel>To time</FormLabel>
          <DatePicker
            selected={toDate}
            onChange={setToDate}
            showTimeSelect
            dateFormat={DATE_FORMAT}
          />
        </FormControl>
        <FormControl>
          <ShowFilterButton />
        </FormControl>
      </Flex>
      <FilterBlock handleLoadConfigs={getHistory} />
      {historyData.length ?
        <>
          <Table {...getTableProps()} ref={tableRef} style={{tableLayout: "fixed"}}>
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
                  {headerGroup.headers.map((column) => (
                    <Th
                      key={column}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      onClick={() => column.toggleSortBy(!column.isSortedDesc)}
                      w={getWidth(column.Header)}
                      maxW={getWidth(column.Header)}
                      minW={getWidth(column.Header)}
                    >
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        textTransform: 'none'
                      }}>
                        {column.render('Header')}
                        <SortColumnIcon
                          sortedIndex={column.sortedIndex}
                          canSort={column.canSort}
                          isSortedDesc = {column.isSortedDesc}
                        />
                      </div>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                const type = row.cells.find(cell => cell.column.id === 'type').value;
                return (
                  <Tr {...row.getRowProps()} key={row.id}>
                    {row.cells.map((cell) => (
                      <Td
                        {...cell.getCellProps()}
                        key={`${cell.value}`}
                        {...setRowColor(type)}
                      >
                        {cell.render('Cell')}
                      </Td>
                    ))}
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
          {!!total && (
            <Box>
              <IconButton
                variant='outline'
                icon={<NavArrowLeft />}
                onClick={() => scrollTopAfterEvent(previousPage, tableRef)}
                isDisabled={!canPreviousPage}
                m={3}
              />
              <IconButton
                variant='outline'
                icon={<NavArrowRight />}
                onClick={() => scrollTopAfterEvent(nextPage, tableRef)}
                isDisabled={!canNextPage}
                m={3}
              />
            </Box>
          )}
        </>
        : 'History not found'}
      </Box>
    </Fragment>
   )
});

export default HistoryTags;
import {
  Box, Center, Flex, Heading, VStack
} from '@chakra-ui/react';
import { GaijinLogo } from '@gjn/react-uikit';
import { observer } from "mobx-react";
import { Link } from 'react-router-dom';
import SidebarGames from './SidebarGames';
import { DEFAULT_LINK } from 'src/constants';

const SidebarHeader = observer(({isCollapse}) => {
  return (
    <Flex>
      <VStack w="100%">
        <Center bg="gray.900" w="full">
          <Link to={DEFAULT_LINK}>
            <Center w="16">
              <GaijinLogo w="12" h="14" p="1" color="white" />
            </Center>
          </Link>
          <Box flex="1">
            <Heading as='h5' size='sm'>
              {isCollapse
                ? ''
                : 'Application Platform'
              }
            </Heading>
          </Box>
        </Center>

        <SidebarGames isCollapse={isCollapse} />
      </VStack>
    </Flex>
  );
})

export default SidebarHeader
import { observer } from "mobx-react"
import { Tooltip } from "@chakra-ui/react";
import { EyeOff } from "iconoir-react";
import { isEqual } from "lodash";

const EyeOffIcon = observer(({watchedVariables, setWatchedVariables, watchedElement}) => (
  <Tooltip label="unwatched value">
    <EyeOff
      className="watched-value-icon"
      onClick={() =>
        setWatchedVariables(
          watchedVariables.filter(element => !isEqual(element, watchedElement))
        )
      }
    />
  </Tooltip>
));

export default EyeOffIcon;
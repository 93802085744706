import { observer } from "mobx-react";
import { useCallback, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import {
  Accordion,
  AccordionItem,
  Box,
  Flex,
  Input,
  Heading,
  FormLabel,
  Select,
  Button,
  Spinner,
  Text
} from "@chakra-ui/react";
import { GamesStore, UserManagementStore } from "../../../stores";
import {
  CLASSNAME_FOR_SCROLL_BLOCK,
  MAIN_BLOCK_DEFAULT_HEIGHT,
  MAIN_BLOCK_PADDING,
  MAX_INPUT_WIDTH,
  PARAMETRS_FOR_GET_PROFILE,
  TEXT_FOR_NULL_USER_DATA
} from "./constants";
import './style.scss';
import WatchedBlock from "./WatchedBlock";
import UserManagementItem from "./UserManagementItem";
import HistoryInfiniteLoader from "./HistoryInfiniteLoader";
import { TEXT_RED_COLOR } from "src/constants";

const UserManagementTree = observer(({
  fetchData,
  scrollToPosition,
  isLoad,
  error
}) => {
  const {
    userInfo,
    services,
    clearUserProfile,
    putToStorage,
    getFromStorage
  } = UserManagementStore;

  const { projectid, environment } = GamesStore;

  const { register, handleSubmit, setValue } = useForm();
  const [userInfoData, setUserInfoData] = useState(null);

  const [watchedVariables, setWatchedVariables] = useState(getFromStorage('watchedVariables') || []);
  const [mainBlockHeight, setMainBlockHeight] = useState(MAIN_BLOCK_DEFAULT_HEIGHT);
  const [serviceActionsWidth, setServiceActionsWidth] = useState(
    getFromStorage('serviceActionsWidth') || {}
  );

  useEffect(() => {
    const requestData = getFromStorage('requestData') || '';
    const typeForRequest = getFromStorage('typeForRequest') || PARAMETRS_FOR_GET_PROFILE[0];

    setValue('requestData', requestData)
    setValue('typeForRequest', typeForRequest)
  }, [setValue, getFromStorage, projectid, environment]);

  useEffect(() => {
    if (document.getElementById('main-layout')) {
      setMainBlockHeight(document.getElementById('main-layout').clientHeight - MAIN_BLOCK_PADDING)
    }
  }, [])

  useEffect(() => {
    setUserInfoData(userInfo?.data);
  }, [userInfo]);

  const viewUserProfile = (profileData, typeForRequest) => {
    fetchData( typeForRequest, profileData );
    clearUserProfile();
  };

  const handleScroll = useCallback((event) => {
    putToStorage('scrollTopValue',  event.target.scrollTop)
  }, [putToStorage]);

  useEffect(() => {
    putToStorage('watchedVariables', watchedVariables)
  }, [watchedVariables, putToStorage])

  useEffect(() => {
    const scrollElement = document.getElementsByClassName(CLASSNAME_FOR_SCROLL_BLOCK)[0];
    if (scrollElement) {
      scrollToPosition();
    }
    scrollElement?.addEventListener('scroll', handleScroll);
    return () => {
      scrollElement?.removeEventListener('scroll', handleScroll);
    };
  }, [scrollToPosition, handleScroll, services]);

  const accordionIdFromStorage = getFromStorage('accordion_id');

  const errorBlock = () => {
    return <Text color='tomato'>{error || null} </Text>
  }

  const userManagementBlockRef = useRef(null);
  const [openIndexes, setOpenIndexses] = useState(accordionIdFromStorage
    ? [...accordionIdFromStorage]
    : [0]
  );

  const isOpenHistory = openIndexes.includes(services.length);

  return (
    <Box height={`${mainBlockHeight}px`}>
      <>
        <Flex justifyContent="space-between" mb="15px">
          <div>
            <FormLabel>Choose parameters for search:</FormLabel>
            <form
              style={{
                display: 'flex',
              }}
              onSubmit={handleSubmit((data) => {
                putToStorage('requestData', data.requestData)
                putToStorage('typeForRequest', data.typeForRequest)
                return viewUserProfile(data.requestData, data.typeForRequest)
              })}
            >
              <Select
                h={"31px"}
                {...register("typeForRequest", {required: true})}
              >
                {PARAMETRS_FOR_GET_PROFILE.map(el => (
                  <option
                    key={el}
                    value={el}
                  >
                    {el}
                  </option>
                ))}
              </Select>
              <Input ml={3} minW={250} {...register("requestData", {required: true})} />
              <Button
                ml={3}
                minW={MAX_INPUT_WIDTH}
                type="submit"
              >
                View user profile
              </Button>
            </form>
          </div>
          { userInfoData
            ? <Heading as="h6" size="xs" mt={5} ml={2}>
                User Info
                <div className="user-management-user-info">
                  <span>
                    <u>login:</u>
                    {userInfoData.login || <span style={{color: TEXT_RED_COLOR}}>{TEXT_FOR_NULL_USER_DATA}</span>};
                    {" "}
                  </span>
                  <span>
                    <u>nick:</u>
                    {userInfoData.nick || <span style={{color: TEXT_RED_COLOR}}>{TEXT_FOR_NULL_USER_DATA}</span>};
                    {" "}
                  </span>
                  <span><u>userid:</u> {userInfoData.userid};</span>
                </div>
              </Heading>
            : null
          }
        </Flex>
      </>
      {isLoad ?
        services.length ?
          <div className="user-management-main-block">
            <Flex
              justifyContent="space-between"
              className="user-management-block"
              ref={userManagementBlockRef}
            >
              <div
                className={CLASSNAME_FOR_SCROLL_BLOCK}
              >
                <Accordion
                  allowToggle
                  mt="10px"
                  index={openIndexes}
                  onChange={(index) => {
                    putToStorage('accordion_id', index)
                    setOpenIndexses(index)
                  }}
                  allowMultiple
                  h={isOpenHistory ? "100%" : 'auto'}
                >
                  {services.map((service) => {
                    return(
                      <AccordionItem key={service}>
                        {({ isExpanded }) => (
                          <UserManagementItem
                            isExpanded={isExpanded}
                            service={service}
                            serviceActionsWidth={serviceActionsWidth}
                            setServiceActionsWidth={setServiceActionsWidth}
                            watchedVariables={watchedVariables}
                            setWatchedVariables={setWatchedVariables}
                            userManagementBlockRef={userManagementBlockRef}
                          />
                        )}
                      </AccordionItem>)
                    })
                  }
                  <AccordionItem
                    id={isOpenHistory ? "history" : ""}
                    h={isOpenHistory ? "100%" : "auto"}
                  >
                    {({ isExpanded }) => <HistoryInfiniteLoader isOpened={isExpanded}/>}
                  </AccordionItem>
                </Accordion>
              </div>
            </Flex>
            <WatchedBlock
              watchedVariables = {watchedVariables}
              setWatchedVariables = {setWatchedVariables}
            />
          </div>
        : errorBlock()
      : <Flex
          alignItems="center"
          justifyContent="center"
          position="absolute"
          top="0"
          bottom="0"
          w="100%"
        >
          <Spinner />
        </Flex>
      }
    </Box>
  )
})

export default UserManagementTree
import { observer } from 'mobx-react';
import './style.scss';

const SortColumnIcon = observer(({
  canSort,
  sortedIndex,
  isSortedDesc
}) => {
  return (
    canSort && sortedIndex !== -1
      ? <span>
          {isSortedDesc
            ? <div className="arrow-icon"/>
            : <div className="arrow-icon up"/>
          }
        </span>
      : null
  )
})
export default SortColumnIcon
import { EditorView } from '@codemirror/view';
import { formatWithCommaApply } from './formatCommaApply';

export const dispatchViewChanges = (
  view: EditorView,
  from: number,
  to: number,
  insertText: string,
  specSymbol?:string
) => {
  view.dispatch({changes: {from, to, insert: formatWithCommaApply(insertText, view, specSymbol)}});
}
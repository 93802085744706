import { flow, makeObservable, observable, action } from "mobx";
import AuthApi from "../api/auth";
import TokenManagemenApi from "../api/tokenManagement";
import { AuthStore } from ".";

class TokenManagementStore {
  constructor() {
    this.exchangeToken = null;
    this.exchangeTokenEnabled = false;

    makeObservable(this, {
      exchangeToken: observable,
      getToken: flow.bound,
      getTokenManagementData: flow.bound,
      clearTokenManagementData: action.bound,
      exchangeTokenEnabled: observable,
      getTokenPayload: flow.bound,
    });
  }

  *getToken({jwt}) {

    try {
      const { data } = yield AuthApi.exchangeToken({jwt});
      this.exchangeToken = data?.result || null;
    } catch (e) {
      this.exchangeToken = null;
    }
  }

  *getTokenManagementData() {
    try {
      const { user } = AuthStore;

      const { data } = yield TokenManagemenApi.getTokenManagementData({jwt: user.token});
      this.exchangeTokenEnabled = data?.result?.exchangeTokenEnabled;
    } catch (e) {
      this.exchangeTokenEnabled = false;
    }
  }

  *getTokenPayload({token}) {
    try {
      const { user } = AuthStore;

      const { data } = yield TokenManagemenApi.getTokenPayload({jwt: user.token, token});
      return data.result
    } catch (e) {
      console.log('error', e)
      return null;
    }
  }

  clearTokenManagementData() {
    this.exchangeToken = null;
    this.exchangeTokenEnabled = false;
  }
}

export default new TokenManagementStore();
import { values } from "lodash";
import { flow, makeObservable, observable, action, computed } from "mobx";
import Storages from "src/services/Storages";
import { GamesStore, MenuPageSelectorsStore } from ".";
import leaderboardApi from "../api/leaderboard";

const leaderboardKeyStorage = (projectid) => `lb-page-description-${projectid}`;

class LeaderboardStore {
  constructor() {
    this.leaderboard = null;
    this.description = null;
    this.lbPageDescription = [];
    this.sortBy = []

    makeObservable(this, {
      leaderboard: observable,
      description: observable,
      loadLeaderboard: flow.bound,
      loadLeaderboardDescription: flow.bound,
      clearLeaderboard: action.bound,
      leaderboardDescriptionStorage: computed,
      lbPageDescription: observable,
      setlbPageDescription: action.bound,
      removelbPage: action.bound,
      loadlbPageDescription: action.bound,
      currentlbPageData: computed,
      currentleaderboardDescription: computed,
      setlbPageDescriptionByIndex: action.bound,
      clearLeaderboardAndDescription: action.bound,
      sortBy: observable,
      setLBSortBy: action.bound,
      clearlbPageDescription: action.bound
    });
  }

  *loadLeaderboard(params) {
    const { projectid, environment } = GamesStore;

    try {
      const { data } = yield leaderboardApi.getLeaderboard(
        { projectid, environment, params });

      this.leaderboard = data || [];
      return data
    } catch (e) {
      this.leaderboard = [];
    }
  }

  *loadLeaderboardDescription() {
    const { projectid, environment } = GamesStore;

    try {
      const { data } = yield leaderboardApi.getLeaderboardDescription(
        { projectid, environment });

      this.description = data;
      return data
    } catch (error) {
      console.log(error);
    }
  }

  setlbPageDescription(description) {
    const { projectid } = GamesStore;
    this.lbPageDescription = [...description];
    Storages.put(leaderboardKeyStorage(projectid), description);
  }

  get leaderboardDescriptionStorage() {
    const { projectid } = GamesStore;
    return Storages.get(leaderboardKeyStorage(projectid))
  }

  get currentlbPageData() {
    const { index } = MenuPageSelectorsStore;
    return this.lbPageDescription ? this.lbPageDescription[index] : null;
  }

  get currentleaderboardDescription() {
    return this.currentlbPageData?.leaderboardDescription || null
  }

  removelbPage(index) {
    const { projectid } = GamesStore;
    this.lbPageDescription.splice(index, 1);
    Storages.put(leaderboardKeyStorage(projectid), this.lbPageDescription);
  }

  setlbPageDescriptionByIndex(index, data) {
    this.lbPageDescription[index] = data;
    this.setlbPageDescription(this.lbPageDescription);
  }


  loadlbPageDescription(name) {
    const { addPageSelector } = MenuPageSelectorsStore;
    const keysTabs = values(this.leaderboardDescriptionStorage);
    this.lbPageDescription = this.leaderboardDescriptionStorage;
    if (keysTabs.length) {
      keysTabs.forEach((value, index) => {
        addPageSelector(value.newItemName, true, name, index === 0);
      })
    } else {
      addPageSelector(name, true, name, true);
    }
  }

  setLBSortBy(sortBy) {
    this.sortBy = sortBy
  }

  clearLeaderboard() {
    this.leaderboard = null;
  }

  clearLeaderboardAndDescription() {
    this.clearLeaderboard();
    this.description = null;
  }

  clearlbPageDescription() {
    this.lbPageDescription = [];
  }
}

export default new LeaderboardStore();
import { action, makeObservable, observable } from "mobx";

class ProgressStore {
  constructor() {
    this.showProgress = false;

    makeObservable(this, {
      showProgress: observable,
      setShowProgress: action.bound
    });
  }

  setShowProgress(value) {
    this.showProgress = value
  }
}

export default new ProgressStore();
import * as JSZip from 'jszip';
import { entries, set } from 'lodash';
import { getFileByLang, getTextByLang, LANGUAGE } from './configLanguageHelper';

type ScriptDirectoryType = object;

type PromisesFileType = {
  [key: string]: Promise<string> | string
}


export const getConfigFile = ( data: object, lang: LANGUAGE ) => {
  let text = getTextByLang(lang, data);
  let base = btoa(unescape(encodeURIComponent(text)));
  let href = `data:application/json;base64, ` + base;
  download(href, getFileByLang(lang).fileName);
};

export const getConfigZIPAndUnpack = (
  data: {archive: string, name: string}
): Promise<void | ScriptDirectoryType> => {
  const directory: ScriptDirectoryType = {};
  const promises: PromisesFileType = {}

  return getZipFromArchive(data.archive).then(zip => {
    zip.forEach(file => {
      const getAsyncFile = zip.file(file)?.async('string');
      promises[file] = getAsyncFile || ''
    })
    return Promise.all(entries(promises))
  }).then(async content => {
    for (const [key, file] of content) {
      const paths = key.split('/').filter(path => path);
      const promiseContent = await file;
      set(directory, paths, promiseContent || {});
    }
    return directory
  },  console.error)
}

export const getConfigZIP = ( data: {archive: string, name: string} ) => {
    let href = `data:application/zip;base64, ` + data.archive;
    download(href, `${data.name || 'scripts_archive'}.zip`);
};

const download = (href: string, file: string) => {
    let link = document.createElement("a");
    link.href = href;
    link.download = file;
    link.click();
};


export const getZipFromArchive = (data: string): Promise<JSZip> => {
  return JSZip.loadAsync(data, {base64: true})
}
import { action, flow, makeObservable, observable } from "mobx";
import Storages from "../services/Storages";
import AuthApi from "../api/auth";

class AuthStore {
  constructor() {
    this.user = Storages.get('user') || null;
    this.processLogin = false;

    makeObservable(this, {
      login: flow.bound,
      user: observable,
      logout: action.bound
    });
  }


  *login(jwt) {
    const { data } = yield AuthApi.exchangeToken({jwt});

    this.user = { token: data.result }

    Storages.put('user', this.user);
  }

  logout(path = '/') {
    const gsea = window.GSEA || undefined;

    gsea.logout().then(() => {
      window.location.href = path;
      Storages.put('user', null);
    });

    return null;
  }
}

export default new AuthStore();
export const MAX_INPUT_WIDTH = 135;
export const HEIGHT_MARGIN = 280;
export const MAX_WATCHED_VALUES_LENGTH = 10;
export const MAIN_BLOCK_PADDING = 170;
export const PARAMETRS_FOR_GET_PROFILE = ["userid", "nick", "login"];

export const MAIN_BLOCK_DEFAULT_HEIGHT = 100;
export const MAIN_BLOCK_DEFAULT_MARGIN = 100;
export const SERVICE_ACTIONS_DEFAULT_WIDTH = "500px";

export const CLASSNAME_FOR_SCROLL_BLOCK = 'user-management-block__content';

export const GET_USER_PROFILE_ERROR_MESSAGE = 'Get user profile error: ';
export const PROFILE_IS_EMPTY = 'Profile is empty';

export const NAME_FOR_JSON_ACTION_DATA_STORAGE = 'actionsData';
export const KEY_FOR_TREE_ROOT = 'root';

export const GET_ACTION_PERMISSIONS_ERROR_MESSAGE = "No permissions.";
export const GET_ACTION_ERROR_MESSAGE = "Getting actions error. See details in console.";

export const TEXT_FOR_NULL_USER_DATA = "undefined";

export const TEXT_FOR_COPY_OBJECT_NAME = "Copy object name";
export const TEXT_FOR_COPY_CONTENT = "Copy content";
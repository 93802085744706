import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer
} from '@chakra-ui/react';
import { get, omit } from 'lodash';
import { observer } from "mobx-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { ConfigElementStore, ConfigsViewStore, ConfigsStore, SchemaStore, MenuPageSelectorsStore, FilterStore } from "../../../stores";
import CodeEditor from '../../CodeEditor';
import "./ChangeForSelectedModal/style.scss";
import { getAllPathKeys } from 'src/helpers/getAllPathKeys';
import { getDefaultValueByLang } from 'src/helpers/configLanguageHelper';
import { getJsonParse } from "src/helpers/JSONparseHelper";
import ReactDiff2Html from 'src/helpers/reactDiff2Html';
import Progress from 'src/components/Progress';
import { getJSONstringify } from 'src/helpers/JSONSstringifyHelper';

const VALUE_FOR_DELETE = null;

const ChangeForSelectedModal = observer(({
  isOpen,
  setIsOpen,
  currentPageSelector,
  elementName,
  handleLoadConfigs
}) => {
  const {
    saveAllConfigList,
    configs
  } = ConfigsStore;

  const { setElementHasErrors, clearAllErrorsAndChanges } = ConfigElementStore;
  const { isJSONLang, checkedConfigs, setConfigCheckedAll, language } = ConfigsViewStore;
  const { currentPageSelectorName } = MenuPageSelectorsStore;
  const { queryValue } = FilterStore;

  const initialRef = useRef(null);
  const [editorText, setEditorText] = useState('');
  const [commonData, setCommonData] = useState({});
  const [isValidData, setIsValidData] = useState(false);

  const [selectedData, setSelectedData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [isShowDiff, setIsShowDiff] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([])

  const { schemaForAllElementsWithRef, loadSchema } = SchemaStore;

  useEffect(() => {
    setEditorText(getDefaultValueByLang(language))
  }, [language])

  useEffect(() => {
    let selected = [];
    let keys = [];
    configs.data.forEach((config) => {
      if (checkedConfigs.has(config.internal.key)) {
        selected.push(config.data)
        keys.push(config.internal.key)
      }
    })
    setSelectedData(selected);
    setSelectedKeys(keys)
  }, [configs, checkedConfigs])

  useEffect(() => {
    loadSchema(elementName, true)
    setCommonData(getDefaultValueByLang(language));
  }, [currentPageSelector, language, loadSchema, elementName]);

  const checkChanges = useCallback((editorText) => {
    if (!editorText) {
      return false
    }

    const codeEditorValues = getJsonParse(editorText);
    if (!codeEditorValues) {
      return false
    }

    let current = []
    selectedData.forEach((config) => {
      let data = {...config, ...codeEditorValues};
      getAllPathKeys(data, [], VALUE_FOR_DELETE, true).forEach(el => {
        if (get(data, el) === VALUE_FOR_DELETE) {
          data = omit(data, el)
        }
      });
      current.push(data);
    })

    return current
  }, [selectedData]);


  const sendCommonDataHandler = () => {
    const changesData = checkChanges(editorText);
    if (changesData) {
      const changesDataWithKeys = selectedKeys.map((el, index) => ({...changesData[index], key: el}))
      saveAllConfigList(elementName, changesDataWithKeys).then(() => {
        handleClose();
        handleLoadConfigs(currentPageSelectorName, queryValue);
        setConfigCheckedAll(false);
      });
    }
  }

  const handleShowDiff = () => {
    setIsShowDiff(!isShowDiff);
  }

  useEffect(() => {
    if (isShowDiff) {
      setIsValidData(false);
      const changesData = checkChanges(editorText);
      setCurrentData(changesData)
    }
  }, [editorText, isShowDiff, checkChanges]);

  useEffect(() => {
    setIsValidData(!!currentData);
  }, [currentData])

  const handleClose = () => {
    setIsOpen(false);
    clearAllErrorsAndChanges()
  }

  const rowIndexForModalEditor = configs.data.length + 1;

  return (
    <Modal
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={handleClose}
      size='large'
    >
      <ModalOverlay />
      <ModalContent  className="modal-for-change-selected-content">
        <ModalHeader>Change values for checked rows</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl mb={5} isInvalid={!isValidData}>
            <FormLabel>Enter values</FormLabel>
            <CodeEditor
              data={commonData}
              schema={schemaForAllElementsWithRef}
              updateChanges={setEditorText}
              setErrors={setElementHasErrors}
              checkRequiredValues={false}
              isJSONLang={isJSONLang}
              lang={language}
              isUseMaxWidth={false}
              newData={editorText}
              id={rowIndexForModalEditor}

            />
            <Flex justifyContent="end" mt={2}>
              {!isValidData ? <FormErrorMessage>Validation error</FormErrorMessage> : null}
              <Spacer />
              <Button onClick={handleShowDiff}>
                {isShowDiff ? 'Hide diff' : 'Show diff'}
              </Button>
            </Flex>
            {isValidData && isShowDiff && currentData
              ? <Box className='diffs-block' mt={1}>
                  {selectedData.map((data, index) => {
                    return <>
                    <div>{data.name}</div>
                      <ReactDiff2Html
                        past={ getJSONstringify(data, '\t') }
                        current={ getJSONstringify(currentData[index], '\t') }
                      />
                    </>
                  })}
                </Box>
              : <Box position="relative">
                  {isShowDiff ? <Progress /> : null}
                </Box>}
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme='blue' mr={3} onClick={sendCommonDataHandler} isDisabled={!isValidData}>
            Save
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
})

export default ChangeForSelectedModal;
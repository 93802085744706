import {
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  UnorderedList
 } from "@chakra-ui/react";
import { InfoEmpty } from "iconoir-react";
import { observer } from "mobx-react";


const FiltersBlockHelp = observer(() => {
  return (
    <Popover>
    <PopoverTrigger>
      <InfoEmpty cursor="pointer" />
    </PopoverTrigger>
    <PopoverContent w="100%">
      <PopoverArrow />
      <PopoverCloseButton />
      <PopoverHeader>Filters available:</PopoverHeader>
      <PopoverBody className="filter-info-block">
        <UnorderedList>
          <ListItem>
            Equality/inequality - matches records where value of the field is equal/unequal to specified value, ex.:
            <Text as='samp'> foo=42, foo!=-3.14, foo!="a string", foo=true, foo=false</Text>
          </ListItem>
          <ListItem>
            Regex - if field value is a string, matches it against specified regex, ex.:
            <Text as='samp'> foo=/^https?:\/\// foo=/^https?:\/\//</Text>
          </ListItem>
          <ListItem>
            Comparison
            <Text as='samp'> {"(<, >, <=, >=)"} </Text>
            - if field value is a number, compares it with specified value, ex.:
            <Text as='samp'> {"foo>42, foo<=-3.14"}</Text>
          </ListItem>
          <ListItem>
            Presence - matches records where specified field is present/missing, ex.:
            <Text as='samp'> foo exists, foo !exists</Text>
          </ListItem>
          <ListItem>
            Array - matches records where the value of a field equals to any value in the array, ex.:
            <Text as='samp'> foo in [42, -3.14, "bar", true, false]</Text>
          </ListItem>
        </UnorderedList>
        <Text mt={1}>Complex queries can be constructed using logical AND
          (<Text as='samp'>&</Text>) and OR (<Text as='samp'>|</Text>)
          operators, ex.:
          <Text as='samp'> foo=42&bar exists</Text>
        </Text>
        <Text mt={1}>Filters can be grouped by parenthesis, ex.:
          <Text as='samp'> {"foo=42&(bar exists|foo<100)"}</Text>
        </Text>
        <Text mt={1} >
          <span> {"Operator precedence (from highest to lowest): "}</span>
          <Text as='samp'> {"(), &, |"}</Text>
        </Text>
        <Text mt={1}>Spaces may be omited where possible:
          <Text as='samp'> {"foo = 42 & bar in [1, 2, 3] | baz exists"} </Text>
          is equivalent to
          <Text as='samp'> {"foo=42&bar in[1,2,3]|baz exists"}</Text>
        </Text>
        <Text mt={1}>Array may have trailing comma:
          <Text as='samp'> {"[1, 2, 3,]"} </Text>
          is equivalent to
          <Text as='samp'> {"[1, 2, 3]"}</Text>
        </Text>
        <Text mt={1}>To search by date, use the timestamp format
          <Text as='samp'> {"create_t=1708437468"} </Text>
        </Text>
      </PopoverBody>
    </PopoverContent>
  </Popover>
    );
})

export default FiltersBlockHelp;
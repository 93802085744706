import { gutter, GutterMarker, EditorView } from "@codemirror/view";
import { getTextByLang, LANGUAGE } from "src/helpers/configLanguageHelper";
import { getUnifiedDiffFromJsDiff } from "src/helpers/getUnifiedDiffFromJsDiff";

const changeElementMarker = class extends GutterMarker {
  toDOM() { return document.createTextNode("✏️") }
}

// Add icon for changed elements
export const changeElementLineGutter = (markers, isNewEntry) => {
  return gutter({
    lineMarker(view, line) {
      const lineNumber = view.state.doc.lineAt(line.from).number;
      const isEqualCurrentAndIncoming = markers.includes(lineNumber);
      // if new row we mark all parameters as changed
      if (isNewEntry) {
        return new changeElementMarker()
      }
      if (isEqualCurrentAndIncoming) {
        return new changeElementMarker()
      }
    },
  })
}

const getOpenSymbol = (lang) => {
  switch (lang) {
    case LANGUAGE.JS:
      return 'function'
    default:
      return '{';
  }
}

export const listenerForChangedElements = (setChangedLines, data, lang) => (
  EditorView.updateListener.of((view) => {
    if (view.docChanged) {
      const markLines = [];

      const dataString = getTextByLang(lang, data);
      const viewString = view.state.doc.toString();
      if (!(dataString && viewString)) {
        return;
      }
      // Get diff between data and view
      const formatedStr = getUnifiedDiffFromJsDiff(
        "",
        "",
        dataString,
        viewString,
        "",
        "",
        {
          context: 'Infinity'
        }
      );

      // if we deleted some lines
      if (dataString.split('\n').length > viewString.split('\n').length) {
        markLines.push(1);
      }
      let currentIndex = 0;
      let isObjectStart = false;
      const splitFormatedStr = formatedStr.split('\n');
      splitFormatedStr.forEach((stringElement, index) => {
        // Get only old lines and changed lines (modified lines with "+")
        if (splitFormatedStr[index].includes(getOpenSymbol(lang))) {
          isObjectStart = true;
        }
        if (isObjectStart && stringElement[0] !== '-') {
          currentIndex++;
          if (stringElement[0] === '+') {
            markLines.push(currentIndex);
          }
        }
      });

      setChangedLines(markLines);
    }
  })
)

export const lineGutterElementDefaultSize = EditorView.theme({
  ".cm-gutter": {
    overflow: 'visible'
  }
})
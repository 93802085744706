import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Input,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Checkbox
} from "@chakra-ui/react"
import { observer } from "mobx-react";
import { useState, useRef, useEffect, useMemo } from 'react';
import { WARNING_STATUS } from "src/constants";
import { DeployStore, GamesStore } from "src/stores";

const ConfirmDeployModal = observer(({ tagConfirmed, setTagConfirmed }) => {
  const { updateConfigsTag } = DeployStore;
  const { environment } = GamesStore;

  const isProdEnv = environment === 'production';

  const [tagConfirmedValue, setTagConfirmedValue] = useState('');
  const [ignoreWarnings, setIgnoreWarnings] = useState(false);
  const [errorTagConfirmed, setErrorTagConfirmed] = useState(false)

  const handleChangeConfirmTag = (e) => {
    const { value } = e.target;
    setTagConfirmedValue(value);
    setErrorTagConfirmed(value !== tagConfirmed.tag)
  }

  const tag = useMemo(() => tagConfirmed?.tag || '', [tagConfirmed]);
  const status = useMemo(() => tagConfirmed?.status || '', [tagConfirmed]);

  useEffect(() => {
    setTagConfirmedValue('');
    setErrorTagConfirmed(isProdEnv);
    setIgnoreWarnings(false);
  }, [isProdEnv, tag])

  const initialRef = useRef(null);

  const handleCloseModal = () => {
    setTagConfirmed(null);
  }

  const handleConfirmDeploy = () => {
    updateConfigsTag(tag, ignoreWarnings);
    handleCloseModal();
  }

  return (
    <Modal
      initialFocusRef={initialRef}
      isOpen={!!tag}
      onClose={handleCloseModal}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirm deploy</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          Confirm deploy <b>'{tag}'</b> tag?
          <FormControl mt='10px' display="flex" alignItems="center">
            <Checkbox
              isChecked={ignoreWarnings}
              onChange={() => setIgnoreWarnings(!ignoreWarnings)}
            />
            <FormLabel ml={1} mb={0}>Ignore warnings</FormLabel>
          </FormControl>
          {isProdEnv
            ? <FormControl mt='10px' isInvalid={errorTagConfirmed}>
                <Input
                  size="md"
                  variant="outline"
                  placeholder='Input tag'
                  value={tagConfirmedValue}
                  onChange={handleChangeConfirmTag}
                />
                <FormErrorMessage>Tag for update is not confirmed</FormErrorMessage>
              </FormControl>
            : null
          }
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={handleConfirmDeploy}
            colorScheme='blue'
            mr={3}
            isDisabled={errorTagConfirmed || (status === WARNING_STATUS && !ignoreWarnings)}
          >
            Deploy
          </Button>
          <Button onClick={handleCloseModal}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
})

export default ConfirmDeployModal
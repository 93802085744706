import { observer } from "mobx-react";
import { Table, Tbody, Td, Tr } from '@chakra-ui/react';
import { SingleConfigStore } from "src/stores";
import { useCallback, useEffect } from "react";
import CodeEditor from "src/components/CodeEditor";
import { STATUS_GREEN_COLOR, STATUS_RED_COLOR } from "src/constants";
import { isEqual, keys } from "lodash";
import { getJSONstringify } from "src/helpers/JSONSstringifyHelper";

const SingleConfigContent = observer(({ configData, schema, errors, setErrors, editorText, setEditorText }) => {
  const {
    setIsSingleConfigChanged,
    isSingleConfigChanged
  } = SingleConfigStore;

  useEffect(() => {
    setEditorText(getJSONstringify(configData))
  }, [configData, setEditorText]);

  const handleSetError = useCallback((_, value) => {
    if (!isEqual(value, errors)) {
      setErrors(value);
    }
  }, [errors, setErrors]);

  const getRowColor = () => {
    if (keys(errors)?.length > 0) {
      return STATUS_RED_COLOR
    }
    if (isSingleConfigChanged) {
      return STATUS_GREEN_COLOR
    }
    return 'inherit'
  }

  return (
    <Table>
      <Tbody>
        <Tr bg={getRowColor()}>
          <Td>
            <CodeEditor
              newData={editorText}
              data={configData}
              schema={schema}
              updateChanges={setEditorText}
              setErrors={handleSetError}
              setIsChanged={() => setIsSingleConfigChanged(true)}
              isClearChangedElements={!isSingleConfigChanged}
              clearMarkers={isSingleConfigChanged}
              id={0}
              removeIsChanged={() => setIsSingleConfigChanged(false)}
            />
          </Td>
        </Tr>
      </Tbody>
    </Table>
  )
})

export default SingleConfigContent
import { observer } from "mobx-react";
import {
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  Button,
  Collapse,
  Flex,
  Input,
  useDisclosure,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Select,
  FormControl,
  FormLabel,
  Divider
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { UserManagementStore } from "src/stores";
import "./style.scss";
import { JSONTree } from "react-json-tree";
import { themeMonokai } from "../themeMonokai";
import { NavArrowDown, NavArrowUp, Settings } from "iconoir-react";
import {
  COLUMNS_WITH_FILTERS,
  DEFAULT_COLUMN,
  DEFAULT_DETAILS_TEXT,
  EMPTY_DETAILS,
  FIRST_COLUMN_WIDTH,
  KEY_FOR_LOAD_DETAILS,
  KEY_FOR_TREE_ROOT,
  LABEL_FOR_TIMESTAMP,
  TIMESTAMP_COLUMN_NAME,
  NEW_ELEMENT_BACKGROUND,
  ELEMENTS_TRANSITION,
  MIN_COLUMN_WIDTH,
  COLUMN_WITH_SORT_HIDDEN_COLUMNS,
  DEFAULT_COLUMN_WIDTH,
  MAX_COLUMN_WIDTH,
  INIT_VISIBLE_COLUMNS,
  ICON_COLUMN_NAME,
  DATE_FORMAT,
  GROUP_DIVIDER_WIDTH,
  BORDER_FOR_GROUP_WIDTH,
  BORDER_WIDTH_DEFAULT
} from "./constants";
import { formatCreateTimeUTC } from "src/helpers/formatCreateTime";
import ButtonWithTooltip from "src/components/ButtonWithTooltip";
import Progress from "src/components/Progress";
import ChangeVisibilityForColumns from "src/components/ChangeVisibilityForColumns";
import CopyIcon from "../Icons/CopyIcon";
import { keys, omit, values } from "lodash";
import ChangeVisibilityIcon from "src/components/ChangeVisibilityForColumns/ChangeVisibilityIcon";
import ShowFilterButton from "src/components/Filter/ShowFilterButton";
import { usePrevious } from "react-use";
import { useTable, useResizeColumns, useFlexLayout, useExpanded, useBlockLayout, useFilters } from 'react-table';
import IconCell from "src/components/IconCell";
import DatePicker from "react-datepicker";
import * as moment from "moment";
import { convertUTCToLocalDate } from "src/helpers/convertUTCToLocalDate";
import { convertLocalToUTCDate } from "src/helpers/convertLocalToUTCDate";
import { getColumnsWithSeparator } from "src/helpers/getColumnsWithSeparator";

// TODO change naiming
const HistoryInfiniteLoader = observer(({isOpened}) => {
  const {
    getUserHistory,
    putToStorage,
    getFromStorage,
    historyColumns,
    columnsValues,
    clearStartParameters,
    hasDetails,
    servicesWithHistory
  } = UserManagementStore;

  const [savedFilters, setSavedFilters] = useState([]);

  const DefaultColumnFilter = observer(({ column, state }) => {
    const {filterValue, setFilter} = column;

    useEffect(() => {
      setSavedFilters(state.filters)
    }, [state.filters])

    return (
      column.id === 'service'
        ?	<Select
            onChange={(e) => {
              const id = column.id;
              const value = e.target.value;
              handleFilterOpen(id);
              setFilter(value);
            }}
            value={filterValue}
          >
            <option value="">All services</option>
            {servicesWithHistory.map(service => (
              <option value={service}>{service}</option>
            ))}
          </Select>
        : <Input
            value = {filterValue || ''}
            onChange={e => {
              const value = e.target.value;
              setFilter(value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleFilterOpen(column.id)
              }
            }}
            placeholder="Filter value"
          />
    )
  })

  const columns = useMemo(() => (
    columnsValues.length ? [
      {
        ...DEFAULT_COLUMN,
        minWidth: MIN_COLUMN_WIDTH,
        width:  getFromStorage('columnWidths')?.service || DEFAULT_COLUMN_WIDTH,
        maxWidth: MAX_COLUMN_WIDTH
      },
      ...columnsValues.map((column) => {
        return {
          accessor: column,
          Cell: ({value, row}) => {
            if (column === TIMESTAMP_COLUMN_NAME && value) {
              return formatCreateTimeUTC(value)
            }
            if (column === ICON_COLUMN_NAME && value) {
              return <IconCell
                iconValue={value}
                name={`${row.original.service}${row.original?.itemid ? ' ' + row.original.itemid : ' ' + undefined}`}
              />
            }
            return value
          },
          minWidth: MIN_COLUMN_WIDTH,
          width: getFromStorage('columnWidths')?.[column] || DEFAULT_COLUMN_WIDTH,
          maxWidth: MAX_COLUMN_WIDTH,
          Header: column
      }})
    ] : []), [columnsValues, getFromStorage]);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [openedFilter, setOpenedFilter] = useState([]);
  const [rowsDetails, setRowsDetails] = useState({});
  const [newElements, setNewElements] = useState(new Set());
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [columnsList, setColumnsList] = useState([]);
  const [columnColors, setColumnColors] = useState({});

  const endTimestampFromStorage = getFromStorage('endTimestamp');
  const startTimestampFromStorage = getFromStorage('startTimestamp');

  useEffect(() => {
    setVisibleColumns(getFromStorage('visibleColumns') || INIT_VISIBLE_COLUMNS);
    setColumnColors(getFromStorage('columnColors') || {})
  }, [getFromStorage]);

  const updateVisibleColumns = (columns) => {
    putToStorage('visibleColumns', columns);
    setVisibleColumns(columns);
  }

  const columnWidthsFromStorages = useMemo(() => getFromStorage('columnWidths'), [getFromStorage]);

  useEffect(() => {
    if (visibleColumns.length && columns.length) {
      const cols = getColumnsWithSeparator(visibleColumns, columns, columnColors, columnWidthsFromStorages);
      setColumnsList(cols)
    } else {
      setColumnsList(columns)
    }
  }, [columns, visibleColumns, columnColors, columnWidthsFromStorages])

  const toDefaultDate = endTimestampFromStorage ? new Date(endTimestampFromStorage) : new Date();
  const fromDefaultDate = startTimestampFromStorage
    ? new Date(startTimestampFromStorage)
    : moment(toDefaultDate).subtract('day', 1).toDate();

  const [startTimestamp, setStartTimestamp] = useState(fromDefaultDate);
  const [endTimestamp, setEndTimestamp] = useState(toDefaultDate);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { filters, columnResizing }
  } = useTable(
    {
      columns: columnsList,
      data,
      initialState: {
        filters: savedFilters
      },
      defaultColumn: {Filter: DefaultColumnFilter}
    },
    useExpanded,
    useFlexLayout,
    useResizeColumns,
    useBlockLayout,
    useFilters
  );

  useEffect(() => {
    columnResizing.isResizingColumn &&
      putToStorage('columnWidths', {...columnWidthsFromStorages, ...columnResizing.columnWidths});
  }, [columnResizing, putToStorage, columnWidthsFromStorages])

  const handleFilterOpen = useCallback((columnKey) => {
    setOpenedFilter((prevOpened) => prevOpened.includes(columnKey)
      ? prevOpened.filter(key => key !== columnKey)
      : [...prevOpened, columnKey]
    )
  }, []);

  const loadHistory =  useCallback(async(startDate, endDate) => {
    setIsLoading(true);
    setData([]);
    try {
      const start = moment(startDate).unix();
      const end = moment(endDate).unix();
      const result = await getUserHistory(start, end);
      if (result?.length > 0) {
        setData(result);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  } , [getUserHistory]);

  useEffect(() => {
    if (isOpened) {
      const startTimestampFromStorage = getFromStorage('startTimestamp');
      const endTimestampFromStorage = getFromStorage('endTimestamp');

      if (endTimestampFromStorage && startTimestampFromStorage) {
        loadHistory(startTimestampFromStorage, endTimestampFromStorage);
      }
    } else {
      setData([]);
      clearStartParameters();
    }
  }, [isOpened, clearStartParameters, getFromStorage, loadHistory]);

  const loadMoreRows = () => {
    if (!isLoading) {
      putToStorage('startTimestamp', startTimestamp);
      putToStorage('endTimestamp', endTimestamp);
      return loadHistory(startTimestamp, endTimestamp)
    }
  };

  const DetailsComponent = observer(({data}) => {
    const [isLoading, setIsLoading] = useState(true);
    const { getUserHistoryDetails } = UserManagementStore;

    const { id, service } = data;
    const isLoadedDetails = rowsDetails[id];

    const shouldExpandNodeInitiallyValue = (keyPath) => {
      if (keyPath[0] === KEY_FOR_LOAD_DETAILS || keyPath[0] === KEY_FOR_TREE_ROOT) {
        return keyPath
      }
    };

    const labelRendererValue = (keyPath) => {
      const keyName = keyPath[0];
      const dataWithDetails = isLoadedDetails ? data : {...data, details: "not requested"};
      return (
        <span
          className="label-block"
          onClick={() => {
            if (keyName === KEY_FOR_LOAD_DETAILS && !isLoadedDetails) {
              setIsLoading(false);
              getUserHistoryDetails(service, id).then(res => {
                setRowsDetails({...rowsDetails, [id]: res.details || EMPTY_DETAILS});
                setIsLoading(true);
              })
            }
          }}
        >
          {keyName === KEY_FOR_LOAD_DETAILS && !isLoadedDetails
            ? <span className="details-icon">▶ </span>
            : null}
          {keyName}
          {keyName === KEY_FOR_TREE_ROOT ? <CopyIcon data={dataWithDetails}/> : null}
        </span>)
    }
    return (
      <JSONTree
        data={data}
        theme={themeMonokai}
        invertTheme={false}
        shouldExpandNodeInitially={shouldExpandNodeInitiallyValue}
        labelRenderer={labelRendererValue}
        valueRenderer={(raw, _, key) => {
          return <Box as="span" flex='1' textAlign='left' position="relative" className="service-name-block">
            <span className="service-name-block__text">{raw}</span>
            <span className="service-name-block__progress">
              {key === KEY_FOR_LOAD_DETAILS && !isLoading ? <Progress size={5} /> : null}
            </span>
          </Box>
        }}
      />
    )
  })

  const prevFilterService = usePrevious(filters);

  useEffect(() => {
    const serviceFilter = filters?.find(filter => filter.id === COLUMN_WITH_SORT_HIDDEN_COLUMNS);
    if (serviceFilter && data.length) {
      const currentService = serviceFilter.value;
      const columnsForService = historyColumns[currentService];
      if (columnsForService && values(historyColumns[currentService]).length) {
        setVisibleColumns((prevVisibleColumns) => {
          const newColumns = columns
            .filter(column =>
              (prevVisibleColumns?.includes(column.Header) &&
              values(columnsForService).includes(column.Header)) ||
              column.Header === COLUMN_WITH_SORT_HIDDEN_COLUMNS
            )
            .map(column => column.Header);

            return newColumns
        })
      }
    } else {
      const prevServiceValue = prevFilterService?.find(filter => filter.id === COLUMN_WITH_SORT_HIDDEN_COLUMNS);
      if (!serviceFilter && prevServiceValue) {
        setVisibleColumns(getFromStorage('visibleColumns') || INIT_VISIBLE_COLUMNS)
      }
    }
  }, [filters, data, historyColumns, columns, getFromStorage, columnsValues, prevFilterService])


  const observerRefs = useRef({})

  const rowRef = useCallback((node, id) => {
    if (node !== null) {
      observerRefs.current[id] = node;
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entires => {
        entires.forEach(entry => {
          const elementId = entry.target.getAttribute('data-row-index');
          if (newElements.has(elementId) && entry.isIntersecting) {
            newElements.delete(elementId)
            setNewElements(new Set(newElements))
          }
        })
      }, { threshold: 0.1}
    );
    rows.forEach((row) => {
      if(observerRefs.current[row.original.uuid]) {
        observer.observe(observerRefs.current[row.original.uuid])

      }
    });

    return () => observer.disconnect()
  }, [rows, newElements]);

  const setColor = (color, columnName) => {
    setColumnColors((prev) => ({...prev, [columnName]: color}));
    putToStorage('columnColors', {...getFromStorage('columnColors'), [columnName]: color});
  }

  return (
    <Box pos="relative" h="100%">
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left'>
          <Box as="span" flex='1' textAlign='left' position="relative" className="service-name-block">
            <span className="service-name-block__text"> User's history</span>
            <span className="service-name-block__progress">{isLoading ? <Progress size={5} /> : null}</span>
          </Box>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel display="flex" h="100%" className="history-block-panel">
        <Box className="history-block" height="95%">
          <Flex justifyContent="end">
            <Flex marginBottom={5} justifyContent="space-between" alignItems="self-end">
              <FormControl>
                <FormLabel>From time (UTC)</FormLabel>
                <DatePicker
                  selected={convertUTCToLocalDate(startTimestamp)}
                  onChange={(date) => setStartTimestamp(convertLocalToUTCDate(date))}
                  showTimeSelect
                  dateFormat={DATE_FORMAT}
                />
              </FormControl>
              <FormControl>
                <FormLabel>To time (UTC)</FormLabel>
                  <DatePicker
                    selected={convertUTCToLocalDate(endTimestamp)}
                    onChange={(date) => setEndTimestamp(convertLocalToUTCDate(date))}
                    showTimeSelect
                    dateFormat={DATE_FORMAT}
                    minDate={startTimestamp}
                  />
                </FormControl>
                <Button mr={2} w="200px" onClick={loadMoreRows}>Load</Button>
                {data.length ? <ButtonWithTooltip
                  size="sm"
                  ml={1}
                  tooltipValue="Change visibility for history fields"
                  onClick={onToggle}
                >
                  <Settings />
                </ButtonWithTooltip> : null}
              </Flex>
            </Flex>
            <ChangeVisibilityForColumns
              columns={columns}
              isOpen={isOpen}
              visibleColumns={visibleColumns}
              updateVisibleColumns={updateVisibleColumns}
              onClose={onClose}
              isMergeColumns={true}
              columnColors={columnColors}
              setColor={setColor}
              isUseCsv={true}
            />
          <Box className="history-block-table">
            {visibleColumns?.length > 0
              ? <Table
                  {...getTableProps()}
                >
                  <Thead>
                    {headerGroups.map(headerGroup => (
                      <Tr
                        {...headerGroup.getHeaderGroupProps()}
                        flexGrow={1}
                        flexBasis="auto"
                        display="flex"
                        className="row-block"
                      >
                        <Th minW={FIRST_COLUMN_WIDTH} />
                        {headerGroup.headers.map(column => {
                          const columnKey = column.id;
                          const isFilter = !!filters.find(el => el.id === columnKey);
                          return (
                            <Th
                              {...column.getHeaderProps()}
                            >
                              <>
                                <Flex className="column-header">
                                  {columnKey === TIMESTAMP_COLUMN_NAME ? LABEL_FOR_TIMESTAMP : column.render('Header')}
                                  <ChangeVisibilityIcon
                                    updateVisibleColumns={updateVisibleColumns}
                                    visibleColumns={visibleColumns}
                                    column={column}
                                    ml={1}
                                  />
                                  {keys(COLUMNS_WITH_FILTERS)?.includes(columnKey)
                                    ? <Box ml={1}>
                                        <ShowFilterButton
                                          isFilter={isFilter}
                                          fill={isFilter ? "green" : "inherit"}
                                          onFilter={() => handleFilterOpen(columnKey)}
                                          variant='link'
                                          heightIcon="18px"
                                        />
                                      </Box>
                                    : null
                                  }
                                </Flex>
                                <Collapse
                                  in={openedFilter.includes(columnKey)}
                                  animateOpacity
                                  className="filter-collapse-block"
                                >
                                  { column.canFilter ? column.render('Filter'): null}
                                </Collapse>
                              </>
                              <Box
                                className="resize-line"
                                {...column.getResizerProps()}
                              />
                          </Th>
                        )})}
                      </Tr>
                    ))}
                  </Thead>
                  <Tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                      const { original } = row;
                      const { id, uuid, bgColor, isFirstSubRow, isLastSubRow } = original;
                      const isNewElement = newElements.has(uuid);
                      const data = omit(original, [
                        'uuid',
                        'bgColor',
                        'isSubRow',
                        'parentId',
                        'isFirstSubRow',
                        'isLastSubRow'
                      ]);
                      if (hasDetails[original.service]) {
                        data.details = rowsDetails[id] || DEFAULT_DETAILS_TEXT
                      }

                      prepareRow(row);

                      return(
                        !row.original.subEntries ? <>
                          <Tr {...row.getRowProps()}
                            data-row-index={uuid}
                            ref = {node => rowRef(node, uuid)}
                          >
                            <Td
                              transition={ELEMENTS_TRANSITION}
                              backgroundColor={isNewElement ? NEW_ELEMENT_BACKGROUND : 'inherit'}
                              position="relative"
                              borderTopColor={isFirstSubRow ? bgColor : 'inherit'}
                              borderTopWidth={isFirstSubRow ? BORDER_FOR_GROUP_WIDTH : 0}
                              borderBottomColor={isLastSubRow && !row.isExpanded ? bgColor : 'inherit'}
                              borderBottomWidth={isLastSubRow && !row.isExpanded
                                ? BORDER_FOR_GROUP_WIDTH
                                : BORDER_WIDTH_DEFAULT
                              }
                            >
                              <span {...row.getToggleRowExpandedProps()}>
                                {row.isExpanded
                                  ? <NavArrowDown />
                                  : <NavArrowUp />
                                }
                              </span>
                              <Divider
                                orientation='vertical'
                                background={bgColor}
                                position="absolute"
                                top="-2px"
                                height="auto"
                                bottom={0}
                                left='-1px'
                                opacity={1}
                                width={GROUP_DIVIDER_WIDTH}
                              />
                            </Td>
                            {row.cells.map((cell, id) => {
                              return <Td
                                {...cell.getCellProps()}
                                className='resize-line-border'
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                                backgroundColor={isNewElement ? NEW_ELEMENT_BACKGROUND : 'inherit'}
                                transition={ELEMENTS_TRANSITION}
                                borderTopColor={isFirstSubRow ? bgColor : 'inherit'}
                                borderTopWidth={isFirstSubRow ? BORDER_FOR_GROUP_WIDTH : 0}
                                borderBottomColor={isLastSubRow && !row.isExpanded ? bgColor : 'inherit'}
                                borderBottomWidth={isLastSubRow && !row.isExpanded
                                  ? BORDER_FOR_GROUP_WIDTH
                                  : BORDER_WIDTH_DEFAULT
                                }
                              >
                                {cell.render('Cell')}
                              </Td>
                            })}
                          </Tr>
                          {row.isExpanded ? (
                            <Tr>
                              <Td
                                position="relative"
                              >
                                <Divider
                                  orientation='vertical'
                                  background={bgColor}
                                  position="absolute"
                                  top={0}
                                  height="auto"
                                  bottom={0}
                                  left='-1px'
                                  opacity={1}
                                  width={GROUP_DIVIDER_WIDTH}
                                />
                                <DetailsComponent data={data} />
                              </Td>
                            </Tr>
                          ) : null}
                        </> : null
                      )
                    })}
                  </Tbody>
                </Table>
              : null}
          </Box>
        </Box>
      </AccordionPanel>
    </Box>
  )
})

export default HistoryInfiniteLoader;
const {createTwoFilesPatch} = require('diff');

export const getUnifiedDiffFromJsDiff = (
  oldFileName: string,
  newFileName: string,
  past: string,
  current: string,
  oldHeader: string,
  newHeader: string,
  options: {
    context: string
  }
) => {
  const patch = createTwoFilesPatch(
    oldFileName,
    newFileName,
    past,
    current,
    oldHeader,
    newHeader,
    options
  );
  return patch;
};
import { getParameterName } from "./parameterStringHelper";
import { CompletionContext } from '@codemirror/autocomplete';
import { SyntaxNode } from "@lezer/common";

const ARRAY_NODE_NAME = 'Array';
const OBJECT_NODE_NAME = 'Object';
const START_ARRAY_NODE = '[';


const getArrayIndex = (node: SyntaxNode): string => {
  let count = 0;
  while (node && node.type.name !== START_ARRAY_NODE && node.prevSibling) {
    node = node.prevSibling;
    count++
  }
  return String(count)
}

export const getPathForNode = (
  node: SyntaxNode,
  context: CompletionContext,
  path: string[] = [],
  useArrayIndexes?: boolean
) => {
  const getPath = (node: SyntaxNode, context: CompletionContext) => {
    const parent = node.parent;
    if (parent) {
      if (parent.type.name === ARRAY_NODE_NAME && useArrayIndexes && node.prevSibling) {
        const arrayIndex = getArrayIndex(node.prevSibling);
        path.push(arrayIndex);
      }
      if (parent.type.name === OBJECT_NODE_NAME) {
        const parameterName = getParameterName(context.state.sliceDoc(node.from, node.to));
        path.push(parameterName);
      }
      getPath(parent, context);
    }
    return path
  }
  return getPath(node, context).reverse()
}


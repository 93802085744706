import { Parser } from '@json2csv/plainjs';

export const CSV_FILE_TYPE = 'text/csv;charset=utf-8;';

export const loadFromDataToCsvFile = (data: object, name: string, fields?: string[]) => {
  const parser = new Parser({
    fields
  })
  const csvParse = parser.parse(data);
  const csvFile = new Blob([csvParse], {type: CSV_FILE_TYPE});
  const csvURL = window.URL.createObjectURL(csvFile);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', name);
  tempLink.click();
}
export const HISTORY_BLOCK_HEIGHT = "500px";
export const HISTORY_TABLE_HEADER_HEIGHT = 55;
export const DEFAULT_DETAILS_TEXT = 'click to load';

export const HISTORY_LOAD_LIMIT = 50;
export const KEY_FOR_LOAD_DETAILS = 'details';
export const KEY_FOR_TREE_ROOT = 'root';

export const DEFAULT_COLUMN = {accessor: 'service', Header: 'service'};
export const LABEL_FOR_TIMESTAMP = 'Time (UTC)';

export const TIMESTAMP_COLUMN_NAME = 'timestamp';
export const ICON_COLUMN_NAME = 'icon_url';

export const COLUMNS_WITH_FILTERS = {
  tag: '',
  service: ''
}

export const ROW_DEFAULT_HEIGHT = 50;
export const CELL_DEFAULT_WIDTH = 150;

export const EMPTY_DETAILS = "Empty";
export const MIN_COLUMN_WIDTH = 150;
export const DEFAULT_COLUMN_WIDTH = 300;
export const MAX_COLUMN_WIDTH = 400;
export const FIRST_COLUMN_WIDTH = 70;

export const TIMEOUT_FOR_RESORTING_PRELOADER = 1000;
export const NEW_ELEMENT_BACKGROUND = '#2D3748';
export const ELEMENTS_TRANSITION = 'background-color 1s ease';
export const ELEMENTS_TRANSITION_DEFAULT= 'background-color 0';

export const COLUMN_WITH_SORT_HIDDEN_COLUMNS = 'service';

export const INIT_VISIBLE_COLUMNS = ['service', "timestamp", "action"];
export const DATE_FORMAT = 'dd/MM/yyyy HH:mm:ss';

export const GROUP_COLORS = ['#A0AEC0', '#4A5568'];
export const BORDER_FOR_GROUP_WIDTH = "3px";
export const BORDER_WIDTH_DEFAULT = "1px";
export const GROUP_DIVIDER_WIDTH = "10px";

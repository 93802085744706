import { createStandaloneToast, extendTheme } from '@chakra-ui/react';
import { ThemeProvider } from '@gjn/react-uikit';
import { configure } from "mobx";
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {  MultiSelectTheme } from 'chakra-multiselect'

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = extendTheme({
  fonts: {
    body: `Roboto, "Helvetica Neue", sans-serif`,
    heading: `Roboto, "Helvetica Neue", sans-serif`,
  },
  components: {
    MultiSelect: MultiSelectTheme
  }
})

const { ToastContainer } = createStandaloneToast();

configure({
  enforceActions: "always",
  computedRequiresReaction: true,
  reactionRequiresObservable: true,
  observableRequiresReaction: true,
  disableErrorBoundaries: true
})

root.render(
    <ThemeProvider theme={theme}>
      <App />
      <ToastContainer />
    </ThemeProvider>
);
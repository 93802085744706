import { action, makeObservable, observable } from "mobx";

class NotificationsStore {
  constructor() {
    this.showNotification = false;
    this.message = {};
    this.updateHandler = () => {}

    makeObservable(this, {
      showNotification: observable,
      message: observable,
      updateHandler: observable,
      setShowNotification: action.bound,
      setHideNotification: action.bound
    });
  }

  setShowNotification(message, update) {
   this.showNotification = true;
   this.message = message;
   this.updateHandler = update;
  }

  setHideNotification() {
    this.showNotification = false;
  }

}

export default new NotificationsStore();
import {action, makeObservable, observable} from "mobx";
import * as moment from "moment";
import { ERROR_STATUS } from "src/constants";
import Storages from "src/services/Storages";
import { v4 as uuidv4 } from 'uuid';
import { CellMeasurerCache } from 'react-virtualized';

const MAX_HISTORY_COUNT = 1000;
const DEFAULT_WIDTH = 0;
const DEFAULT_ELEMENT_HEIGHT = 35;

class ResponsesHistoryStore {
  constructor() {
    this.responsesHistory = Storages.get('history') || [];
    this.responsesHistory = [];
    this.isErrorInResponse = false;
    this.cosoleWidth = DEFAULT_WIDTH;
    this.autocompleteStringValue = {};
    this.responsesErrors = [];
    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: DEFAULT_ELEMENT_HEIGHT,
      minHeight: DEFAULT_ELEMENT_HEIGHT
    })

    makeObservable(this, {
      responsesHistory: observable,
      setResponsesHistory: action.bound,
      clearResponsesHistory: action.bound,
      isErrorInResponse: observable,
      setIsErrorInResponse: action.bound,
      getHistoryElement: action.bound,
      setConsoleWidth: action.bound,
      cosoleWidth: observable,
      removeIsErrorInResponse: action.bound,
      setFilteredHistory: action.bound,
      autocompleteStringValue: observable,
      setAutocompleteStringValue: action.bound,
      clearAutocompleteStringValue: action.bound,
      updateResponseHistory: action.bound,
      responsesErrors: observable,
      addResponsesErrors: action.bound,
      removeResponsesErrors: action.bound,
      copyToClipboardAction: action.bound,
      cache: observable
    });
  }

  copyToClipboardAction() {
    this.setResponsesHistory({
      action: "Copy to clipboard",
      status: 'SUCCESS'
    })
  }

  addResponsesErrors(value) {
    this.responsesErrors.push(value)
   }

   removeResponsesErrors(value) {
    const index = this.responsesErrors.indexOf(value);
    if (index >= 0) {
      this.responsesErrors.splice(index, 1);
    }
   }

  setAutocompleteStringValue(value) {
    const actionName = value.action;
    const findIndex = this.responsesHistory.findLastIndex(el => el.action === actionName);

    if (findIndex >= 0) {
      const autocompleteObject = this.autocompleteStringValue;
      if (autocompleteObject[actionName]) {
        autocompleteObject[actionName].push(value)
      } else {
        autocompleteObject[actionName] = [value]
      }
      this.responsesHistory[findIndex].autocompleteStringValue =
        JSON.stringify(autocompleteObject[actionName])
    }
    this.updateResponseHistory(this.responsesHistory);
  }

  clearAutocompleteStringValue() {
    this.autocompleteStringValue = {}
  }

  setIsErrorInResponse(value) {
   return this.isErrorInResponse = value
  }

  setResponsesHistory(data, level = 0, autocompleteString) {
    const historyData = Storages.get('history') || [];
    if (historyData.length >= MAX_HISTORY_COUNT) {
      this.removeResponsesErrors(historyData[0].responseId);
      this.cache.clearAll();
      historyData.shift();
    }

    const formatDate = moment(new Date()).format('YY-MM-DD hh:mm:ss');
    const responseId = uuidv4();

    if (data.status === ERROR_STATUS) {
      this.addResponsesErrors(responseId)
    }

    historyData.push({
      date: formatDate,
      level,
      type: data.status,
      action: data.action,
      source: data.source || '',
      responseId,
      info: data.response,
      errorInfo: data.errorMessage,
      autocompleteString,
      autocompleteStringValue: historyData.autocompleteString,
      isTableResponse: data.isTableResponse,
      subStatus: data.subStatus
    });

    this.updateResponseHistory(historyData);
  }

  updateResponseHistory(data) {
    Storages.put('history', data);
    this.setFilteredHistory(data)
  }

  getHistoryElement(index) {
    return this.responsesHistory[index];
  }

  setConsoleWidth(value) {
    this.cosoleWidth = value;
  }

  setFilteredHistory(data) {
    this.responsesHistory = data
  }

  removeIsErrorInResponse(responseId) {
    if (responseId === this.isErrorInResponse) {
      setTimeout(() => {
        this.setIsErrorInResponse(0)
      }, 100)
    }
  }

  clearResponsesHistory() {
    this.responsesHistory = [];
  }
}

export default new ResponsesHistoryStore();
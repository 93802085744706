import { useDisclosure } from '@chakra-ui/react';
import { observer } from "mobx-react";
import { useRef } from 'react';
import ButtonWithTooltip from '../ButtonWithTooltip';
import Dialog from '../Dialog';

const ActionConfirmation = observer((props) => {
  const {
    handleClick, dialogBody, dialogHeader,
    colorScheme, isDisabled, icon, value,
    marginRight, marginLeft, size, variant, minW, w,
    accessPermission,
    isDisabledOkButton, onOpenEvent, preventClose
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  const confirmation = () => {
    handleClick();
    !preventClose && onClose();
  };

  const open = () => {
    onOpenEvent && onOpenEvent();
    onOpen();
  }

  return (
    <>
      <Dialog
        showDialog = {isOpen}
        cancel = {onClose}
        confirm = {confirmation}
        title = {dialogHeader}
        subtitle = {dialogBody}
        ref = {cancelRef}
        isDisabledOkButton={isDisabledOkButton}
      />
      <ButtonWithTooltip
        size={size}
        marginRight={marginRight}
        marginLeft={marginLeft}
        colorScheme={colorScheme}
        isDisabled={isDisabled}
        variant={variant}
        onClick={open}
        minW={minW}
        w={w}
        accessPermission={accessPermission}
      >
        {icon} {value}
      </ButtonWithTooltip>
    </>
  )
})

export default ActionConfirmation
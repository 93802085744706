import {
  Box
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import "./style.scss";
import CodeMirror from '@uiw/react-codemirror';
import { materialDark } from 'cm6-theme-material-dark';
import { javascript } from "@codemirror/lang-javascript";
import { EditorView } from "@codemirror/view";
import { activeLineColor, cursorType } from "src/helpers/themeExtension";
import { ScriptsStore } from "src/stores";
import ButtonWithTooltip from "src/components/ButtonWithTooltip";
import { SaveFloppyDisk } from "iconoir-react";
import "./style.scss";
import { DEFAULT_BG_COLOR } from "src/constants";
import { isEqual } from "lodash";

const ScriptEditor = observer(({ value, currentValue, setCurrentValues, handleSaveChanges, filePath }) => {
  const { setIsScriptsChanged } = ScriptsStore;
  const [isDisabledSaveButton, setIsDisabledSaveButton] = useState(true);

  useEffect(() => {
    setCurrentValues((prev) => {
      return {...prev, [filePath]: value}
    })
  }, [value, filePath, setCurrentValues])

  useEffect(() => {
    if (value && currentValue) {
      const equal = isEqual(value.replace(/[\s\t]+/g, ''), currentValue.replace(/[\s\t]+/g, ''));
      setIsDisabledSaveButton(equal);
      setIsScriptsChanged(!equal)
    }
  }, [value, currentValue, setIsScriptsChanged])

  const codeMirrorNumbersPudding = EditorView.theme({
    ".cm-line": { paddingLeft: "10px" }
  });

  const handleChange = (value) => {
    setCurrentValues((prev) => ({...prev, [filePath]: value}));
  }

  return (
    <Box>
      <ButtonWithTooltip
        size='xs'
        position="absolute"
        top= "0.5rem"
        right= "1rem"
        colorScheme='green'
        marginLeft='20px'
        onClick={() => handleSaveChanges(filePath)}
        isDisabled={isDisabledSaveButton}
      >
        <SaveFloppyDisk color={DEFAULT_BG_COLOR}/>
      </ButtonWithTooltip>
      <CodeMirror
        value={currentValue}
        key={filePath}
        theme={materialDark}
        onChange={handleChange}
        extensions={[
          javascript(),
          codeMirrorNumbersPudding,
          cursorType,
          activeLineColor
        ]}
      />
  </Box>
  );
});

export default ScriptEditor;
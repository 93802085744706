import { SchemaElement } from "src/types/schema"

export const getEnumValuesAsStrList = (enumObject: SchemaElement): string[] => {
  if (enumObject?.enum) {
    return enumObject.enum
  }
  if (enumObject?.allOf?.length && enumObject.allOf[0].enum) {
    return enumObject?.allOf[0].enum
  }
  return []
}
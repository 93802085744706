import { flow, makeObservable, observable } from "mobx";
import { PermissionsStore } from ".";
import gamesApi from "../api/games";
import Storages from "../services/Storages";
import { ConfigsStore } from ".";
import { isArray } from "lodash";

class GamesStore {
  constructor() {
    this.games = [];
    this.environment = Storages.get('environment') || null;
    this.projectid = Storages.get('projectid') || null;
    this.game_name = null;
    this.games_loaded = false;
    this.getGamesError = '';

    makeObservable(this, {
      games: observable,
      projectid: observable,
      environment: observable,
      games_loaded: observable,
      getGames: flow.bound,
      setGame: flow.bound,
      setGames: false,
      getGamesError: observable
    });
  }

  *getGames() {
    try {
      this.getGamesError = '';
      this.games_loaded = false;

      const { data } = yield gamesApi.getGames();

      if (isArray(data)) {
        this.setGames(data);
      } else {
        this.games_loaded = true;
      }
    } catch (err) {
      this.getGamesError = err?.data  || err?.message || 'Get games error';
    }
  }

  setGames(games) {
    if (games) {
      this.games = games;

      const projectid = this.projectid;
      const environment = this.environment;

      const savedGame = games.filter(data =>
        data.projectid === projectid && data.environment === environment);

      const [gameElement] = (projectid && savedGame.length ? savedGame : games);

      if (gameElement) {
        this.setGame(gameElement.projectid, gameElement.environment);
      } else {
        this.games_loaded = true;
      }
    }
  }

  *setGame(projectid, environment) {
    if (this.games) {
      const game = this.games.filter(data => data.projectid === projectid && data.environment === environment).shift();

      if (game) {
        const projectid = game.projectid;
        const gameName = game.name;
        const environment = game.environment;

        if (!projectid  || !environment) {
          return false
        }
        this.projectid = projectid;
        this.game_name = gameName;
        this.environment = environment;

        Storages.put('projectid', projectid);
        Storages.put('environment', environment);

        ConfigsStore.clearConfigs();

        yield PermissionsStore.getPermissions(projectid, environment);
      }

      this.games_loaded = true;
    }
  }
}

export default new GamesStore();
import { Box, Divider, Flex, Heading, Input } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import SingleConfigContent from "./SingleConfigContent";
import { SingleConfigStore, GamesStore, PermissionsStore, ConfigsViewStore } from "../../../stores";
import Permissions from "../../Permissions";
import ButtonWithTooltip from "src/components/ButtonWithTooltip";
import { uploadFile } from "src/helpers/uploadConfig";
import { LANGUAGE, getFileByLang } from "src/helpers/configLanguageHelper";
import UploadConfigModal from "../Configs/UploadConfigModal";
import { getConfigFile } from "src/helpers/downloadConfig";
import { keys } from "lodash";
import { SaveFloppyDisk } from "iconoir-react";

const SingleConfig = observer(({ elementName, headerName, isUpload = false, defaultLang = LANGUAGE.JSON}) => {
  const {
    loadSingleConfigSchema,
    configData,
    loadSingleConfig,
    schema,
    clearSingleConfig,
    language,
    changeSingleConfig,
    isSingleConfigChanged
  } = SingleConfigStore;
  const { projectid, environment, games_loaded } = GamesStore;
  const { permissions } = PermissionsStore;
  const { setConfigLang } = ConfigsViewStore;
  const permission = `can_view_${elementName}`;

  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
  const [uploadData, setUploadData] = useState([]);

  const [errors, setErrors] = useState({});
  const [editorText, setEditorText] = useState("");

  useEffect(() => {
    games_loaded && loadSingleConfigSchema(elementName).then(() => {
      if (permissions?.includes(permission)) {
        loadSingleConfig(elementName);
        setConfigLang(defaultLang);
      }
    });
    return () => clearSingleConfig()
  }, [
    loadSingleConfigSchema,
    projectid,
    environment,
    elementName,
    loadSingleConfig,
    permissions,
    permission,
    clearSingleConfig,
    games_loaded,
    setConfigLang,
    defaultLang
  ]);

  const uploadFiles = useRef();

  const getFile = (event) => {
    uploadFile(event, language).then((data) => {
      uploadFiles.current.value = '';
      if (data) {
        setIsOpenUploadModal(true)
        setUploadData(data);
      }
    })
  };

  const getConfig = async () => {
    const config = await loadSingleConfig(elementName);
    getConfigFile(
      config,
      language
    );
  };

  const saveChanges = () => {
    changeSingleConfig(JSON.parse(editorText), elementName);
  };

  return (
    <Permissions permission={permission} isLoaded={configData !== null && schema !== null}>
      <Box mt={2}>
        <Flex w={"100%"} flexWrap="wrap" position="relative">
          <Heading as="h3" size="lg" mr={5}>
            {headerName}
          </Heading>
          {isUpload
            ? <Flex alignItems="center" >
                <>
                  <ButtonWithTooltip
                    variant="outline"
                    style={{ marginLeft: "auto" }}
                    accessPermission={`can_change_${elementName}`}
                  >
                    <Input
                      type="file"
                      height="100%"
                      width="100%"
                      position="absolute"
                      top="0"
                      left="0"
                      opacity="0"
                      aria-hidden="true"
                      accept={getFileByLang(language).accept}
                      onChange={getFile}
                      ref={uploadFiles}
                    />
                    Upload config
                  </ButtonWithTooltip>
                  <ButtonWithTooltip
                    variant="outline"
                    marginRight='0'
                    marginLeft="5px"
                    onClick={getConfig}
                  >
                    Download config
                  </ButtonWithTooltip>
                </>
                <Box position="absolute" right={0}>
                  <ButtonWithTooltip
                    size='xs'
                    colorScheme='green'
                    marginRight='20px'
                    isDisabled={!isSingleConfigChanged || keys(errors)?.length > 0}
                    onClick={saveChanges}
                    accessPermission={`can_change_${elementName}`}
                  >
                    <SaveFloppyDisk />
                  </ButtonWithTooltip>
                </Box>
              </Flex>
            : null
          }
        </Flex>
        <Divider orientation="horizontal" />
        <Box mt={3}>
          <SingleConfigContent
            configData={configData}
            schema={schema}
            elementName={elementName}
            errors={errors}
            setErrors={setErrors}
            editorText={editorText}
            setEditorText={setEditorText}
          />
        </Box>
      </Box>
      <UploadConfigModal
        isOpen={isOpenUploadModal}
        uploadData={uploadData}
        setIsOpen={setIsOpenUploadModal}
        elementName={elementName}
        loadAction={loadSingleConfig}
        uploadAction={(uploadData) => changeSingleConfig(uploadData, elementName)}
      />
    </Permissions>
  );
});

export default SingleConfig;
import { action, flow, makeObservable, observable } from "mobx";
import { GamesStore } from ".";
import configsApi from "../api/configs";

class ScriptsStore {
  constructor() {
    this.scriptsArchiveInfo = {};
    this.isScriptsChanged = false;

    makeObservable(this, {
      loadScriptsArchiveInfo: flow.bound,
      scriptsArchiveInfo: observable,
      uploadScriptsConfig: flow.bound,
      loadScriptsConfig: flow.bound,
      checkScripts: flow.bound,
      setIsScriptsChanged: action.bound,
      isScriptsChanged: observable,
      clearScripts: action.bound
    });
  }

  *loadScriptsArchiveInfo( elementName ) {
    const { projectid, environment } = GamesStore;
    try {
      const { data } = yield configsApi.getScriptsArchiveInfo({ projectid, environment, elementName });
      this.scriptsArchiveInfo = {...data}
      return data
    } catch (error) {
      console.log(error);
      this.scriptsArchiveInfo = {}
    }
  }

  *loadScriptsConfig( elementName ) {
    const { projectid, environment } = GamesStore;

    try {
      const {data} = yield configsApi.getScriptsConfig({ projectid, environment, elementName });
      this.scriptsArchiveInfo = {...data}
      return data;
    } catch (error) {
      console.log(error);
      this.scriptsArchiveInfo = {}
    }
  }

  *uploadScriptsConfig( archive, elementName ) {
    const { projectid, environment } = GamesStore;

    try {
      yield configsApi.setScriptsConfig({ projectid, environment, archive: archive, elementName });
    } catch (error) {
      console.log(error);
    }
  }

  setIsScriptsChanged(value) {
    this.isScriptsChanged = value;
  }

  *checkScripts() {
    const { projectid, environment } = GamesStore;

    try {
      yield configsApi.checkScriptsCompilation({ projectid, environment });
    } catch (error) {
      console.log(error);
    }
  }

  clearScripts() {
    this.scriptsArchiveInfo = {};
    this.isScriptsChanged = false;
  }
}

export default new ScriptsStore();
import { Center, Spinner, VStack } from '@chakra-ui/react';
import { GaijinLogo } from '@gjn/react-uikit';
import { observer } from 'mobx-react';

const Loading = observer(({height = "100vh"}) => {
  return (
    <Center h={height}>
      <VStack gap="6">
        <GaijinLogo w="24" h="24" />
        <Spinner />
      </VStack>
    </Center>
  )
})

export default Loading
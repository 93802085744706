import { get, isArray, keys, set } from "lodash";
import { action, flow, makeObservable, observable } from "mobx";
import { isJSONLang, LANGUAGE } from "src/helpers/configLanguageHelper";
import { ConfigsStore, GamesStore } from ".";
import configsApi from "../api/configs";
import Storages from "src/services/Storages";

class ConfigsViewStore {
  constructor() {

    this.checkedConfigs = new Set();
    this.configCheckedAll = false;
    this.elementKey = 0;
    this.expendedRows = {};
    this.fieldList = {};
    this.isUseNamespace = true;
    this.language = LANGUAGE.JSON;
    this.isJSONLang = true;
    this.csvEditable = [];
    this.abortController = null;

    makeObservable(this, {
      checkedConfigs: observable,
      setConfigChecked: action.bound,
      clearConfigsChecked: action.bound,
      setConfigCheckedAll: action.bound,
      configCheckedAll: observable,
      setKeys: action.bound,
      generateKey: action.bound,
      elementKey: observable,
      expendedRows: observable,
      setExpendedRows: action.bound,
      addExpandedElement: action.bound,
      removeExpandedElement: action.bound,
      loadFieledValueList: flow.bound,
      fieldList: observable,
      isUseNamespace: observable,
      setIsUseNamespace: action.bound,
      language: observable,
      setConfigLang: action.bound,
      isJSONLang: observable,
      clearConfigsConfiguration: action.bound,
      csvEditable: observable,
      setCsvEditable: action.bound,
      abortController: observable,
      setAbortController: action.bound,
      configsKeyStorage: action.bound,
      putToStorage: action.bound,
      getFromStorage: action.bound
    });
  }

  setAbortController(controller) {
    this.abortController = controller;
  }

  setCsvEditable(value) {
    if (isArray(value)) {
      this.csvEditable = value;
    }
  }

  setConfigChecked(value, isCheck) {
    if (isCheck) {
      this.checkedConfigs.add(value)
    } else {
      this.checkedConfigs.delete(value)
    }
  }

  clearConfigsChecked() {
    this.checkedConfigs.clear();
  }

  setConfigCheckedAll(isCheck) {
    const { configs } = ConfigsStore;
    configs.data.forEach(config => {
      const configKey = config.internal.key;
      if (isCheck) {
        this.checkedConfigs.add(configKey)
      } else {
        this.checkedConfigs.delete(configKey)
      }
      this.configCheckedAll = isCheck;
    })
  }

  setKeys({data}) {
    data.forEach((el) =>{
      el.internal.key = this.generateKey();
    });
    return {data}
  }

  generateKey() {
    return ++this.elementKey
  }

  setExpendedRows(rows) {
    this.expendedRows = rows
  }

  addExpandedElement() {
    const newExpendedRows = {0: true};
    keys(this.expendedRows).forEach(el => newExpendedRows[++el] = true);
    this.setExpendedRows(newExpendedRows);
  }

  removeExpandedElement(rowIndex) {
    const newExpendedRows = {};
    keys(this.expendedRows).forEach(el => {
      if (el !== rowIndex) {
        newExpendedRows[--el] = true
      }
    });
    this.setExpendedRows(newExpendedRows);
  }


  *loadFieledValueList( elementName, path, specSymbol = '') {
    const { projectid, environment } = GamesStore;
    try{
      const {data} = yield configsApi.getIdentList({ projectid, environment, elementName});
      this.fieldList = {...this.fieldList, [path]: {list: data, specSymbol}};
    } catch(error) {
      console.log(error)
    }
  }

  setIsUseNamespace(value) {
    this.isUseNamespace = value;
  }

  setConfigLang(value) {
    this.language = value;
    this.isJSONLang = value ? isJSONLang(value) : true;
  }

  configsKeyStorage() {
    const { projectid, environment } = GamesStore;
    return `configs_${projectid}_${environment}`;
  }

  putToStorage(path, value) {
    const CONFIG_KEY_STORAGE = this.configsKeyStorage();
    const storageObject = Storages.get(CONFIG_KEY_STORAGE) || {};
    const updateStorageObject = set(storageObject, path, value);
    Storages.put(CONFIG_KEY_STORAGE, updateStorageObject)
  }

  getFromStorage(path) {
    const CONFIG_KEY_STORAGE = this.configsKeyStorage();
    const storageObject = Storages.get(CONFIG_KEY_STORAGE) || {};
    return get(storageObject, path)
  }

  clearConfigsConfiguration() {
    this.elementKey = 0;
    this.fieldList = {};
  }
}

export default new ConfigsViewStore();
import { gutter, GutterMarker } from "@codemirror/view";
import { getParameterName } from "src/helpers/parameterStringHelper";
import './style.scss';
import getSchemaFromPath from "json-schema-from-path";
import { SchemaStore } from "src/stores";
import { keys } from "lodash";
import { NAME_FOR_CSV_FILE } from "./constants";
import { loadFromDataToCsvFile } from "src/helpers/loadFromDataToCsvFile";

const getFields = (fields) => {
  return fields.map(field => {
    return {
      label: field,
      value: (value) => {
        return value[field];
      }
    }
  })
}

export const getCsv = (data, parameterName) => {
  const { schemaWithRef } = SchemaStore;
  const path = [parameterName, 'items'].join('/');
  const { properties } = getSchemaFromPath(schemaWithRef, path);
  const schemaItems = keys(properties);
  loadFromDataToCsvFile(data, NAME_FOR_CSV_FILE, getFields(schemaItems))
}

const downloadCsvButton = class extends GutterMarker {
  constructor (CSVdata, parameterName) {
    super()
    this.headers = CSVdata.headers || [];
    this.data = CSVdata.data || [];
    this.parameterName = parameterName
  }
  toDOM() {
    if (!this.data?.length) {
      const emptySpan = document.createElement("span")
      return emptySpan
    }
    const button = document.createElement("button");
    button.classList.add("cvs-downlod-link", "cvs-button", "tooltip");
    button.innerHTML = "CSV🡇";

    const tooltiptext = document.createElement("span");
    tooltiptext.innerHTML = 'Download';
    tooltiptext.classList.add('tooltiptext')
    button.appendChild(tooltiptext);
    button.onclick = () => getCsv(this.data, this.parameterName)

    return button
  }
}

export const addLoadCsvButton = (csvEditable, data) => {
  return gutter({
    lineMarker(view, line) {
      const parameterName = getParameterName(view.state.doc.lineAt(line.from).text);
      if (csvEditable.includes(parameterName) && data) {
        return new downloadCsvButton(data, parameterName)
      }
    }
  })
}

import { EditorView } from '@codemirror/view';
import { replace } from 'lodash';

export const formatWithCommaApply = (value: string, view: EditorView, specSymbol?: string): string => {
  let applyDefaultWithFormat = value;
  const { lines } = view.state.doc;
  const currentLine = view.state.doc.lineAt(view.state.selection.main.head);
  const currentLineNumber = currentLine.number;
  const currentLineText = currentLine.text;
  if (specSymbol && currentLineText.includes(specSymbol)) {
    applyDefaultWithFormat = specSymbol + replace(applyDefaultWithFormat, /"/g, '');
  }
  if (lines - 1 > currentLineNumber && !currentLineText.includes(',')) {
    applyDefaultWithFormat = applyDefaultWithFormat + ',';
  }
  return applyDefaultWithFormat
}

import { flow, makeObservable, observable, action } from "mobx";
import { GamesStore } from ".";
import matchingInfo from "../api/matchingInfo";

class MatchingInfoStore {
  constructor() {
    this.matchingInfo = null;
    this.error = '';

    makeObservable(this, {
      matchingInfo: observable,
      getMatchingInfo: flow.bound,
      clearMatchingInfo: action.bound,
      setMaintenanceOnMatching: flow.bound,
      error: observable
    });
  }

  *getMatchingInfo() {
    const { projectid, environment } = GamesStore;
    try {
      const { data } = yield matchingInfo.getMatchingStatus({ projectid, environment });
      this.matchingInfo = data || {};
    } catch (e) {
      this.matchingInfo = null;
      this.error = e?.data || ''
    }
  }


  *setMaintenanceOnMatching(value) {
    const { projectid, environment } = GamesStore;
    try {
      yield matchingInfo.setMaintenanceOnMatching({ projectid, environment, value });
    } catch (e) {

    }
  }

  clearMatchingInfo() {
    this.matchingInfo = null;
    this.error = '';
  }
}

export default new MatchingInfoStore();
import { observer } from "mobx-react";
import { Box, Flex, Tooltip } from "@chakra-ui/react";
import { themeMonokai } from "./themeMonokai";
import { isEqual, reverse } from "lodash";
import { JSONTree } from 'react-json-tree';
import CopyIcon from "./Icons/CopyIcon";
import EyeOffIcon from "./Icons/EyeOffIcon";
import EyeEmptyIcon from "./Icons/EyeEmptyIcon";
import { searchByTree } from "src/helpers/searchByTree";
import { UserManagementStore } from "src/stores";
import { KEY_FOR_TREE_ROOT, TEXT_FOR_COPY_CONTENT, TEXT_FOR_COPY_OBJECT_NAME } from "./constants";
import { ClipboardCheck } from "iconoir-react";
import * as copy from "copy-to-clipboard";

const JSONTreeComponent = observer(({
  value,
  watchedVariables,
  setWatchedVariables,
  treeKey,
  searchValue
}) => {

  const { putToStorage, getFromStorage } = UserManagementStore;

  const findWatchedPath = (path) => (
    watchedVariables.find(watchedElement => isEqual(path, watchedElement))
  )

  const handleSearchTree = (obj, value) => {
    if (!value) {
      return obj
    }
    return searchByTree(obj, value)
  }

  return (
    <JSONTree
      data={handleSearchTree(value, searchValue)}
      theme={themeMonokai}
      invertTheme={false}
      labelRenderer={(keyPath, type, expanded) => {
        const expandedArray = getFromStorage('expandedNodes') || [];
        const findCurrentPath = expandedArray?.findIndex(savedKeyPath =>
          isEqual(keyPath, savedKeyPath));
        if (expanded && findCurrentPath === -1) {
          expandedArray.push(keyPath)
        } else {
          if (findCurrentPath !== -1 && !expanded) {
            expandedArray.splice(findCurrentPath, 1)
          }
        }
        putToStorage('expandedNodes', expandedArray);
        const keyName = keyPath[0];
        return (
          <Flex justifyContent="center" alignItems="center">
            {keyName}
            {type === "Object" && keyName !== KEY_FOR_TREE_ROOT
              ? <Tooltip label={TEXT_FOR_COPY_OBJECT_NAME}>
                  <Box ml={1}>
                    <ClipboardCheck
                      width="18px"
                      height="18px"
                      cursor="pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        copy(keyName)
                      }}
                    />
                  </Box>
                </Tooltip>
              : null
            }
          </Flex>
        )
      }}
      shouldExpandNodeInitially={(keyPath) => {
        if (keyPath[0] === KEY_FOR_TREE_ROOT) {
          return keyPath
        }
        return getFromStorage('expandedNodes')?.find(savedKeyPath =>
          isEqual(keyPath, savedKeyPath))
      }}
      valueRenderer={(raw, _, ...keyPath) => {
        const pathToKey = [treeKey, ...reverse(keyPath)];
        const findPath = findWatchedPath(pathToKey);
        return <em className="json-tree__value-render">
          <Flex>{raw}
            <CopyIcon data={raw} />
            {findPath
              ? <EyeOffIcon
                  watchedVariables={watchedVariables}
                  setWatchedVariables={setWatchedVariables}
                  watchedElement={pathToKey}
                />
              : <EyeEmptyIcon
                  watchedVariables={watchedVariables}
                  setWatchedVariables={setWatchedVariables}
                  watchedElement={pathToKey}
                />
            }
          </Flex>
        </em>
      }}
      getItemString={(_, data, itemType, itemString) => {
        return <span>
          {itemType} {itemString}
          <Tooltip label={TEXT_FOR_COPY_CONTENT}>
            <span>
              <CopyIcon data={data} />
            </span>
          </Tooltip>
        </span>
      }}
    />
  )
})

export default JSONTreeComponent
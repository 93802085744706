import {
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';
import { observer } from "mobx-react";

const ImageModal = observer(({
  openImage,
  setOpenImage,
  name
}) => {

  const handleClose = () => {
    setOpenImage('')
  }

  return (
    <Modal isOpen={!!openImage} onClose={handleClose}>
      <ModalOverlay />
      {openImage
        ? <ModalContent>
            <ModalHeader>{name}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex justifyContent="center" alignItems="center">
                <Image
                  objectFit='cover'
                  src={openImage}
                  alt={name}
                />
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={handleClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        : null
      }
    </Modal>
  )
})

export default ImageModal;
import { handlerSendRequest } from './handlerSendRequest';

class SidebarMenuApi {
  getEnabledConfigs = ({ projectid, environment }) =>
    handlerSendRequest(`get_enabled_configs`, { projectid, environment });

  getServices = ({ projectid, environment }) =>
    handlerSendRequest(`get_services`, { projectid, environment });
}

export default new SidebarMenuApi();
import { action, flow, makeObservable, observable } from "mobx";
import permissionsApi from "../api/permissions";
import { isArray } from "lodash";

class PermissionsStore {
  constructor() {
    this.permissions = [];

    makeObservable(this, {
      permissions: observable,
      getPermissions: flow.bound,
      checkPermission: action.bound
    });
  }

  *getPermissions(projectid, environment) {
    try {
      const { data } = yield permissionsApi.getPermissions({ projectid, environment });
      this.permissions = isArray(data) ? data : [];
    } catch (error) {
      this.permissions = [];
    }
  }

  checkPermission(permission) {
    return this.permissions.includes(permission);
  }
}

export default new PermissionsStore();
import { SchemaElement } from "src/types/schema";

export const getParamaterTypes = (parameterFromSchema: SchemaElement): string[] => {
  const types = [];

  parameterFromSchema?.type && types.push(parameterFromSchema.type);

  parameterFromSchema?.anyOf?.forEach((schema: SchemaElement) => {
    schema.type && types.push(schema.type)
  });

  return types
};

import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl
} from "@chakra-ui/react"
import {observer} from "mobx-react";

const ConfirmOverwriteModal = observer(({ isOpenConfirm, setIsOpenConfirm, tagName, handleClickOk }) => {

  return (
    <Modal
      size='xl'
      blockScrollOnMount={true}
      isOpen={isOpenConfirm}
      onClose={() => setIsOpenConfirm(false)}
    >
      <ModalContent>
        <ModalHeader>Import and overwrite tag</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl>
            {`Are you sure to overwrite the tag "${tagName}"? The old tag data will be lost.`}
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme='blue' mr={3} onClick={handleClickOk}>
            Ok
          </Button>
          <Button onClick={() => setIsOpenConfirm(false)}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
})

export default ConfirmOverwriteModal
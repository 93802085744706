import { useCallback, useEffect } from 'react';
import { unstable_useBlocker as useBlocker, useBeforeUnload } from 'react-router-dom';

export function useCallbackStopNavigate(isBlock, clear) {

  function usePrompt(isBlock) {
    const blocker = useBlocker(isBlock)
    useEffect(() => {
      // If "isBlock" parameter is false and navigation had already blocked - we must reset blocker
      if (blocker.state === "blocked" && !isBlock) {
        blocker.reset()
      }
    }, [blocker, isBlock])

    useBeforeUnload(
      useCallback(
        (event) => {
          if (isBlock) {
            event.preventDefault()
            event.returnValue = "Changes that you made may not be saved."
          }
        },
        [isBlock],
      ),
      { capture: true },
    )

    return blocker
  }

  const blocker = usePrompt(isBlock)
  // If choose No in dialog window
  const cancelNavigation = useCallback(() => {
    if (blocker.state === "blocked") {
      blocker.reset();
    }
  }, [blocker]);

  // If choose Yes in dialog window
  const confirmNavigation = useCallback(() => {

    if (blocker.state === "blocked") {
      blocker.proceed()
    }

    if (clear) {
      clear();
    }
  }, [clear, blocker])


  return [blocker.state === "blocked", confirmNavigation, cancelNavigation]
}
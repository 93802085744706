import { Box, Button, Flex, HStack, Spacer } from '@chakra-ui/react';
import { useColor } from '@gjn/react-uikit';
import { observer } from "mobx-react";
import Storages from 'src/services/Storages';
import { AuthStore } from '../../stores';
import MenuPanel from '../MenuPanel';

const Header = observer(() => {
  const { panelBg } = useColor();

  const toRelogin = () => {
    const { pathname } = window.location;
    Storages.put('current_pathname', pathname);
    Storages.clear();
    AuthStore.logout();
  }

  return (
    <Box>
      <Flex h="14" pl="2" pr="2" bg={panelBg}>
        <HStack w="100%">
          <Spacer />
          <MenuPanel />
          <Button
            size="sm"
            variant="outline"
            textTransform="uppercase"
            onClick={toRelogin}
          >Relogin</Button>
        </HStack>
      </Flex>
    </Box>
  )
})

export default Header
import { action, makeObservable, observable } from "mobx";

class FilterStore {
  constructor() {
    this.isShowFilters = false;
    this.queryValue = "";

    makeObservable(this, {
      isShowFilters: observable,
      queryValue: observable,
      setIsShowFilters: action.bound,
      setQueryValue: action.bound,
      clearFilter: action.bound
    });
  }

  setIsShowFilters(value) {
    this.isShowFilters = value;
  }

  setQueryValue(value) {
    this.queryValue = value;
  }

  clearFilter() {
    this.queryValue = "";
    this.isShowFilters = false;
  }
}

export default new FilterStore();
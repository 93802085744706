import { observer } from "mobx-react";
import { useState } from "react";
import {
  Button,
  Text,
  Select,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Box
} from "@chakra-ui/react";
import './style.scss';
import { Settings, ArrowRight } from "iconoir-react";
import ButtonWithTooltip from "src/components/ButtonWithTooltip";
import ColorPicker from "src/components/ColorPicker";
import { DEFAULT_COLOR, POPOVER_BODY_MAX_HEIGHT } from "../constants";
import { DEFAULT_BG_COLOR } from "src/constants";

const ColumnSettings = observer(({
 index,
 item,
 visibleColumns,
 updateVisibleColumns,
 setColor,
 columnColors
}) => {
  const [mergedItems, setMergedItems] = useState([item]);
  const [mergedValue, setMergedValue] = useState('')

  const splitItem = (index) => {
    const updatedItems = [...visibleColumns];
    const item = updatedItems[index];

    const splitContent = item.split('/');
    const newItems = splitContent.map(item => item);

    updatedItems.splice(index, 1, ...newItems);
    updateVisibleColumns([...new Set(updatedItems)]);
  }


  const mergeColumns = (e, currentItem, currentIndex) => {
    const { value } = e.target;
    const mergedItem = value ? `${currentItem}/${value}` : currentItem;
    const count = (mergedItem.match(/\//g) || []).length;
    if (count < 10) {
      const updatedItems = [...visibleColumns];
      updatedItems[currentIndex] = mergedItem;
      setMergedValue(mergedItem);
      setMergedItems(updatedItems.filter((item, index) => index === currentIndex || !value.startsWith(item)));
    }
  }

  return (
    <Popover variant="picker" placement="top">
      <PopoverTrigger>
        <ButtonWithTooltip
          m={2}
          size="s"
          variant='outline'
          tooltipValue="Settings for column"
          onClick={() =>{
            setMergedItems([item]);
            setMergedValue('')
          }}
        >
          <Settings />
        </ButtonWithTooltip>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton backgroundColor={DEFAULT_BG_COLOR} />
        <PopoverHeader
          borderTopLeftRadius={5}
          borderTopRightRadius={5}
        >
          <Flex alignContent="center" flexWrap="wrap">
            <Box wordBreak="break-word" overflowWrap="break-word">{item}</Box>
            {mergedValue ? <ArrowRight /> : null}
            <Box wordBreak="break-word" overflowWrap="break-word">{mergedValue}</Box>
          </Flex>
        </PopoverHeader>
        <PopoverBody maxHeight={POPOVER_BODY_MAX_HEIGHT} overflow="auto" className="popover-body-block">
          {item.includes('/')
            ? <Flex alignItems="center" mb={2}>
                <Text fontSize='sm' mb={1} mr={2}>Split columns</Text>
                <Button onClick={() => splitItem(index)}>Split</Button>
              </Flex>
            : null
          }
          <Text fontSize='sm' mb={1}>Merge with columns</Text>
          <Select onChange={(e) => mergeColumns(e, item, index)} mb={2} defaultValue=''>
            <option value=""></option>
            {visibleColumns.map(column => (column !== item && <option value={column}>{column}</option>))}
          </Select>
          <Button onClick={() => {mergedValue && updateVisibleColumns(mergedItems)}}>Merge</Button>

          {setColor && item.includes('/') ? item.split('/').map(mergedItem => (
            <ColorPicker
              setColor={(color) => setColor(color, mergedItem)}
              color={columnColors[mergedItem] || DEFAULT_COLOR}
              coloredElement={mergedItem}
            />
          )) : null}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
})

export default ColumnSettings
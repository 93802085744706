import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { TokenManagementStore, AuthStore } from "../../../stores";
import "./style.scss";
import { materialDark } from 'cm6-theme-material-dark';
import CodeMirror from '@uiw/react-codemirror';
import { json } from "@codemirror/lang-json";
import { getJSONstringify } from "src/helpers/JSONSstringifyHelper";
import TextElementWithCopyButton from "src/components/TextElementWithCopyButton";
import { BLOCKS_HEIGHT, CODE_EDITOR_LINE_HEIGHT, PADDING_RIGHT } from "./constants";

const TokenManagement = observer(() => {
  const {
    getToken,
    exchangeToken,
    clearTokenManagementData,
    getTokenManagementData,
    exchangeTokenEnabled,
    getTokenPayload
  } = TokenManagementStore;
  const { user } = AuthStore;
  const [initialValue, setInitialValue] = useState('');
  const [token, setToken] = useState("");
  const [tokenPayload, setTokenPayload] = useState("");

  const [currentTokenValue, setCurrentTokenValue] = useState('');
  const [isOpenExchangeTokenModal, setIsOpenExchangeTokenModal] = useState(false);


  useEffect(() => {
    const lineHeight = CODE_EDITOR_LINE_HEIGHT;
    const targetHeight = BLOCKS_HEIGHT;
    const numLines = Math.floor(targetHeight / lineHeight);

    const lines = Array(numLines).fill('').join('\n');
    setInitialValue(lines)
  }, [])

  useEffect(() => {
    return () => clearTokenManagementData()
  }, [clearTokenManagementData]);

  useEffect(() => {
    getTokenManagementData();
  }, [getTokenManagementData]);

  const handleClose = () => {
    setIsOpenExchangeTokenModal(false);
  }

  const handleGetNewPayloadToken = (token) => {
    getTokenPayload({token})
      .then((payload) => {
        setTokenPayload(payload)
      })
  }

  const insertCurrentToken = () => {
    const currentToken = user.token;
    setToken(currentToken);
  }

  const CopyButton = observer(({copyValue}) => (
    <Box
      position="absolute"
      top="10px"
      right="10px"
      zIndex={100}
    >
      <TextElementWithCopyButton
        copyValue={copyValue}
        label="Copy to buffer"
      />
    </Box>
  ));

  return (
    <Box>
      <Heading as="h3" size="lg">
        Token Management
      </Heading>
      <Divider orientation="horizontal" />
      {exchangeTokenEnabled
        ? <Button mt={5} onClick={() => setIsOpenExchangeTokenModal(true)}>Exchange token</Button>
        : null
      }
      <Box>
        <Heading as="h3" size="md" mt={5}>
          Token Payload
        </Heading>
        <Flex width="100%" alignItems="center" mt={5}>
          <Box w="50%" mr={2} position="relative">
            <Textarea
              value={token}
              size="xs"
              onChange={(e) => setToken(e.target.value)}
              height={BLOCKS_HEIGHT}
              resize="none"
              className="text-area"
              paddingRight={PADDING_RIGHT}
            />
            {token
              ? <CopyButton copyValue={token.toString()} />
              : null
            }
          </Box>
          <Box w="50%" position="relative">
            <CodeMirror
              readOnly={true}
              value={tokenPayload ? getJSONstringify(tokenPayload) : initialValue}
              theme={materialDark}
              height={`${BLOCKS_HEIGHT}px`}
              extensions={[
                json()
              ]}
            />
              {tokenPayload
                ? <CopyButton copyValue={getJSONstringify(tokenPayload)} />
                : null
              }
          </Box>
        </Flex>
        <Flex w="100%" justifyContent="space-between">
          <Button onClick={() => insertCurrentToken(token)} mt={3}>
            Insert my token
          </Button>
          <Button onClick={() => handleGetNewPayloadToken(token)} mt={3}>
            Get payload token
          </Button>
        </Flex>
      </Box>
      <Modal
        isOpen={isOpenExchangeTokenModal}
        onClose={handleClose}
        size="large"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Exchange token</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Flex mt={3} alignItems="end" justifyContent="space-between">
              <FormControl  >
                <Textarea
                  minHeight="150px"
                  value={currentTokenValue}
                  size="xs"
                  height="auto"
                  onChange={(e) => setCurrentTokenValue(e.target.value)}
                  className="text-area"
                  resize="none"
                />
              </FormControl>
            </Flex>
            <Box position="relative" mt={5}>
              <Flex justifyContent="space-between" alignItems="center" mb={2}>
                <Text fontSize='xs'>Exchange:</Text>
              </Flex>
              <Box position="relative">
                <Textarea
                  value={exchangeToken}
                  size="xs"
                  isReadOnly="true"
                  height="auto"
                  minHeight="150px"
                  resize="none"
                  className="text-area"
                  paddingRight={PADDING_RIGHT}
                />
                {exchangeToken
                  ? <CopyButton copyValue={exchangeToken.toString()} />
                  : null
                }
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
              <Button onClick={() => getToken({jwt: currentTokenValue})} mr={3}>
                Exchange token
              </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
});

export default TokenManagement;
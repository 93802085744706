import { observer } from "mobx-react";
import { Flex, Heading, IconButton, Box } from "@chakra-ui/react";
import { get } from "lodash";
import EyeOffIcon from "../Icons/EyeOffIcon";
import { Resizable } from 're-resizable';
import { NavArrowDown, NavArrowUp } from "iconoir-react";
import {
  WATCHED_BLOCK_DEFAULT_HEIGHT,
  WATCHED_BLOCK_MAX_HEIGHT,
  WATCHED_BLOCK_MIN_HEIGHT,
  WATCHED_BLOCK_MAX_HEIGHT_HIDDEN
} from "./constants";
import { useEffect, useMemo, useRef } from "react";
import './style.scss';
import { UserManagementStore } from "src/stores";
import { KEY_FOR_TREE_ROOT } from "../constants";

const WatchedBlock = observer(({
  watchedVariables,
  setWatchedVariables
}) => {
  const { data, putToStorage, getFromStorage, showWatchedBlock, setShowWatchedBlock } = UserManagementStore;
  const watchedBlockHeight = getFromStorage('watchedBlockHeight');

  const watchedElements = useMemo(() => {
    return watchedVariables.map(watchedElement => {
      const pathToElement = watchedElement.reduce((accumulator, currentValue, index) => (
        `${accumulator} ${accumulator ? ' -> ' : ''} ${index !== watchedElement.length - 1 ? currentValue : ''}`
      ), '');
      const watchedElementName = watchedElement[watchedElement.length - 1];
      const watchedElementPath = watchedElement.filter(element => element !== KEY_FOR_TREE_ROOT)
      const watchedElementValue = get(data, watchedElementPath);
      return (
        <Flex alignItems="center" opacity={watchedElementValue ? 1 : 0.5}>
          <span className="watched-element-path">{`${pathToElement}`}</span>
          <b>{watchedElementName}: </b>
          <span className="watched-value-text">{`${watchedElementValue}`}</span>
          <EyeOffIcon
            watchedVariables={watchedVariables}
            setWatchedVariables={setWatchedVariables}
            watchedElement={watchedElement}
          />
        </Flex>
      )
    })
  }, [watchedVariables, data, setWatchedVariables]);

  const resizableRef = useRef(null);

  useEffect(() => {
    if (!showWatchedBlock) {
      resizableRef.current.updateSize({ height: WATCHED_BLOCK_DEFAULT_HEIGHT});
      putToStorage('watchedBlockHeight', 0)
    } else {
      if (!watchedBlockHeight) {
        putToStorage('watchedBlockHeight', WATCHED_BLOCK_DEFAULT_HEIGHT)
      }
    }
  }, [showWatchedBlock, putToStorage, watchedBlockHeight]);

  return (
    <Resizable
      defaultSize={{
        width: '100%',
        height: watchedBlockHeight,
      }}
      minHeight={WATCHED_BLOCK_MIN_HEIGHT}
      maxHeight={!showWatchedBlock ? WATCHED_BLOCK_MAX_HEIGHT_HIDDEN : WATCHED_BLOCK_MAX_HEIGHT}
      enable={{top: showWatchedBlock}}
      ref={resizableRef}
      onResize={(_, __, element) => putToStorage('watchedBlockHeight', element.style.height)}
    >
      {showWatchedBlock
        ? <div className="watched-values-show-block">
            <Box
              pl={10}
              pr={10}
              pt="5px"
              pb="10px"
              className="watched-values-block"
            >
              {watchedElements}
            </Box>
          </div>
        : null
      }
      <Flex
        className="watched-values-icon-block"
        pr="2"
        flexDirection="row"
        justifyContent="space-between"
        pl={10}
      >
        <Heading as="h6" size="xs" mb={1} mt={2}>
          Watched values
        </Heading>
        <IconButton
          onClick={() => setShowWatchedBlock(!showWatchedBlock)}
          aria-label="combobox"
          colorScheme="gray"
          icon={showWatchedBlock ? <NavArrowDown /> : <NavArrowUp />}
        />
      </Flex>
  </Resizable>

  )
})

export default WatchedBlock
import $RefParser from "@apidevtools/json-schema-ref-parser";
import { entries, values } from "lodash";
import { action, flow, makeObservable, observable } from "mobx";
import { GamesStore, ResponsesHistoryStore } from ".";
import schemaApi from "../api/schema";

class SchemaStore {
  constructor() {
    this.schema = null;
    this.uniqueParametersLists = {};
    this.schemaWithRef = null;
    this.schemaForAllElementsWithRef = null;

    makeObservable(this, {
      schema: observable,
      uniqueParametersLists: observable,
      loadSchema: flow.bound,
      initUniqueParametersLists: action.bound,
      getUniqueValuesFromData: action.bound,
      updateUniqueValuesFromData: action.bound,
      getSchemaWithRef: flow.bound,
      schemaWithRef: observable,
      schemaForAllElementsWithRef: observable
    });
  }

  initUniqueParametersLists() {
    this.schema && entries(this.schema.properties).forEach(([key, value]) => {
      if (value.unique) {
        this.uniqueParametersLists[key] = [];
      }
    })
  }

  // fill all existing values for unique parameters
  getUniqueValuesFromData({data}) {
    entries(this.uniqueParametersLists).forEach(([key]) => {
      this.uniqueParametersLists[key] = values(data).reduce((arr, el) => {
        return [
          ...arr,
          {
            value: el.data[key],
            key: el.internal.key
          }
        ]
      }, []);
    })
  }

  updateUniqueValuesFromData(data) {
    this.initUniqueParametersLists();
    this.getUniqueValuesFromData(data);
  }

  *loadSchema(schema_name, withNull) {
    const { projectid, environment } = GamesStore;
    try {
      const { data } = yield schemaApi.getSchema({schema_name, projectid, environment, withNull});
      this.schema = data || [];

      this.initUniqueParametersLists();
      this.getSchemaWithRef(data, withNull);
    } catch (error) {
      this.schema = [];
    }
  }

  *getSchemaWithRef(schema, withNull) {
    try {
      if (schema) {
        let newSchema = yield $RefParser.dereference(schema);
        if (withNull) {
          this.schemaForAllElementsWithRef = newSchema;
        } else {
          this.schemaWithRef = newSchema;
        }
      }
    } catch (error) {
      const { setResponsesHistory } = ResponsesHistoryStore;
      setResponsesHistory({
        action: "Get json schema error",
        status: 'ERROR',
        errorMessage: error
      });
    }
  }
}

export default new SchemaStore();
import { observer } from "mobx-react";
import { Button, Tooltip } from "@chakra-ui/react";
import { PermissionsStore } from "src/stores";

const ButtonWithTooltip = observer((props) => {
  const { permissions } = PermissionsStore;
  const { children, accessPermission, tooltipValue = "" } = props;

  const isShowTooltip = accessPermission && !permissions?.includes(accessPermission);
  const tooltipText = isShowTooltip ? "You don't have permission to this action" : tooltipValue;
  return (
    <Tooltip label={tooltipText}>
      <span>
        <Button
          {...props}
          isDisabled={props.isDisabled || isShowTooltip}
        >
          {children}
        </Button>
      </span>
  </Tooltip>
  );
});
export default ButtonWithTooltip;
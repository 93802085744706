import {
  Box, IconButton, Table,
  Th, Tr, Thead, Tbody,
  Flex, Checkbox
} from '@chakra-ui/react';
import {
  NavArrowLeft, NavArrowRight
} from 'iconoir-react';
import { observer } from "mobx-react";
import { useEffect, useRef } from "react";
import { usePagination, useSortBy, useTable, useExpanded, useRowSelect } from 'react-table';
import { DeployStore } from "../../../stores";
import {
  DEPLOY_BUTTONS_GROUP_WIDTH,
  VALID_STATE_WIDTH
} from './constants';
import SortColumnIcon from 'src/components/SortColumnIcon';
import { scrollTopAfterEvent } from 'src/helpers/scrollTopAfterEvent';
import { DEFAULT_SORTIG_COLUMN_FOR_TABLE } from 'src/constants';
import { keys } from 'lodash';
import DeployTableRow from './DeployTableRow';

const DeployTable = observer(({
  columns,
  data,
  deployButtons,
  updateTagConfigs,
  setConfirmedDeployInfo,
  setSelectedTags
}) => {
  const { getConfigTagsNames } = DeployStore;

  useEffect(() => {
    data.length && getConfigTagsNames()
  }, [getConfigTagsNames, data])

  const controlledPageSize = 1000;
  const total = data?.length;
  const hiddenColumns = ["_id", "hash"];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    visibleColumns,
    toggleAllRowsSelected,
    state: { selectedRowIds }
  } = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: controlledPageSize,
      hiddenColumns,
      sortBy: DEFAULT_SORTIG_COLUMN_FOR_TABLE
    }
  }, useSortBy, useExpanded, usePagination, useRowSelect);

  useEffect(() => {
    setSelectedTags(selectedRowIds)
  }, [selectedRowIds, setSelectedTags])

  const tableRef = useRef(null);

  return (
    <Box>
      <Table {...getTableProps()} ref={tableRef} style={{tableLayout: 'fixed'}}>
        <Thead>
          <Tr>
          <Th
              w={"20px"}
              maxW={"20px"}
              minW={"20px"}
            />
            <Th
              w={"60px"}
              maxW={"60px"}
              minW={"60px"}
            />
            <Th
              w={"125px"}
              maxW={"125px"}
              minW={"125px"}
            />
             <Th
              w={"125px"}
              maxW={"125px"}
              minW={"125px"}
            />
            <Th />
            <Th
              w={"120px"}
              maxW={"120px"}
              minW={"120px"}
            >
              <Flex alignItems="center" flexWrap="wrap" justifyContent="center">
                <span style={{width: VALID_STATE_WIDTH}}/>

              </Flex>
            </Th>
            <Th
              w={DEPLOY_BUTTONS_GROUP_WIDTH}
              maxW={DEPLOY_BUTTONS_GROUP_WIDTH}
              minW={DEPLOY_BUTTONS_GROUP_WIDTH}
            >
              {deployButtons()}
            </Th>
          </Tr>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              <Th>
                <Checkbox
                  onChange={() =>
                    toggleAllRowsSelected(!keys(selectedRowIds).length)}
                />
              </Th>
              <Th>
                <div style={{
                  display: 'flex',
                  textTransform: 'none'
                }}>
                  view configs
                </div>
              </Th>
              {headerGroup.headers.map((column) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  onClick={() => column.toggleSortBy(!column.isSortedDesc)}
                >
                  <div style={{
                    display: 'flex',
                    textTransform: 'none',
                    alignItems: 'center'
                  }}>
                    {column.render('Header')}
                    <SortColumnIcon
                      sortedIndex={column.sortedIndex}
                      canSort={column.canSort}
                      isSortedDesc={column.isSortedDesc}
                    />
                  </div>
                </Th>
              ))}
              <Th>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  textTransform: 'none'
                }}>
                  action
                </div>
              </Th>
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return <DeployTableRow
              row={row}
              setConfirmedDeployInfo={setConfirmedDeployInfo}
              updateTagConfigs={updateTagConfigs}
              visibleColumns={visibleColumns}
              isExpanded={row.isExpanded}
              tag={row.original.tag}
            />
          })}
        </Tbody>
      </Table>

      {!!total && (
        <Box>
          <IconButton
            variant='outline'
            icon={<NavArrowLeft />}
            onClick={() => scrollTopAfterEvent(previousPage, tableRef)}
            isDisabled={!canPreviousPage}
            m={3}
          />

          <IconButton
            variant='outline'
            icon={<NavArrowRight />}
            onClick={() => scrollTopAfterEvent(nextPage, tableRef)}
            isDisabled={!canNextPage}
            m={3}
          />
        </Box>
      )}
    </Box>
  )
})

export default DeployTable
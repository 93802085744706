import { isArray, isObject, keys } from "lodash";
import { DefaultValuesTypes, SchemaElement, SchemaType } from "src/types/schema";


const removeArrayNumbersFromPath = (path: string) => {
  return path.split('.').filter(el => isNaN(Number(el))).join('.');
}

export const getAllPathKeys = (
  obj: SchemaType,
  excludeParams?: Set<string> | string[],
  value?: DefaultValuesTypes,
  isCheckValue?: boolean
): string[] => {
  let paths: string[] = [];

  function findPath(obj: SchemaType | SchemaElement, str: string) {
    keys(obj).forEach((key) => {
      const parameter = (obj as SchemaType)[key];
      if (isArray(parameter) || isObject(parameter)) {
        findPath(parameter, str ? str + "." + key : key);
      } else {
        const newStr = str ? str + "." + key : key;
        const newStrWithoutNumbers = removeArrayNumbersFromPath(newStr);
        if (!excludeParams) {
          paths.push(newStr);
        } else {
          const isInclude = isArray(excludeParams)
            ? !excludeParams.includes(newStrWithoutNumbers)
            : !excludeParams.has(newStrWithoutNumbers);
          const isAddValueToPath = isCheckValue ? parameter === value : true;

          if (isInclude && isAddValueToPath) {
            paths.push(newStr);
          }
        }
      }
    });
  }

  findPath(obj, "");
  return paths;
}

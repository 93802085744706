import { ResponsesHistoryStore } from "src/stores";
import * as JSZip from 'jszip';
import { LANGUAGE, parseDataByLang } from "./configLanguageHelper";
import {
  ResultType,
  FileSystemDirectoryHandle,
  WindowWithDirectory,
  FoldersFromDirectoryType,
  FilesFromDirectoryType
} from "./uploadConfig/types";
import { KIND_OF_DIRECTORY, KIND_OF_FILE, ZIP_FILE_TYPE } from "./uploadConfig/constants";

export const uploadZIP = (event: Event) => {
  const reader = new FileReader();
  const read = (file: File) => reader.readAsDataURL(file);
  const parser = (result: ResultType) => result.replace("data:application/x-zip-compressed;base64,", '');

  return upload(reader, event, read, parser, ZIP_FILE_TYPE);
};

export const uploadFile = (event: Event, lang: LANGUAGE) => {
  const reader = new FileReader();
  const read = (file: File) => reader.readAsText(file);
  const parser = (result: ResultType) => parseDataByLang(result, lang);

  return upload(reader, event, read, parser);
};

const upload = (
    reader: FileReader,
    event: Event ,
    read: (file: File) => void,
    parser: (result: ResultType) => void,
    expectedFileType?: string
) => {
  const target= event.target as HTMLInputElement;
  let file: File = (target.files as FileList)[0];

  if (file) {
    read(file);

    return new Promise(function (resolve) {
      reader.onload = function () {
        try {
          if (expectedFileType && file.type !== expectedFileType) {
            throw new Error('')
          } else {
            resolve(parser(String(reader.result)));
          }
        } catch (e) {
          ResponsesHistoryStore.setResponsesHistory({
            action: "Invalid file format",
            status: 'ERROR'
          });
        }
      };
    });
  }
};

// sort files and folders separately from each other
async function processDirectory(
  directoryHandle: FileSystemDirectoryHandle,
  currentPath: string,
  folders: FoldersFromDirectoryType,
  files: FilesFromDirectoryType
) {
  for await (const entry of directoryHandle.values()) {
    const fullPath = currentPath ? `${currentPath}/${entry.name}` : entry.name;

    if (entry.kind === KIND_OF_DIRECTORY) {
      folders.push({path: fullPath, entry});
      await processDirectory(entry, fullPath, folders, files);
    } else if (entry.kind === KIND_OF_FILE) {
      files.push({path: fullPath, entry});
    } else {
      ResponsesHistoryStore.setResponsesHistory({
        action: "Invalid file format",
        status: 'ERROR'
      });
    }
  }
}

export const uploadFromDirectory = async () => {
  const windowWithDirectory = window as WindowWithDirectory;
  if (windowWithDirectory.showDirectoryPicker) {
    const directoryHandle = await windowWithDirectory.showDirectoryPicker();
    const zip = new JSZip();

    const folders: FoldersFromDirectoryType = [];
    const files: FilesFromDirectoryType = [];

    await processDirectory(directoryHandle, '', folders, files);

    folders.forEach((el) => {
      zip.folder(el.path)
    })

    files.forEach((el) => {
      const pathParts = el.path.split('/');
      const file = el.entry.getFile();
      let folder = zip;
      // write child folders and files into the structure
      for (let i = 0; i < pathParts.length - 1; i++) {
        folder = folder.folder(pathParts[i]) as JSZip;
      }
      folder.file(pathParts[pathParts.length - 1], file)
    })

    const content = await zip.generateAsync({ type: 'base64' }).then((content: string) => {
      return content
    });
    return {
      content,
      zipInstance: zip
    }
  } else {
    // Not secure connection. Work with user's directory unavailable
    ResponsesHistoryStore.setResponsesHistory({
      action: "Not secure connection",
      status: 'ERROR'
    });
  }
};
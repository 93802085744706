import {
  AccordionItem, AccordionButton,
  Flex, AccordionPanel, AccordionIcon
} from '@chakra-ui/react';
import { observer } from "mobx-react";
import { useState } from "react";
import { DeployStore } from "../../../stores";
import CodeEditor from 'src/components/CodeEditor';
import { getConfigZIPAndUnpack } from 'src/helpers/downloadConfig';
import Progress from 'src/components/Progress';
import DeployTagArchives from './DeployTagArchives';
import { getJSONstringify } from 'src/helpers/JSONSstringifyHelper';
import { VALID_ERRORS_BLOCK } from './constants';

const DeployTagConfigInfo = observer(({tag, tagName, isExpanded}) => {
  const { getConfigTagsJSON, getErrorsAsJSON, validationDataForTags } = DeployStore;

  const [isLoading, setIsLoading] = useState(false);
  const [tagConfigs, setTagConfigs] = useState();
  const [scriptsArchive, setScriptsArchive] = useState();

  const loadTagConfig = async (tagName) => {
    if (!isExpanded) {
      setIsLoading(false);
      const value = await getConfigTagsJSON( tag, [tagName]);
      if (value?.profile_scripts?.archive) {
        await getConfigZIPAndUnpack(value.profile_scripts).then(config => {
          setScriptsArchive(config)
          delete value.profile_scripts.archive
        })
      }

      setTagConfigs(value)
      setIsLoading(true)
    }
  };

  const loadConfigErrors = async (tag) => {
    if (!isExpanded) {
      setIsLoading(false);
      await getErrorsAsJSON( tag );
      setIsLoading(true)
    }
  };

  const validationErrors = validationDataForTags.getErrors(tag);

  return (
    <AccordionItem
      key={`${tag}_${tagName}`}
      onClick={() => {
        if (tagName === VALID_ERRORS_BLOCK) {
          loadConfigErrors(tag)
        } else {
          loadTagConfig(tagName)
        }
      }}
      position="relative"
    >
      <AccordionButton>
        <Flex justifyContent="space-between" w="100%">
          {tagName}
          <AccordionIcon />
        </Flex>
      </AccordionButton>
      <AccordionPanel pb={4}>
        {isLoading
          ? <CodeEditor
              newData={
                getJSONstringify(
                  tagName === VALID_ERRORS_BLOCK ? validationErrors : tagConfigs[tagName] || ''
                )
              }
              readOnly={true}
          />
          : <Progress />
        }
          <DeployTagArchives
            currentArchive={scriptsArchive}
            tabName={tagName}
          />
      </AccordionPanel>
    </AccordionItem>
  )})

export default DeployTagConfigInfo
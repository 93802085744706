import { observer } from "mobx-react";
import { useColor } from '@gjn/react-uikit';
import { autocompletion } from "@codemirror/autocomplete";
import { EditorView, hoverTooltip } from "@codemirror/view";
import { json, jsonParseLinter } from "@codemirror/lang-json";
import { javascript, javascriptLanguage } from "@codemirror/lang-javascript";
import CodeMirror from '@uiw/react-codemirror';
import { linter, lintGutter } from "@codemirror/lint";
import { materialDark } from 'cm6-theme-material-dark';
import completions from "../../helpers/completions";
import errorMarker from "../../helpers/errorMarker";
import { checkboxPlugin } from "./checkboxesWidget";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  changeElementLineGutter,
  lineGutterElementDefaultSize,
  listenerForChangedElements
} from "./changeElementLineGutter";
import { activeLineColor, cursorType } from "src/helpers/themeExtension";
import { getParameterName } from "src/helpers/parameterStringHelper";
import { syntaxTree } from "@codemirror/language";
import { get, isEqual, keys } from "lodash";
import { getJsonParse } from "src/helpers/JSONparseHelper";
import { getPathForNode } from "src/helpers/getPathForNode";
import getSchemaFromPath from "json-schema-from-path";
import { findParameterInObject } from "src/helpers/findParameterInObjectHelper";
import { LANGUAGE } from "src/helpers/configLanguageHelper";
import "./style.scss";
import { addLoadCsvButton } from "./addLoadCsvButton";
import { usePrevious } from "@chakra-ui/react";
import { addUploadCsvButton } from "./addUploadCsvButton";
import { Validator } from "jsonschema";

const PADDING_FOR_MAX_WIDTH_CODEMIRROR = 140;

const CodeEditor = observer(({
  data,
  schema = false,
  updateChanges,
  readOnly = false,
  setErrors = () => {},
  setIsChanged = () => {},
  changedLines = [],
  checkRequiredValues = true,
  fieldListForComplite = [],
  specCompletionsPath,
  isJSONLang = true,
  lang = LANGUAGE.JSON,
  newData,
  isUseMaxWidth = true,
  isNewAddedElement = false,
  id = 0,
  clearMarkers,
  csvEditable = [],
  removeIsChanged = () => {},
  isCheckChanges = true,
  onBlur = () => {}
}) => {

  const [maxWidth, setMaxWidth] = useState('auto');
  const [markers, setMarkers] = useState(changedLines);
  const [CSVdata, setCSVdata] = useState({});

  useEffect(() => {
    if (!clearMarkers) {
      setMarkers([])
    }
  }, [clearMarkers]);

  const previousData = usePrevious(newData)

  useEffect(() => {
    if (csvEditable.length) {
      const parameterFromData = get(getJsonParse(newData), csvEditable);
      const parameterFromPreviousData = get(getJsonParse(previousData), csvEditable);
      if (!isEqual(parameterFromData, parameterFromPreviousData)) {
        const parameterFromSchema = getSchemaFromPath(schema, csvEditable.join('/'));
        const { properties } = parameterFromSchema.items;
        const headers = keys(properties).map(el => ({label: el, key: el}));
        setCSVdata({
          headers: headers,
          data: parameterFromData
        })
      }
    }
  }, [newData, schema, csvEditable, previousData])

  const { contrast } = useColor();

  const codeEditorRef = useRef(null);

  const validator = new Validator();

  useEffect(() => {
    const element = document.getElementById('main-layout');
    if (element && isUseMaxWidth) {
      const width = element.offsetWidth;
      setMaxWidth(width - PADDING_FOR_MAX_WIDTH_CODEMIRROR);
    }
  }, [isUseMaxWidth]);

  const autocompleteSelectedColor = EditorView.theme({
    ".cm-tooltip.cm-tooltip-autocomplete > ul > li[aria-selected]": {
      backgroundColor: contrast,
      color: 'black'
    }
  });

  const getErrors = (view) => {
    const currentData = lang === LANGUAGE.JS ? newData : data;
    const jsonString = view.state.doc.toString();
    const errors = errorMarker(jsonString, schema, currentData, id, checkRequiredValues, view, validator);
    setErrors(id, errors);
    return errors
  };

  useEffect(() => {
    if (codeEditorRef.current && codeEditorRef.current.view && isCheckChanges) {
      const view = codeEditorRef.current.view;
      const newDataValue = getJsonParse(newData) || newData;
      if (isEqual(data, newDataValue)) {
        removeIsChanged(id)
      } else {
        setIsChanged(id, true, view.state.doc.toString(), markers);
      }
    }
  }, [data, newData, codeEditorRef, markers, id, setIsChanged, removeIsChanged, isCheckChanges]);

  const memoChangeLineGutter = useMemo(() => {
    return changeElementLineGutter(markers, isNewAddedElement)
  }, [markers, isNewAddedElement]);

  const getHint = (view, from, text) => {
    const nodeBefore = syntaxTree(view.state).resolveInner(from, -1);
    const parameterName = getParameterName(text);
    const path = `${getPathForNode(nodeBefore, view, [], true).reverse().join('/')}/${parameterName}`;
    const parameterSchema = getSchemaFromPath(schema, path);
    return parameterSchema?.hint || findParameterInObject(schema, parameterName)?.hint
  }

  const parameterHover = hoverTooltip((view, pos) => {
    const { from, text } = view.state.doc.lineAt(pos);
    // get start and end positions for hint

    const name = getParameterName(text);
    const start = from + text.indexOf(name);
    const end = start + name.length + 1;

    if (pos > end || pos < start) {
      return null
    }
    return {
      pos: start,
      end,
      above: true,
      create() {
        let dom = document.createElement("div");
        dom.textContent = getHint(view, from, text)
        return { dom }
      }
    }
  });

  const extensions = [
    lintGutter(),
    autocompleteSelectedColor,
    cursorType,
    activeLineColor,
    lineGutterElementDefaultSize,
    addLoadCsvButton(csvEditable, CSVdata),
    addUploadCsvButton(csvEditable, updateChanges, data),
    listenerForChangedElements(setMarkers, data, lang)
  ];

  if (isCheckChanges) {
    extensions.push(memoChangeLineGutter)
  }

  if (isJSONLang) {
    extensions.push(
      json()
    );
  } else {
    extensions.push(
      javascript(),
      javascriptLanguage
    );
  }

  if (schema && schema?.properties) {
    const extensionsWithSchema = [
      checkboxPlugin(schema),
      parameterHover,
      linter(getErrors, {delay: 0})
    ];
    if (isJSONLang) {
      extensions.push(
        autocompletion({
          override: [(context) => completions(
            context, schema, fieldListForComplite, specCompletionsPath
          )]
        })
      )
    }
    extensions.push(...extensionsWithSchema);
  } else {
    extensions.push([linter(
      isJSONLang && jsonParseLinter()
    )]);
  }

  return (
    <CodeMirror
      readOnly={readOnly}
      value={newData}
      theme={materialDark}
      extensions={extensions}
      onChange={updateChanges}
      ref={codeEditorRef}
      style={{
        maxWidth
      }}
      key={id}
      id={id}
      onBlur={onBlur}
    />
  );
});
export default CodeEditor;
import { observer } from "mobx-react";
import copy from 'copy-to-clipboard';
import './style.scss';
import { useState } from "react";
import { getJSONstringify } from "src/helpers/JSONSstringifyHelper";

const CopyIcon = observer(({data, marginLeft = "10px"}) => {

  const [isCopy, setIsCopy] = useState(false);

  const copyData = (data) => {
    setIsCopy(true);
    if (typeof data === 'object') {
      copy(getJSONstringify(data));
    } else {
      copy(data)
    }

    setTimeout(() => {
      setIsCopy(false)
    }, 1000)
  };

  const CopyIconSvg = observer(() => (
    <svg
      viewBox="0 0 40 40"
      fill="currentColor"
      preserveAspectRatio="xMidYMid meet"
      className="copy-icon"
    >
      <g>
        <path d="m30 35h-25v-22.5h25v7.5h2.5v-12.5c0-1.4-1.1-2.5-2.5-2.5h-7.5c0-2.8-2.2-5-5-5s-5 2.2-5
        5h-7.5c-1.4 0-2.5 1.1-2.5 2.5v27.5c0 1.4 1.1 2.5 2.5 2.5h25c1.4 0 2.5-1.1 2.5-2.5v-5h-2.5v5z
        m-20-27.5h2.5s2.5-1.1 2.5-2.5 1.1-2.5 2.5-2.5 2.5 1.1 2.5 2.5 1.3 2.5 2.5 2.5h2.5s2.5 1.1 2.5
        2.5h-20c0-1.5 1.1-2.5 2.5-2.5z m-2.5 20h5v-2.5h-5v2.5z m17.5-5v-5l-10 7.5 10 7.5v-5h12.5v-5h-12.5z m-17.5
        10h7.5v-2.5h-7.5v2.5z m12.5-17.5h-12.5v2.5h12.5v-2.5z m-7.5 5h-5v2.5h5v-2.5z" />
      </g>
    </svg>
  ))

  return (
    <span
      className="copy-icon-block"
      onClick={(e) => {
        e.stopPropagation();
        copyData(data)}
      }
      style={{
        marginLeft: marginLeft
      }}
    >
      <CopyIconSvg />
      <span
        className={`copy-done-icon ${isCopy ? 'is-copy' : ''}`}
      >
        ✔
      </span>
    </span>
  );
});

export default CopyIcon;
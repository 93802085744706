import { observer } from "mobx-react";
import { Image } from "@chakra-ui/react";
import ImageModal from "./ImageModal";
import { useState } from "react";

const IconCell = observer(({iconValue, name}) => {
  const [openImage, setOpenImage] = useState('');

  return (
    <>
      <Image
        boxSize='50px'
        objectFit='cover'
        src={iconValue}
        cursor="pointer"
        alt={name}
        onClick={() => setOpenImage(iconValue)}
      />
      <ImageModal
        openImage={openImage}
        setOpenImage={setOpenImage}
        name={name}
      />
    </>
  );
})

export default IconCell;
import { Button, Center, Heading, VStack } from '@chakra-ui/react';
import { GaijinLogo } from '@gjn/react-uikit';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

const NotFound = observer(() => {
  let navigate = useNavigate();

  const goToMain = () => navigate('/');

  return (
    <Center h="100vh">
      <VStack gap="6">
        <GaijinLogo w="24" h="24" />
        <Heading as='h3' size='lg'>Page not found</Heading>
        <Button size="md" onClick={goToMain}>Go to main page</Button>
      </VStack>
    </Center>
  )
})

export default NotFound;
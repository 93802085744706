
import {
  CRITICAL_ERROR_STATUS,
  ERROR_STATUS,
  INVALID_ERROR_STATUS,
  SUCCESS_STATUS,
  UNDEFINED_STATUS,
  VALID_STATUS,
  WARNING_STATUS
} from "src/constants";
import { layoutConfig } from "src/components/Layout/config";
import { getJsonParse } from "src/helpers/JSONparseHelper";
import Rpc from "src/services/Rpc"
import { ConfigsViewStore, DeployStore, ResponsesHistoryStore } from "src/stores";
import { isObject } from "lodash";
import { getJSONstringify } from "src/helpers/JSONSstringifyHelper";

const STATUS_START = 'START';

const showError = (method, level) => {
  const data = {
    action: method,
    status: ERROR_STATUS
  };

  ResponsesHistoryStore.setResponsesHistory(
    data,
    level
  );
}

export const defaultHandler = (action, response, level, responseConfig) => {
  if (responseConfig) {
    const data = {
      action,
      status: SUCCESS_STATUS
    };
    ResponsesHistoryStore.setResponsesHistory(
      data,
      level
    );

    response.data = response.data?.result
  } else {
    showError(action, level)
  }
}

export const handlerWithShowResponse = (action, response, level, responseConfig) => {
  if (responseConfig) {
    const resultData = response?.data?.result;
    const data = {
      action,
      status: SUCCESS_STATUS,
      response: isObject(resultData) ? getJSONstringify(resultData) : resultData,
      isTableResponse: isObject(resultData) && resultData.titles && resultData.items
    };
    ResponsesHistoryStore.setResponsesHistory(
      data,
      level
    );

    response.data = response.data?.result
  } else {
    showError(action, level)
  }
}

export const getUserInfoHandler = (action, response, level, responseConfig) => {
  if (responseConfig) {
    const isError = response?.data?.result?.userInfo?.error;
    if (isError) {
      const resultHistory = {
        action,
        status: ERROR_STATUS,
        errorMessage: isError
      };

      ResponsesHistoryStore.setResponsesHistory(resultHistory, level);
    } else {
      const data = {
        action,
        status: SUCCESS_STATUS
      };

      ResponsesHistoryStore.setResponsesHistory(
        data,
        level
      );
    }
    response.data = response.data?.result
  } else {
    showError(action, level)
  }

}

const getConfigNameFromAction = (actionName) => {
  const [, key] = actionName.split('_');
  return layoutConfig[key]
}

const getResponseInfo = (actionName, params) => {
  const nameFromAction = getConfigNameFromAction(actionName);
  if (nameFromAction) {
    const fields = nameFromAction.tableFields;
    const { elementName } = nameFromAction;

    return fields.map(el => params.fields ? `for ${elementName} with ${el} "${params.fields[el]}"` : '').join('; ')
  }
}

export const changeConfigElementsResponseView = (action, response, level, responseConfig, currentIndex, arrLength) => {
  const config = responseConfig;
  if (responseConfig) {
    const status = response.error ? ERROR_STATUS : SUCCESS_STATUS;
    const currentLevel = level + 1;
    const data = {
      action,
      status,
      response: status === SUCCESS_STATUS ? getResponseInfo(action, config.params) : "",
      errorMessage: status === ERROR_STATUS ? response?.error?.message : '',
      ...response.result
    };
    ResponsesHistoryStore.setResponsesHistory(data, currentLevel);
    if (currentIndex >= arrLength - 1) {
      defaultHandler(action, response, level, config)
    }
  } else {
    showError(action, level)
  }
}

// if we need to delay showing the request response status
let deferredResponse = null;
let currentEvent = "";

export const updateProgressView = (_, response, level) => {
  response.data.result.forEach(res => {
    if (currentEvent !== res.event) {
      currentEvent = res.event
      ResponsesHistoryStore.clearAutocompleteStringValue();
      ResponsesHistoryStore.setResponsesHistory({action: res.event, ...res}, level, true);
    } else {
      ResponsesHistoryStore.setAutocompleteStringValue({action: res.event, type: res.status, ...res});
    }
  })

  if (deferredResponse) {
    deferredResponse();
    deferredResponse = null;
  }
}

export const updateConfigsView = (action, response, level, config) => {
  deferredResponse = () => defaultHandler(action, response, level, config);
}

const getCurrentStatus = (status) => {
  switch (status) {
    case VALID_STATUS:
      return SUCCESS_STATUS;
    case INVALID_ERROR_STATUS:
    case CRITICAL_ERROR_STATUS:
      return ERROR_STATUS
    case WARNING_STATUS:
      return WARNING_STATUS
    default:
      return UNDEFINED_STATUS
  }
}

export const validateTagView = (action, response, level, responseConfig) => {
  const result = response?.data?.result || response.result;
  const config = responseConfig;
  if (responseConfig && result) {
    const status = getCurrentStatus(result.state);
    const data = {
      action,
      status: SUCCESS_STATUS,
      response: config?.params?.tag ? `for tag ${config.params.tag}` : '',
      errorMessage: result.errors.length
        ? `[Tag status: ${status}(see more information inside tag)]`
        : '',
      subStatus: status,
      ...result
    };
    ResponsesHistoryStore.setResponsesHistory(data, level);

    if (config) {
      const tageName = config.params.tag;
      const { validationDataForTags } = DeployStore;
      validationDataForTags.setErrors(tageName, result.errors || []);
      validationDataForTags.setState(tageName, result.state);
    }
  } else {
    showError(action, level)
  }
}

export const handlerSendRequestWithController = (
  action,
  params,
  handler = defaultHandler,
  level = 0,
  isShowStart = true
) => {
  isShowStart && ResponsesHistoryStore.setResponsesHistory({action, status: STATUS_START});
  const { abortController } = ConfigsViewStore;
  return Rpc.sendRequest(action, params, abortController).then(res => {
    const config = getJsonParse(res.config.data);
    if (handler) {
      handler(action, res, level, config)
    }
    return res
  })
}

const defaultErrorHandler = (action, response, level) => {
  const resultHistory = {
    action: action,
    status: 'ERROR',
    errorMessage: response?.error?.message,
    response: response?.error?.data
  };
  ResponsesHistoryStore.setResponsesHistory(resultHistory, level);
  return response.error
}

export const updateConfigsError = (action, response, level) => {
  deferredResponse = () => defaultErrorHandler(action, response, level);
}

export const checkProfileScriptsCompilationRresponse = (action, response, level, responseConfig) => {
  const result = response?.data?.result || response.result;
  if (responseConfig && result) {
    const errors = result.errors;
    const data = {
      action,
      status: SUCCESS_STATUS,
      response: errors.length
      ? errors.map(el => el?.data).join(',')
      : '',
      errorMessage: errors.length
        ? `[${errors.map(el => el?.msg).join(',')}]`
        : '',
      subStatus: errors.length ? ERROR_STATUS : '',
      ...result
    };
    ResponsesHistoryStore.setResponsesHistory(data, level);
  } else {
    showError(action, level)
  }
}

export const handlerSendRequest = (
  action,
  params,
  handler = defaultHandler,
  level = 0,
  isShowStart = true,
  handleError = defaultErrorHandler
) => {
  isShowStart && ResponsesHistoryStore.setResponsesHistory({action, status: STATUS_START});
  return Rpc.sendRequest(action, params).then(res => {
    const config = getJsonParse(res.config.data);
    if (handler) {
      handler(action, res, level, config)
    }
    return res
  }).catch((err) => {
    if (handleError) {
      throw handleError(action, err, level)
    } else {
      return err
    }
  })
}

export const handlerSendArrayRequest = (params, handler = defaultHandler, level = 0, handleError) => {
  const startAction = 'Batch request';
  ResponsesHistoryStore.setResponsesHistory({action: startAction, status: STATUS_START});
  return Rpc.sendArrayRequest([ ...params]).then(res => {
    if (handler) {
      const configs = res.config.data;
      res.data.forEach((resElement, index, elements) => {
        const config = getJsonParse(configs).find(el => el.id === resElement.id);
        handler(config.method, resElement, level, config, index, elements.length)
      })
    }
    return res
  }).catch((err) => {
    if (handleError) {
      throw handleError(startAction, err, level)
    } else {
      return err
    }
  })
}
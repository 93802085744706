import { Button, Input, InputGroup, InputLeftAddon, InputRightElement } from "@chakra-ui/react";
import { Check, DeleteCircledOutline } from "iconoir-react";
import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { get, isArray } from "lodash";
import './style.scss';
import Progress from "src/components/Progress";
import * as moment from "moment";
import { ResponsesHistoryStore } from "src/stores";
import Storages from "src/services/Storages";

const DEBOUNCE_SEARCH = 1000;

const HistorySearch = observer(({
  isOpen,
  toastId,
  scrollToBottom
}) => {
  const KEYS_TO_FILTERS = useMemo(() => ['date', 'action', 'type', 'errorMessage.message'], []);
  const KEYS_TO_FILTERS_RESPONSE = useMemo(() => ['event', 'source', 'status'], []);

  const { setFilteredHistory } = ResponsesHistoryStore;
  const inputRef = useRef();

  const [searchTerm, setSearchTerm] = useState('');
  const [searchPreloader, setSearchPreloader] = useState(false);

  const handleClear = () => {
    setSearchTerm('');
    inputRef.current.value = '';
  }

  const handleSearch = (event) => {
    setSearchPreloader(true);
    setTimeout(() => {
      setSearchTerm(event.target.value)
    }, DEBOUNCE_SEARCH)
  }

  const placeholderData = (filtersArray) => {
    return filtersArray.map(key => {
      if (key.includes('.')) {
        const keySplit = key.split('.');
        return keySplit[keySplit.length - 1]
      }
      return key
    })
  }

  const filterElementByTerm = useCallback((searchTerm, historyElement) => {
    if (historyElement.response && isArray(historyElement.response)) {
      const filteredResponses = historyElement.response.filter(responseElement => {
        return findTermInKeys(searchTerm, KEYS_TO_FILTERS_RESPONSE, responseElement);
      });
      return {...historyElement, response: filteredResponses}
    } else {
      const filteredHistory = findTermInKeys(searchTerm, KEYS_TO_FILTERS, historyElement)
      if (filteredHistory) {
        return {...filteredHistory}
      }
    }
  }, [KEYS_TO_FILTERS, KEYS_TO_FILTERS_RESPONSE])

  const findByRangeDate = useCallback((searchTerm, element) => {
    const rangeDate = searchTerm.split('/');

    const dataFormat = 'YY-MM-DD';
    const timeFormat = 'hh:mm:ss';
    const format = `${dataFormat} ${timeFormat}`;

    const noRangeTerms = searchTerm.split(' ')?.filter(term =>
      !moment(term, dataFormat).isValid() && !moment(term, timeFormat).isValid());
    const elementDate = moment(element.date, format);

    if (rangeDate.length === 2) {
      const isBetween = elementDate.isBetween(
        moment(rangeDate[0], format),
        moment(rangeDate[1], format)
      );
      if (isBetween) {
        if (noRangeTerms.length) {
          return filterElementByTerm(noRangeTerms, element)
        } else {
          return element
        }
      }
    }
  }, [filterElementByTerm]);

  const findTermInKeys = (searchTerm, keys, element) => {
    const terms = searchTerm.filter(term => term);
    let count = 0;
    terms.forEach(term => {
      keys.forEach(key => {
        const currentElement = get(element, key);
        if (currentElement && currentElement?.includes(term)) {
          count++;
        }
      })
    })

    if (count === terms.length) {
      return element
    };
  }

  useEffect(() => {
    if (isOpen && toastId.current) {
      const searchResult = [];
      const data = Storages.get('history');
      if (data) {
        data.forEach(historyElement => {
          if (searchTerm.includes('/')) {
            const findByRangeElement = findByRangeDate(searchTerm, historyElement);
            if (findByRangeElement) {
              searchResult.push({...findByRangeElement})
            }
          }
          else if (searchTerm !== '') {
            const termsArray = searchTerm.split(' ');
            const find = filterElementByTerm(termsArray, historyElement);
            if (find) {
              searchResult.push({...find})
            }
          } else {
            searchResult.push({...historyElement})
          }
        });

        setFilteredHistory(searchResult);
        setTimeout(() => {
          setSearchPreloader(false);
        }, 1);
      }
    }
  }, [
    searchTerm,
    KEYS_TO_FILTERS,
    KEYS_TO_FILTERS_RESPONSE,
    isOpen,
    scrollToBottom,
    toastId,
    setFilteredHistory,
    filterElementByTerm,
    findByRangeDate
  ]);

  return (
    <InputGroup size='md'>
      <InputLeftAddon children={searchPreloader
        ? <Progress />
        : <Check className='empty-search-element' />}
      />
      <Input
        className="search-input"
        onChange={handleSearch}
        ref={inputRef}
        variant='filled'
        placeholder={
          `Search in history by ` +
          `${placeholderData(KEYS_TO_FILTERS)} or ${placeholderData(KEYS_TO_FILTERS_RESPONSE)}`
        }
      />
      <InputRightElement width='4.5rem'>
        <Button onClick={handleClear} leftIcon={<DeleteCircledOutline />} />
    </InputRightElement>
  </InputGroup>
  );
});

export default HistorySearch;
import { Center, Heading, VStack, Box } from "@chakra-ui/react";
import { WarningTriangleOutline } from "iconoir-react";
import { observer } from "mobx-react";
import { PermissionsStore } from "../stores";
import Progress from "./Progress";

const Permissions = observer(({ children, isLoaded, permission }) => {
  const { permissions } = PermissionsStore;

  if (!permissions.length || !isLoaded) {
    return <Progress />
  }

  if (permissions?.includes(permission))
    return <Box>{children}</Box>;

  return (
    <Center h="100%" position="absolute" top={0} left={0} right={0} bottom={0}>
      <VStack gap="6">
        <WarningTriangleOutline height={96} width={96} />
        <Heading as="h3" size="lg">
          Access is denied
        </Heading>
      </VStack>
    </Center>
  );
});

export default Permissions;

import { getStatistics } from '@uiw/react-codemirror';
import { makeOptionsFromDataForSchema } from '../makeOptionsFromData';
import { syntaxTree } from "@codemirror/language";
import { getParameterName, getParameterValue } from '../parameterStringHelper';
import { findParameterInObject } from '../findParameterInObjectHelper';
import { Completion } from '@codemirror/autocomplete';
import { SchemaElement, SchemaEnum, SchemaType } from 'src/types/schema';
import { TreeNodeType, ContextType, CompletionsType, FieldListForCompliteType, MatchBeforeType } from './types';
import { EditorView } from '@codemirror/view';
import { dispatchViewChanges } from '../dispatchViewChanges';
import { getPathForNode } from '../getPathForNode';
import { assign, get, keys } from 'lodash';
import { getParamaterTypes } from '../getParamaterTypes';
import { TYPE_NAME_FOR_BOOL } from 'src/constants';

const getSchemaFromPath = require('json-schema-from-path');

const OPTIONS_FOR_BOOL = [
  {
    label: 'true',
    apply: (view: EditorView, _: Completion, from: number, to: number) => {
      dispatchViewChanges(view, from, to, 'true');
    }
  },
  {
    label: 'false',
    apply: (view: EditorView, _: Completion, from: number, to: number) => {
      dispatchViewChanges(view, from, to, 'false');
    }
  }
];
const PROPERTY = "Property";
const STRING = "String";
const PROPERTY_NAME = "PropertyName";
const OBJECT = "Object";
const NODE_FOR_LETTER = "⚠";
const VALID_FOR_VALUE = /^\w*$/;

const PROPERTYES = 'properties';

/**
* Function for get parent parameter (parent property for some json value)
* @param tree - tree of nodes
* @param context - context for view
*/
const getParentParameter = (tree: TreeNodeType, context: ContextType): string | null => {
  if (!tree?.parent) {
    return null
  } else {
    if (tree?.parent.type.name === PROPERTY) {
      return getParameterName(context.state.sliceDoc(tree.parent.from, tree.parent.to))
    } else {
      return getParentParameter(tree?.parent, context)
    }
  }
}

const getOptionsForSymbol = (
  text: string,
  isLetter: MatchBeforeType,
  context: ContextType,
  pathList: SchemaType | SchemaEnum,
  specSymbol?: string
): CompletionsType => {
    return specSymbol && getParameterValue(text).includes(specSymbol) ? {
      from: isLetter ? isLetter.from : context.pos,
      options: makeOptionsFromDataForSchema(pathList, specSymbol),
      validFor: VALID_FOR_VALUE
    } : null
}

/**
* Function to complete values
* @param context - object with CodeMirror's info
* @param schema - schema to complete
*/
const completions = (
  context: ContextType,
  schema: SchemaElement,
  fieldListForComplite?: FieldListForCompliteType,
  paths?: string[]
): CompletionsType => {
  const isLetter = context.matchBefore(/\w+/);

  if (!schema.properties) {
    return null
  }

  const data: SchemaType = schema.properties;

  let options: Completion[] = [];
  const text = getStatistics(context).line.text;

  // If there is a parameter name before a colon, we must  change options for autocomplete
  const parameterName = getParameterName(text);

  // If we set value, we can find parameter name for this value
  let findSubOptions = findParameterInObject(data, parameterName as keyof SchemaElement);

  // Take parent node for current element
  let nodeBefore: TreeNodeType = syntaxTree(context.state).resolveInner(context.pos, -1);
  // If node is PropertyName - we work with value and must take parent.parent node for work
  if (nodeBefore.type.name === PROPERTY_NAME && nodeBefore.parent) {
    nodeBefore = nodeBefore.parent.parent
  }

  if (!nodeBefore?.parent) {
    // If nodeBefore haven't parentnode - we are at the first level of tree
    options = makeOptionsFromDataForSchema(data);
  } else {
    // If we work with property's value
    if (findSubOptions &&
      (nodeBefore.type.name === PROPERTY
        || nodeBefore.type.name === STRING
        || nodeBefore.type.name === NODE_FOR_LETTER
      )) {

        // If parameter type is boolean we set boolean options
        if (getParamaterTypes(findSubOptions).includes(TYPE_NAME_FOR_BOOL)) {
          options = OPTIONS_FOR_BOOL;
        } else if (findSubOptions?.allOf || findSubOptions?.anyOf) {
          const isParentEqualName = getParentParameter(nodeBefore, context) === parameterName;
          const parentObjectName = getParentParameter(isParentEqualName
            ? nodeBefore.parent
            : nodeBefore,
          context);
          // If we work with repeating name of parameter in nested tree (we must find right enums)
          if (parentObjectName) {
            const parentObject = findParameterInObject(data, parentObjectName);
            const itemsProps = parentObject?.items?.properties || parentObject?.additionalProperties?.properties;
            const parentProps = parentObject?.properties;
            findSubOptions = itemsProps
              ? itemsProps[parameterName]
              : (parentProps && parentProps[parameterName]);
          }

          const enums = findSubOptions?.enum
            || (findSubOptions?.allOf && findSubOptions.allOf[0]?.enum)
            || (findSubOptions?.anyOf && findSubOptions.anyOf[0]?.enum);
          if (enums?.length) {
            // Take parameters from values (for enums)
            options = makeOptionsFromDataForSchema(enums);
          }
      } else {
        if (paths?.length && fieldListForComplite && keys(fieldListForComplite).length) {
          const pathForNode = getPathForNode(nodeBefore, context).join('.');
          if (fieldListForComplite[pathForNode]) {
            const listForComplite = fieldListForComplite[pathForNode];
            const specSymbol = listForComplite.specSymbol;
            const pathList = listForComplite.list;
            if (pathList) {
              const showOptions = getOptionsForSymbol(text, isLetter, context, pathList, specSymbol);
              if (showOptions) {
                return showOptions
              } else {
                options = makeOptionsFromDataForSchema(pathList)
              }
            }
          }
        }
      }
    }
    // If we work with property
    if (!findSubOptions && (nodeBefore.type.name === OBJECT || nodeBefore.type.name === NODE_FOR_LETTER)) {
      const parentParameter = getParentParameter(nodeBefore, context);
      if (parentParameter) {
        const path = getPathForNode(nodeBefore, context, [], true).join('/');
        const parameterSchema = getSchemaFromPath(schema, path);
        if (parameterSchema) {
          const findOptions = get(parameterSchema, PROPERTYES) || get(parameterSchema.additionalProperties, PROPERTYES);
          if (findOptions) {
            options = makeOptionsFromDataForSchema(findOptions);
          } else {
            const anyOfParams = parameterSchema?.anyOf;
            if (anyOfParams) {
              const combineOfProperties = anyOfParams.reduce((acc: SchemaType, el: SchemaElement) => {
                if (el[PROPERTYES]) {
                  assign(acc, el[PROPERTYES])
                }
                return acc
              }, {});
              options = makeOptionsFromDataForSchema(combineOfProperties);
            }
          }
        }
      } else {
        options = makeOptionsFromDataForSchema(data);
      }
    }
  }

  if (!context.explicit && !isLetter) {
    return null;
  }
  return {
    from: isLetter ? isLetter.from : context.pos,
    options,
    validFor: VALID_FOR_VALUE
  }
}

export default completions;
import { gutter, GutterMarker } from "@codemirror/view";
import { getParameterName } from "src/helpers/parameterStringHelper";
import './style.scss';
import { SchemaStore } from "src/stores";
import getSchemaFromPath from "json-schema-from-path";
import { getJSONstringify } from "src/helpers/JSONSstringifyHelper";
import { entries, isArray } from "lodash";
import { getJsonParse } from "src/helpers/JSONparseHelper";

const Papa = require('papaparse');

export const parseCompleteFunction = (results, selectedNode) => {
  const { schemaWithRef } = SchemaStore;
  results.data.map((resultElement) => {
    entries(resultElement).forEach(([key, value]) => {
      const path = [selectedNode, 'items', key].join('/');
      const parameterFromSchema = getSchemaFromPath(schemaWithRef, path);
      const parseValue = getJsonParse(value);
      if (parameterFromSchema?.type === "array" && parseValue && isArray(parseValue)) {
        resultElement[key] = parseValue
      }
      else if (!value && value !== 0) {
        delete resultElement[key]
      }
    });
    return resultElement
  })
}

export const uploadCsvButton = class extends GutterMarker {
  constructor (updateChanges, data, parameterName) {
    super()
    this.currentData = data;
    this.updateChanges = updateChanges;
    this.selectedNode = parameterName;
  }
  toDOM() {
    const csvFileToArray = string => {
      const data = Papa.parse(string, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: (result) => parseCompleteFunction(result, this.selectedNode)
      });
      const newObj =  {...this.currentData, [this.selectedNode]: data.data};
      this.updateChanges(getJSONstringify(newObj))
    };

    const handleOnChange = (e) => {
      const file = e.target.files[0];
      const fileReader = new FileReader();
      if (file) {
        fileReader.onload = function (event) {
          const text = event.target.result;
          csvFileToArray(text);
        };

        fileReader.readAsText(file);
      }
      document.getElementById("upload-file-input").value = "";
    };

    const button = document.createElement("button");
    button.innerHTML = "CSV🡅";
    button.classList.add("cvs-upload-link", "cvs-button", "tooltip");

    const csvInput = document.createElement('input');
    csvInput.type = "file"
    csvInput.accept = ".csv"
    csvInput.id = "upload-file-input"
    csvInput.onchange = handleOnChange;

    const tooltipElement = document.createElement('span');
    tooltipElement.innerHTML = "Upload";
    tooltipElement.classList.add("tooltiptext")

    button.appendChild(csvInput)
    button.appendChild(tooltipElement)
    return button
  }
}

export const addUploadCsvButton = (csvEditable, updateChanges, data) => {
  return gutter({
    lineMarker(view, line) {
      const parameterName = getParameterName(view.state.doc.lineAt(line.from).text);
      if (csvEditable.includes(parameterName) && updateChanges && data) {
        return new uploadCsvButton(updateChanges, data, parameterName)
      }
    }
  })
}

import { isObject, isUndefined } from "lodash";
import { ReturnJSONType } from "src/types/JSONtype";
import { DecimalObjectType } from "./types";
const JSONInt64 = require('json-int64');

const isDecimalString  = (value: DecimalObjectType, key: string): boolean => {
  return value && isObject(value) && value.buffer && !isUndefined(value.offset) && key !== 'buffer'
}

export const getJSONInt64Parse = (data: object): ReturnJSONType | undefined => {
  try {
    return JSONInt64.parse(data, (key: string, value: DecimalObjectType) => {
      if (isDecimalString(value, key)) {
        return JSONInt64.util.toDecimalString(value)
      }
      return value
    })
  } catch (error) {
   // not return anything if we get error JSON.parse (because errors check separately)
  }
}
import { handlerSendRequest } from './handlerSendRequest';

class PermissionsApi {
  getPermissions = ({ projectid, environment }) =>
    handlerSendRequest('get_dev_portal_permissions', {
      projectid,
      environment
    })
}

export default new PermissionsApi();
import { jsonParseLinter } from '@codemirror/lang-json';
import {
  ERROR_DUPLICATE_KEYS,
  ERROR_FOR_INVALID_VALUES,
  ERROR_FOR_NOT_UNIQUE_CONFIG_ELEMENT
} from '../../constants';
import { EditorView } from '@codemirror/view';
import { ConfigElementStore, ConfigsViewStore, SchemaStore } from '../../stores';
import { SchemaElement } from 'src/types/schema';
import {
  GenerateErrorType,
  JsonMapResultType,
  PathType,
  ValidAttributesType,
  UniqueParamListsType
} from './types';
import { LANGUAGE, getDataForValidateJS } from '../configLanguageHelper';
import { getJsonParse } from '../JSONparseHelper';
import { ValidationError, Validator, ValidatorResult } from 'jsonschema';
import { getJSONstringify } from '../JSONSstringifyHelper';

const jsonHandler = require('find-duplicated-property-keys');
const getSchemaFromPath = require('json-schema-from-path');
const jsonMap = require('json-source-map');

const generateError = (
  from: number,
  to: number,
  message: string| GenerateErrorType[]
): GenerateErrorType => {
  return {
    from,
    to,
    severity: "error",
    message
  }
}

const generateErrorByPointers = (
  jsonMapResult: JsonMapResultType,
  currentPath: string,
  message: string
): GenerateErrorType => {
  const currentJSONPointers = jsonMapResult.pointers[currentPath];
  if (currentJSONPointers) {
    const to = currentJSONPointers.key.pos;
    const from = currentJSONPointers.valueEnd.pos;
    return generateError(to, from, message)
  }
  return generateError(0, 0, message)
}

const getLineForErrorByPath = (
  jsonString: string,
  message: string,
  path: PathType
): GenerateErrorType => {
  const currentPath = `/${path.join('/')}`;
  const jsonMapResult = jsonMap.parse(jsonString);
  return generateErrorByPointers(jsonMapResult, currentPath, message)
}


const findDuplicated = (json: string): PathType[] => {
  return jsonHandler(json).map((duplicatedElement: {propertyPath: () => string[]}) => {
    return duplicatedElement.propertyPath().reduce((acc: string[], el: string) => {
      if (el !== '<instance>') {
        const parsed = getJsonParse(el) as string[];
        acc.push(parsed ? parsed[0] : el)
      }
      return acc
    }, [])
  })
}

const getUniqueParameterError = (path: string[], instance: string, rowIndex: number) => {
  const { uniqueParametersLists } = SchemaStore;
  const { isElementChanged } = ConfigElementStore;
  const elementName = path[0];

  if (elementName) {
    const uniqueArray = (uniqueParametersLists as UniqueParamListsType)[elementName];
    const findUnique = uniqueArray?.findIndex(uniqueEl =>
      uniqueEl.value === instance && uniqueEl.key !== rowIndex && isElementChanged(rowIndex)
    );
    if (findUnique >= 0) {
      return `${elementName} ${ERROR_FOR_NOT_UNIQUE_CONFIG_ELEMENT}`
    }
  }
}

const checkErrorsInSchema = (
  validResult: ValidatorResult,
  diagnosticsArray: GenerateErrorType[],
  schema: SchemaElement,
  jsonString: string
) => {
  validResult.errors.forEach((error: ValidationError) => {
    const { path, message, name } = error;
    let diagnosticMessage = message;
    const parameterSchema = getSchemaFromPath(schema, path.join('/'));

    if (name === 'pattern') {
      diagnosticMessage = parameterSchema?.regex_error || message;
    }
    if (name === "anyOf") {
      const expectedTypes = parameterSchema.anyOf.map((param: {type: string}) => param.type).join(', ');
      diagnosticMessage = `Expected types: ${expectedTypes}`;
    }

    const lineForErrorByInstance = getLineForErrorByPath(jsonString, diagnosticMessage, path);
    diagnosticsArray.push(lineForErrorByInstance)
  })
}

const checkForDuplicate = (jsonString: string, diagnosticsArray: GenerateErrorType[]) => {
  const duplicated = findDuplicated(jsonString);
  if (duplicated.length) {
    // for all duplicate keys show error with key name
    duplicated.forEach((duplicatedPath: PathType) => {
      const lineForErrorByInstance = getLineForErrorByPath(
        jsonString,
        ERROR_DUPLICATE_KEYS,
        duplicatedPath
      );
      if (lineForErrorByInstance) {
        diagnosticsArray.push(lineForErrorByInstance)
      }
    })
  }
}

const checkLintError = (view: EditorView, diagnosticsArray: GenerateErrorType[]) => {
  const jsonParseLinterErrors = jsonParseLinter()(view);
  jsonParseLinterErrors.forEach(linterError => {
    diagnosticsArray.push(generateError(linterError.from, linterError.to, linterError.message));
  })
}

/**
* Function for validation json with schema
* @param jsonString - json string
* @param schema - schema for validate
* @param data - incoming data
* @param rowIndex - current row index
* @param view - json view
* @param validator - validator
*/
export const errorMarker = (
  jsonString: string,
  schema: SchemaElement,
  data: object,
  rowIndex: number,
  checkRequiredValues: boolean,
  view: EditorView,
  validator: Validator
): GenerateErrorType[] => {
  let diagnostics: GenerateErrorType[] = [];
  let jsonParse: object = {};

  const skipAttributes: string[] = [];

  const validOptions =  {
    skipAttributes: skipAttributes
  };

  if (!checkRequiredValues) {
    skipAttributes.push('required')
  }

  (validator.attributes as ValidAttributesType).unique = (instance, _, __, ctx) => {
    const { path } = ctx;
    return getUniqueParameterError(path, instance, rowIndex);
  }

  (validator.attributes as ValidAttributesType).invalid_values = (instance, schema) => {
    if (schema.invalid_values && schema.invalid_values.includes(instance)) {
      return ERROR_FOR_INVALID_VALUES
    }
  }

  try {
    jsonParse = JSON.parse(jsonString);

    const validResult = validator.validate(
      jsonParse,
      schema,
      validOptions
    );

    if (validResult.errors.length) {
      checkErrorsInSchema(validResult, diagnostics, schema, jsonString);
    }
    checkForDuplicate(jsonString, diagnostics);
  }
  catch(error) {
    const { language } = ConfigsViewStore;
    if (view && (language === LANGUAGE.JSON || !language)) {
      checkLintError(view, diagnostics)
    }

    if (language === LANGUAGE.JS) {
      const dataForValidate = getDataForValidateJS(String(data));
      const dataString = getJSONstringify(dataForValidate.body);
      const validResult = validator.validate(dataForValidate, schema, validOptions);
      checkErrorsInSchema(validResult, diagnostics, schema, dataString);
    }
  }

  return diagnostics;
}

export default errorMarker;
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';
import { isArray } from 'lodash';
import { observer } from "mobx-react";
import { useEffect, useState } from 'react';
import Loading from 'src/components/Loading';
import { getJSONstringify } from 'src/helpers/JSONSstringifyHelper';
import { LANGUAGE } from 'src/helpers/configLanguageHelper';
import ReactDiff2Html from 'src/helpers/reactDiff2Html';
import { ConfigsViewStore } from 'src/stores';

const UploadConfigModal = observer(({
  isOpen,
  uploadData,
  setIsOpen,
  elementName,
  uploadAction,
  reload,
  loadAction
}) => {
  const { language } = ConfigsViewStore;
  const [data, setData] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [isLoadDiff, setIsLoadDiff] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsLoad(false);
      const groupByNamespace = isArray(uploadData);

      loadAction(elementName, !groupByNamespace).then((data) => {
        setData(data);
        setIsLoad(true)
      })
    }
  }, [isOpen, elementName, uploadData, loadAction])

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleConfirm = () => {
    uploadAction(uploadData).then((status) => {
      if (status === 200) {
        reload && reload();
        handleClose();
      }
    })
  }

  return (
    <Modal
      size="large"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload difference</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Flex justifyContent="space-between" mb={1} fontSize="small">
            <div>Current config</div>
            <div>Upload config</div>
          </Flex>
          {isLoad
            ? <ReactDiff2Html
                current={ language === LANGUAGE.JS ? uploadData : getJSONstringify(uploadData, '\t') }
                past={ language === LANGUAGE.JS ? data.data : getJSONstringify(data, '\t')}
                setIsLoad={setIsLoadDiff}
              />
            : <Loading height='100%' />
          }
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleConfirm} colorScheme='blue' mr={3} isDisabled={!(isLoad && isLoadDiff)}>
            Upload
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
})

export default UploadConfigModal;
import {
  handlerSendRequest,
  handlerSendArrayRequest,
  updateProgressView,
  updateConfigsView,
  validateTagView,
  updateConfigsError
} from './handlerSendRequest';

class DeployApi {
  getConfigTags = ({ projectid, environment, query }) =>
    handlerSendRequest('get_config_tags', { projectid, environment, query })

  addTag = ({ projectid, environment, tag }) =>
    handlerSendRequest('save_configs', { projectid, environment, tag })

  removeTag = ({ projectid, environment, tag }) =>
    handlerSendRequest('delete_configs', { projectid, environment, tag })

  updateConfigsTag = ({ projectid, environment, tag, ignoreWarnings = false }) =>
    handlerSendRequest(
      'update_configs',
      { projectid, environment, tag, ignoreWarnings },
      updateConfigsView,
      0,
      true,
      updateConfigsError
    )

  getConfigsJSON = ({ projectid, environment, tag, configNames  }) =>
    handlerSendRequest('get_configs_as_json', { projectid, environment, tag, configNames  })

  getConfigsNames = ({ projectid, environment }) =>
    handlerSendRequest('get_tag_config_names', { projectid, environment })

  restoreConfigTags = ({ projectid, environment, tag }) =>
    handlerSendRequest('restore_configs_from_tag', { projectid, environment, tag })

  saveConfigsFromJson = ({ projectid, environment, tag, configs }) =>
    handlerSendRequest('save_configs_from_json', { projectid, environment, tag, configs })

  getUpdateProgress = ({ projectid, environment }) => {
    const level = 1;
    const isShowStart = false;
    return handlerSendRequest('get_update_progress', { projectid, environment }, updateProgressView, level, isShowStart);
  }

  getTagsHistory = ({ projectid, environment, tag, fromTime, toTime, query }) =>
    handlerSendRequest('get_tags_history', { projectid, environment, tag, fromTime, toTime, query })

  overwriteConfigs = ({ projectid, environment, tag }) =>
    handlerSendRequest('overwrite_configs', { projectid, environment, tag })

  overwriteConfigsFromJson = ({ projectid, environment, tag, configs }) =>
    handlerSendRequest('overwrite_configs_from_json', { projectid, environment, tag, configs })

  validateTag = ({ projectid, environment, tag }) =>
    handlerSendRequest('validate_tag', { projectid, environment, tag }, validateTagView)

  validateAllTags = ({ tags }) =>
    handlerSendArrayRequest(tags, validateTagView)

  removeSelectedTags = ({ tags }) =>
    handlerSendArrayRequest(tags)

  getSchema = ({ projectid, environment }) =>
    handlerSendRequest('get_tags_schema', { projectid, environment })

  getErrorsAsJSON = ({ projectid, environment, tag }) =>
    handlerSendRequest("get_errors_as_json", { projectid, environment, tag })

  }

export default new DeployApi();
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Heading } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { JSONTree } from "react-json-tree";
import { jsonTreeStyles } from "../UserManagement/themeMonokai";
import { get, merge } from "lodash";
import { LANGUAGE } from "src/helpers/configLanguageHelper";
import CompareTagsDiff from "./CompareTagsDiff";
import { javascript } from "@codemirror/lang-javascript";
import { activeLineColor, cursorType } from "src/helpers/themeExtension";
import { EditorView } from "@codemirror/view";
import CodeMirror from '@uiw/react-codemirror';
import { materialDark } from 'cm6-theme-material-dark';

const TAB_NAME_WITH_ARCHIVE = 'profile_scripts';

const DeployTagArchives = observer(({
  currentArchive,
  diffArchive = {},
  tabName,
  isDiff
}) => {
  const codeMirrorNumbersPudding = EditorView.theme({
    ".cm-line": { paddingLeft: "10px" }
  });

  return currentArchive && tabName === TAB_NAME_WITH_ARCHIVE
    ? <>
      <Heading as="h6" size="xs" mt={2}>Scripts archive</Heading>
      <JSONTree
        data={merge({...diffArchive}, {...currentArchive})}
        theme={jsonTreeStyles}
        invertTheme={false}
        valueRenderer={(_, value, ...keyPath) => {
        const path = keyPath.filter(key => key !== 'root').reverse();
        return (
          <Accordion allowToggle className="tree-accordion">
            <AccordionItem>
              {({ isExpanded }) => (
                <>
                  <AccordionButton>
                    <div className={`button-icon ${isExpanded ? "expanded" : ""}`}>▶</div>
                    {keyPath[0]}
                  </AccordionButton>
                  <AccordionPanel>
                    {isDiff
                      ? <CompareTagsDiff
                          paramsBefore = {get(currentArchive, path) || ''}
                          paramsAfter = {get(diffArchive, path) || ''}
                          lang = {LANGUAGE.JS}
                      />
                      : <CodeMirror
                          readOnly={true}
                          value={value}
                          key={keyPath}
                          theme={materialDark}
                          extensions={[
                            javascript(),
                            codeMirrorNumbersPudding,
                            cursorType,
                            activeLineColor
                          ]}
                        />
                    }
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>
        )
      }}
    />
  </>
  : null
})

export default DeployTagArchives

import {
  Select,
  Spacer,
  Flex,
  FormControl,
  FormLabel,
  Tooltip,
  Button
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import { useEffect, Fragment, useState, useMemo, forwardRef, useCallback } from "react";
import { LeaderboardStore, MenuPageSelectorsStore } from "../../../stores";
import { isArray, isEqual, keys } from "lodash";
import {
  DEFAULT_PAGE_INDEX,
  KEY_WITH_DEFAULT_EMPTY_SELECT_PARAMETER,
  DEFAULT_EMPTY_SELECT_PARAMETER
} from "./constants";
import Combobox from "src/components/Combobox";
import { getJsonParse } from "src/helpers/JSONparseHelper";

const LeaderboardFilterType = forwardRef(() => {
  const {
    lbPageDescription,
    setlbPageDescription,
    description,
    currentlbPageData,
    currentleaderboardDescription
  } = LeaderboardStore;

  const {
    currentPageSelectorIndex,
    setPageSelectorName
  } = MenuPageSelectorsStore;

  const { register, control } = useForm();
  const dynamicValues = useMemo(() => ['table', 'mode', 'group', 'platform'], []);
  const countOptions = [10, 100, 1000, 5000];
  const prevSeasonOptions = ['false', 'true'];

  const [count, setCount] = useState(countOptions[0]);
  const [prevSeason, setPrevSeason] = useState(prevSeasonOptions[0]);

  const updateOptions = (data, path) => {
    let node = data;
    for (const p of path) {
      node = node[p];
      if (!node) break
    }
    if (!node) {
      return {
        options: [],
        selected: ''
      }
    }
    if (isArray(node)) {
      return {
        options: node,
        selected: node[0] || ''
      }
    }

    const nodeKeys = keys(node);
    return {
      options: nodeKeys,
      selected: nodeKeys[0] || ''
    }
  }

  const [lbFilters, setLbFilters] = useState({
    selected: {
      table: '',
      mode: '',
      group: '',
      platform: ''
    },
    options: {
      table: [],
      mode: [],
      group: [],
      platform: []
    }
  });

  const handleSelectChange = useCallback((type, value, lbFilters) => {
    const newSelected = {...lbFilters?.selected, [type]: value};
    const newOptions = {...lbFilters?.options};
    const selectors = dynamicValues;
    const path = [];

    let startIndex = selectors.indexOf(type);
    for (let i = 0; i <= startIndex; i++) {
      path.push(newSelected[selectors[i]]);
    }
    for (let i = startIndex; i < selectors.length; i++) {
      const selector = selectors[i];
      const { options, selected } = updateOptions(description, path);
      if (i > startIndex) {
        newSelected[selector] = selected;
        newOptions[selector] = options;
        path.push(selected)
      }
    }
    setLbFilters({
      selected: newSelected,
      options: newOptions
    })
  }, [description, dynamicValues]);

  useEffect(() => {
    if (description && currentlbPageData?.leaderboardDescription) {
      const currentLBDescription = currentlbPageData.leaderboardDescription;
      const { table, mode, group, platform, count, prevSeason } = currentLBDescription;
      const options = {
        table: updateOptions(description, []).options,
        mode: updateOptions(description, [table]).options,
        group: updateOptions(description, [table, mode]).options,
        platform: updateOptions(description, [table, mode, group]).options
      };

      setLbFilters({
        selected: { table, mode, group, platform },
        options
      });
      setCount(count);
      setPrevSeason(prevSeason);
    } else {
      const dataKeys = keys(description);
      if (description && dataKeys.length) {
        const firstFilterKey = dynamicValues[0];
        const firstDataValue = dataKeys[0];
        handleSelectChange(firstFilterKey, firstDataValue);
      }
    }
  }, [currentlbPageData, description, dynamicValues, handleSelectChange])

  const getDescriptionValue = (value) => {
    return value ? `_${value}` : '';
  }

  const handleSubmitDescription = (e) => {
    e.preventDefault();
    const data = {count, ...lbFilters.selected, prevSeason: getJsonParse(prevSeason)};
    const newItemName = `${data.table || 'UNDEFINED_TABLE'}
      ${getDescriptionValue(data.mode)}
      ${getDescriptionValue(data.group)}
      ${getDescriptionValue(data.platform)}`;

    const leaderboardData = {
      leaderboardDescription: data,
      newItemName,
      pageIndex: DEFAULT_PAGE_INDEX
    };

    const newLbPageDescription = isArray(lbPageDescription) && lbPageDescription.length ? [...lbPageDescription] : [];
    newLbPageDescription[currentPageSelectorIndex] = leaderboardData || {...leaderboardData};

    setPageSelectorName(newItemName, currentPageSelectorIndex);
    setlbPageDescription(newLbPageDescription);
  }

  const disabledSubmitButton = dynamicValues.filter(value => lbFilters.options[value] === null);

  const setOptions = (arr) => {
    return arr.map(el => ({value: el, context: ""}))
  }

  const isFilterValuesChanged = useMemo(() => {
    if (!currentleaderboardDescription) {
      return false
    }
    const oldData = {};
    dynamicValues.forEach(value => {
      oldData[value] = currentleaderboardDescription[value]
    })
    const newData = {count: count?.toString(), ...lbFilters.selected, prevSeason: prevSeason.toString()};
    const formattedOldData = {
      ...oldData,
      count: currentleaderboardDescription?.count?.toString(),
      prevSeason: currentleaderboardDescription?.prevSeason?.toString()
    }
    return !isEqual(formattedOldData, newData);
  }, [count, currentleaderboardDescription, lbFilters.selected, prevSeason, dynamicValues]);

  const previousKey = useMemo(() => (key) => {
    const isDynamicValues = dynamicValues.includes(key);
    return isDynamicValues ? dynamicValues[dynamicValues.indexOf(key) - 1] : ''
  }, [dynamicValues]);

  const isPreviousIsEmpty = useMemo(() => (key) => {
    const isDisabledFlag = !lbFilters?.options[key]?.length;
    const previousKeyValue = previousKey(key);

    const previousValue = lbFilters.selected[previousKeyValue];
    return isDisabledFlag && previousKeyValue && !previousValue
  }, [previousKey, lbFilters]);

  return (
    <form
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: '20px'
      }}
      id="leaderboard-filters"
      className="leaderboard-filters-block"
      data-testid="leaderboard-filters"
      onSubmit={handleSubmitDescription}
    >
      <Fragment>
        {dynamicValues && dynamicValues?.map(key => {
          const { options: stateOptions } = lbFilters || {};
          const optionsByKey = stateOptions && stateOptions[key];
          const options = optionsByKey
            ? setOptions(optionsByKey)
            : [];
          const isPreviousIsEmptyValue = isPreviousIsEmpty(key);
          const itemsElementValues =
            !isPreviousIsEmptyValue && key === KEY_WITH_DEFAULT_EMPTY_SELECT_PARAMETER
              ? [DEFAULT_EMPTY_SELECT_PARAMETER, ...options]
              : options;

          return <Tooltip
            label={isPreviousIsEmptyValue
              ? `Choose ${previousKey(key)}`
              : null
            }
            key={key}
          >
            <FormControl
              style={{
                marginLeft: '0.5rem'
              }}
            >
              <FormLabel>Select {key}</FormLabel>
              <Controller
                name={key}
                control={control}
                {...register(key, {required: false})}
                ref={null}

                render={() => {
                  return <Combobox
                    items={itemsElementValues || []}
                    key={`select_${key}`}
                    name={key}
                    maxW={200}
                    h="40px"
                    datatestid={key}
                    isDisabled={isPreviousIsEmptyValue}
                    value={lbFilters.selected[key] || ''}
                    setValue={(e) => {
                      handleSelectChange(key, e, lbFilters)
                    }}
                  />
                }}
              />
              <Spacer/>
            </FormControl>
          </Tooltip>
        })}
        {keys(description).length
          ? <>
            <FormControl
              style={{
                marginLeft: '0.5rem'
              }}
            >
              <FormLabel>Select prevSeason</FormLabel>
              <Controller
                name={"prevSeason"}
                control={control}
                {...register("prevSeason", {required: false})}
                ref={null}
                render={() => {
                  return <Combobox
                    items={ prevSeasonOptions.map(el => ({value: el, context: ""}))}
                    key={"Select prevSeason"}
                    name={"prevSeason"}
                    maxW={200}
                    h="40px"
                    value={prevSeason}
                    setValue={setPrevSeason}
                  />
                }}
              />
            </FormControl>
            <Select
              name="count"
              maxW={200}
              ml="3"
              {...register("count", {required: false})}
              value={count}
              onChange={(e) => setCount(e.target.value)}
            >
              {countOptions.map(el => (
                <option
                  key={el}
                  value={el}
                >
                  {el}
                </option>
              ))}
            </Select>
            <Flex gap='1'>
              <Button
                size="md"
                variant="outline"
                type="submit"
                colorScheme={isFilterValuesChanged ? 'green' : 'gray'}
                disabled={!keys(description).length || disabledSubmitButton.length > 0}
                ml="5px"
              >
                Get Leaderboard
              </Button>
            </Flex>
          </>
        : null}
    </Fragment>
  </form>)
})
export default LeaderboardFilterType

import { observer } from "mobx-react";
import {
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputLeftElement
} from "@chakra-ui/react";
import { PermissionsStore, UserManagementStore } from "../../../stores";
import './style.scss';
import { useEffect, useMemo, useState } from "react";
import JSONTreeComponent from "./JSONTreeComponent";
import {
  CLASSNAME_FOR_SCROLL_BLOCK,
  GET_ACTION_ERROR_MESSAGE,
  GET_ACTION_PERMISSIONS_ERROR_MESSAGE,
  MAIN_BLOCK_DEFAULT_MARGIN,
  SERVICE_ACTIONS_DEFAULT_WIDTH
} from "./constants";
import { Resizable } from "re-resizable";
import { keys } from "lodash";
import ServiceActions from "./ServiceActions";
import { RefreshDouble, Search } from "iconoir-react";
import Progress from "src/components/Progress";
import ButtonWithTooltip from "src/components/ButtonWithTooltip";

const UserManagementItem = observer(({
  isExpanded,
  service,
  serviceActionsWidth,
  setServiceActionsWidth,
  watchedVariables,
  setWatchedVariables,
  userManagementBlockRef
}) => {
  const { permissions } = PermissionsStore;
  const {
    getUserProfile,
    getServiceApi,
    actions,
    data,
    putToStorage,
    reloadService,
    userServiceError,
    showWatchedBlock,
    reloadServicesAfterAction,
    setReloadServicesAfterAction
  } = UserManagementStore;

  const [searchValue, setSearchValue] = useState('');
  const [isLoad, setIsLoad] = useState(true);

  const isEnableViewActions = permissions.includes(`can_view_${service}_api`);

  const isEnableViewService = permissions.includes(`can_read_${service}_user_profile`);

  useEffect(() => {
    const fetchData = async () => {
      if (isExpanded) {
        setIsLoad(false);
        const userProfilePromise = getUserProfile(service);
        const serviceApiPromise = isEnableViewActions ? getServiceApi(service) : Promise.resolve();

        await Promise.allSettled([userProfilePromise, serviceApiPromise]);

        setIsLoad(true)
      }
    }
    fetchData()
  }, [isExpanded, service, getUserProfile, isEnableViewActions, getServiceApi]);


  const serviceData = useMemo(() => data[service] || [], [data, service]);

  const serviceAction = actions[service] || [];

  const [maxHeightBlock, setMaxHeightBlock] = useState(0);

  useEffect(() => {
    const { height } = userManagementBlockRef.current.getBoundingClientRect();
    setMaxHeightBlock(height - MAIN_BLOCK_DEFAULT_MARGIN)
  }, [userManagementBlockRef, showWatchedBlock]);

  const isReloadAfterAction = useMemo(() => {
    return reloadServicesAfterAction?.includes(service)
  }, [reloadServicesAfterAction, service]);

  const errorBlockForAction = () => (
    <Box ml={2}>
      {!isEnableViewActions
        ? GET_ACTION_PERMISSIONS_ERROR_MESSAGE
        : GET_ACTION_ERROR_MESSAGE
      }
    </Box>
  )

  return <>
    <AccordionButton>
      <Box as="span" flex='1' textAlign='left' position="relative" className="service-name-block">
        <span className="service-name-block__text">{service}</span>
        <span className="service-name-block__progress">{!isLoad ? <Progress size={5} /> : null}</span>
      </Box>
      <AccordionIcon />
    </AccordionButton>
    <AccordionPanel display="flex">
      {isLoad
        ? <>
          <div
            className="user-info-block"
            style={{
              width: `calc(100% - ${serviceActionsWidth[service] || SERVICE_ACTIONS_DEFAULT_WIDTH})`,
              position: 'relative',
              maxHeight: maxHeightBlock,
              overflow: 'auto'
            }}
          >
            {isEnableViewService && !userServiceError[service] && keys(serviceData).length
              ? <>
                <Flex>
                  <ButtonWithTooltip
                    tooltipValue="Reload profile automatically"
                    mr={1}
                    className="reload-automatically-button"
                    p={1}
                  >
                    <div className={`reload-icon ${isReloadAfterAction ? 'reload-check' : ''}`} />
                    <Checkbox
                      isChecked={isReloadAfterAction}
                      className="reload-automatically-button__checkbox"
                      onChange={(e) => setReloadServicesAfterAction(service, e.target.checked)}
                    />
                  </ButtonWithTooltip>
                  <ButtonWithTooltip
                    tooltipValue="Reload profile"
                    onClick={() => reloadService(service)}
                    p={1}
                  >
                    <RefreshDouble />
                  </ButtonWithTooltip>
                  <InputGroup ml={1}>
                    <InputLeftElement>
                      <Search />
                    </InputLeftElement>
                    <Input onChange={(e) => setSearchValue(e.target.value)} value={searchValue}/>
                  </InputGroup>
                </Flex>
                <JSONTreeComponent
                  value={serviceData}
                  watchedVariables={watchedVariables}
                  setWatchedVariables={setWatchedVariables}
                  treeKey={service}
                  searchValue={searchValue}
                />
              </>
              : userServiceError[service] || null
            }
          </div>
          <Resizable
            enable={{left: true}}
            className="user-management-block__resizeble"
            defaultSize={{
              width: serviceActionsWidth[service] || SERVICE_ACTIONS_DEFAULT_WIDTH
            }}
            onResize={(_, __, ref) => {
              const width = ref.style.width;
              const currentActionWidth = {...serviceActionsWidth, [service]: width};
              setServiceActionsWidth(currentActionWidth);
              putToStorage('serviceActionsWidth', currentActionWidth);
            }}
          >
            <div className={CLASSNAME_FOR_SCROLL_BLOCK}>
              {isEnableViewActions && keys(serviceAction).length
                ? <ServiceActions
                    serviceActions={serviceAction}
                    service={service}
                    userManagementBlockRef={userManagementBlockRef}
                  />
                : errorBlockForAction()
              }
            </div>
          </Resizable>
        </>
        : null
      }
    </AccordionPanel>
  </>
});

export default UserManagementItem
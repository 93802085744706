import { isNull } from "lodash";
import { makeObservable, observable, action, computed } from "mobx";

class MenuPageSelectorsStore {
  constructor() {
    this.index = 0;
    this.pageSelectors = [];
    this.nameForAddingNewPageSelector = '';
    this.isCalculatePageSelectorLength = false;
    this.addNewPageSelectorWithModal = true;
    this.handleRemovePageSelector = null;
    this.handleAddPageSelector = null;
    this.typeForAddingNewPageSelector = '';
    this.headerPageSelector = '';
    this.handleChangeSelector = null;
    this.isAddPageSelectorDisable = false;
    this.hintForDisableAdd = '';

    makeObservable(this, {
      index: observable,
      pageSelectors: observable,
      addPageSelector: action.bound,
      removePageSelector: action.bound,
      setPageSelector: action.bound,
      clearPageSelector: action.bound,
      setPageSelectorParam: action.bound,
      checkPageSelectorIdx: false,
      currentPageSelector: computed,
      nameForAddingNewPageSelector: observable,
      setNameForAddingNewPageSelector: action.bound,
      isCalculatePageSelectorLength: observable,
      setCalculatablePageSelectorWidth: action.bound,
      setPageSelectorName: action.bound,
      addNewPageSelectorWithModal: observable,
      setAddNewPageSelectorWithModal: action.bound,
      handleRemovePageSelector: observable,
      setHandleRemovePageSelector: action.bound,
      currentPageSelectorIndex: computed,
      typeForAddingNewPageSelector: observable,
      handleAddPageSelector: observable,
      headerPageSelector: observable,
      setHeaderPageSelector: action.bound,
      handleChangeSelector: observable,
      currentPageSelectorName: computed,
      isAddPageSelectorDisable: observable,
      setIsAddPageSelectorDisable: action.bound,
      hintForDisableAdd: observable,
      setHintForDisableAdd: action.bound
    });
  }

  setHeaderPageSelector(value) {
    this.headerPageSelector = value;
  }

  addPageSelector(name, closable, type, switchPageSelector, nameOverride = null) {
    this.pageSelectors.push({name, closable, type, nameOverride, params: {}});

    if(switchPageSelector)
      this.index = this.pageSelectors.length - 1;
  }

  removePageSelector(idx) {
    if(this.checkPageSelectorIdx(idx)) {
      if(idx < this.index || (idx === this.index && idx === this.pageSelectors.length - 1))
        this.index -= 1;

      this.pageSelectors.splice(idx, 1);
    }
  }

  setPageSelector(idx) {
    if(this.checkPageSelectorIdx(idx)) {
      this.index = idx;
    }
  }

  get currentPageSelector() {
    return this.checkPageSelectorIdx(this.index) ? this.pageSelectors[this.index] : false;
  }

  get currentPageSelectorIndex() {
    return this.index
  }

  setPageSelectorParam(name, value) {
    if(this.checkPageSelectorIdx(this.index) && name) {
      const selectorParams = this.pageSelectors[this.index].params;
      selectorParams[name] = value
    }
  }

  checkPageSelectorIdx(idx) {
    return 0 <= idx && idx <= this.pageSelectors.length - 1;
  }

  setNameForAddingNewPageSelector(value, type, event, changeEvent) {
    this.nameForAddingNewPageSelector = value
    this.typeForAddingNewPageSelector = type || '';
    this.handleAddPageSelector = event;
    this.handleChangeSelector = changeEvent;
  }

  setCalculatablePageSelectorWidth(value) {
    this.isCalculatePageSelectorLength = value;
  }

  setPageSelectorName(name, index) {
    if (this.checkPageSelectorIdx(index)) {
      this.pageSelectors[index].name = name;
    }
  }

  setAddNewPageSelectorWithModal(value) {
    this.addNewPageSelectorWithModal = value
  }

  setHandleRemovePageSelector(event) {
   this.handleRemovePageSelector = event;
  }

  get currentPageSelectorName() {
    return !isNull(this.currentPageSelector.nameOverride)
      ? this.currentPageSelector.nameOverride
      : this.currentPageSelector.name;
  }

  setIsAddPageSelectorDisable(value) {
    this.isAddPageSelectorDisable = value
  }

  setHintForDisableAdd(hintValue) {
    this.hintForDisableAdd = hintValue
  }

  clearPageSelector() {
    this.index = 0;
    this.pageSelectors = [];
    this.nameForAddingNewPageSelector = '';
    this.isCalculatePageSelectorLength = false;
    this.addNewPageSelectorWithModal = true;
    this.headerPageSelector = '';
    this.isAddPageSelectorDisable = false;
    this.hintForDisableAdd = '';
  }
}

export default new MenuPageSelectorsStore();
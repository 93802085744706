import { EditorView } from '@codemirror/view';
import { Completion } from '@codemirror/autocomplete';
import { DefaultValuesTypes, SchemaElement, SchemaEnum, SchemaType, TypesEnum } from "src/types/schema";
import { getEnumValuesAsStrList  } from "../getEnumValuesAsStrList";
import { OptionsReturnType } from './types';
import { isArray } from 'lodash';
import { formatWithCommaApply } from '../formatCommaApply';
import { dispatchViewChanges } from '../dispatchViewChanges';

/**
* Function to get default value by type for parameter
* @param type - parameter's type
*/
const getDefaultValueFromType = (type: TypesEnum, element: SchemaElement): DefaultValuesTypes  => {
  switch (type) {
    case 'string':
      return "";
    case 'boolean':
      return false;
    case 'array':
      return [];
    case 'object':
      return {};
    case 'integer':
      return 0;
    case 'number':
    case 'float':
      return 0.0;
    case 'enum': {
      const enumsValues = getEnumValuesAsStrList(element);
      return `${enumsValues?.length ? enumsValues[0] : ''}`;
    }
    default:
      return '';
  }
}

const getElementType = (element: SchemaElement, isEnum?: boolean): TypesEnum => {
    if (element?.anyOf) {
      return element.anyOf[0].type
    }
    if (isEnum) {
      return TypesEnum.Enum
    }
    return element?.type || ''
}

/**
* Function to choose and format default value from init parameter, or from preassigned default values by type
* @param defaultValue - parameter's default value from init
* @param element - element from json object
*/
export const getDefaultParameterValue = (
  element: SchemaElement,
  defaultValue?: DefaultValuesTypes
): string | DefaultValuesTypes => {
  const isEnum = element?.allOf || element?.anyOf || element?.enum;
  const type = getElementType(element, !!isEnum);
  return defaultValue || getDefaultValueFromType(type, element);
};

/**
* Function to convert input to options for autocomplete
* @param schema - input data for transform
*/
export const makeOptionsFromDataForSchema = (
  schema: SchemaType | SchemaEnum,
  specSymbol?: string
): OptionsReturnType => {
  const schemaElements = Array.isArray(schema) ? schema : Object.keys(schema);
  return schemaElements.map((schemaElement) => {
    const isParameter = !Array.isArray(schema);
    const element = !isArray(schema) ? schema[schemaElement] : null;
    const defaultValue = element?.init;
    const defaultParameterValue = element && JSON.stringify(getDefaultParameterValue(element, defaultValue), null, 2);
    const completeParameterValue = isParameter ? `: ${defaultParameterValue}` : '';
    const applyDefaultWithFormat = `"${schemaElement}"${completeParameterValue}`;
    return {
      label: schemaElement,
      apply: (view: EditorView, _: Completion, from: number, to: number) => {
        const stringWithQuote = view.state.sliceDoc(from - 1, to + 1);
        const quotesCount = [...stringWithQuote].filter(char => char === '"')?.length;

        let posTo = to;
        let posFrom = from;
        // If we have "" in parameter name we must replace to our default value
        posTo += Number(quotesCount === 2);
        posFrom -= Number(quotesCount > 0);

        if (specSymbol) {
          posFrom = posFrom - (specSymbol.length - 1);
        }

        const insert = formatWithCommaApply(applyDefaultWithFormat, view, specSymbol);
        dispatchViewChanges(view, posFrom, posTo, applyDefaultWithFormat, specSymbol);
        // If we raplaced all string - change cursor position
        if ([0, 2].includes(quotesCount)) {
          view.dispatch({selection: {anchor: posFrom + insert.length} })
        }
      }
    }
  })
}

export default makeOptionsFromDataForSchema;
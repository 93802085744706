import { action, makeObservable, observable } from "mobx";

class DialogIsUnsavedChangesStore {
  constructor() {
    this.isOpenDialog = false;
    this.handleConfirmDialog = null

    makeObservable(this, {
      isOpenDialog: observable,
      handleConfirmDialog: observable,
      setDialogIsUnsavedChangesConfirm: action.bound,
      clearDialogIsUnsavedChangesData: action.bound
    });
  }

  setDialogIsUnsavedChangesConfirm(handleConfirmDialog) {
    this.isOpenDialog = true;
    this.handleConfirmDialog = handleConfirmDialog;
  }

  clearDialogIsUnsavedChangesData() {
    this.isOpenDialog = false;
    this.handleConfirmDialog = null;
  }
}

export default new DialogIsUnsavedChangesStore();
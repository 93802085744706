import { Box, Button, Divider, Flex, Heading, Text } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import ServicesInfoTable from "./ServicesInfoTable";
import { ServicesInfoStore, GamesStore, SidebarMenuStore } from "../../../stores";
import Progress from "src/components/Progress";
import { isNull, keys, values } from "lodash";
import { INIT_BUTTON_WIDTH } from "./constants";
import "./style.scss";
import MatchingInfoTable from "./MatchingInfoTable";

const ServicesInfo = observer(() => {
  const {
    loadServicesInfo,
    servicesInfo,
    loadPortalServerVersion,
    serverVersion,
    registerConfigs,
    error,
    clearServicesInfo
  } = ServicesInfoStore;
  const { projectid, environment, games_loaded } = GamesStore;
  const { enableServices } = SidebarMenuStore;

  const [isLoadInitService, setIsLoadInitService] = useState(false);

  const handleRegisterConfigs = async () => {
    setIsLoadInitService(true);
    const availableServices = keys(servicesInfo).filter(key => !isNull(servicesInfo[key].canRegisterConfigs))
    await registerConfigs(availableServices);
    setIsLoadInitService(false);
  }

  useEffect(() => {
    if (games_loaded) {
      loadServicesInfo();
      loadPortalServerVersion();
    }
    return () => clearServicesInfo()

  }, [loadServicesInfo, projectid, environment, loadPortalServerVersion, games_loaded, clearServicesInfo]);

  const isDisabledInitServices = useMemo(() => values(servicesInfo).some(el =>
    !el.canRegisterConfigs && !isNull(el.canRegisterConfigs)
  ), [servicesInfo])

  return (
    <Box>
      <Heading as="h3" size="lg">
        Services Info
      </Heading>
      <Divider orientation="horizontal" />
      <Box mt={3}>
        <ServicesInfoTable
          servicesInfoData={servicesInfo}
          handleRegisterConfigs={handleRegisterConfigs}
          error={error}
        />
      </Box>
      <Heading as="h3" size="lg" mt={2}>
        Services actions
      </Heading>
      <Flex justifyContent="end" className="services-actions-block">
        <Button
          size='xs'
          width={INIT_BUTTON_WIDTH}
          colorScheme='green'
          variant='solid'
          onClick={handleRegisterConfigs}
          isDisabled={isDisabledInitServices}
        >
          Init services
        </Button>
      </Flex>
      {isLoadInitService
        ? <Progress isFullScreen={isLoadInitService} />
        : null
      }
      <Box mt={5}>
        <Text fontSize="xs">UI version: {process.env.UI_VERSION}</Text>
        <Text fontSize="xs">Server version: {serverVersion}</Text>
      </Box>
      {enableServices?.includes('matching_rpc') ? <MatchingInfoTable /> : null}
    </Box>
  );
});

export default ServicesInfo;
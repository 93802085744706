import { Box, Flex } from "@chakra-ui/react";
import { ColorsType, ColumnType, ColumnWidthType } from "./types";

export const getColumnsWithSeparator = (
  columnsList: string[],
  columns: ColumnType[],
  colors: ColorsType,
  widths: ColumnWidthType
) => {
  return columnsList.map(column => {
    const splitColumn = column.split('/');
    return splitColumn.length > 1 ? {
      "Header": column,
      "accessor": column,
      width: widths?.[column] || 300,
      Cell: ({row}: {row: {original: {[key: string]: string}}}): JSX.Element => {
        return <Flex flexDirection="column" h="100%" >
          {splitColumn.map((element: string) => (
            <Box backgroundColor={colors?.[element] || 'inherit'} flexGrow={1} padding={1}>
              {row.original[element]}
            </Box>
          ))}
        </Flex>
      }
    } : columns.find((element: ColumnType) => element.Header === column)
  });
};

import { keys } from "lodash";
import { NOT_REMOVE_FROM_STORE } from "src/constants";

class Storages {
  get = (name) => {
    if (typeof window === 'undefined') return null;

    return JSON.parse(localStorage.getItem(name));
  };

  put = (name, value) => {
    if (typeof window === 'undefined') return;

    localStorage.setItem(name, JSON.stringify(value));
  };
  remove = (name) => {
    if (typeof window === 'undefined') return;

    localStorage.removeItem(name);
  };
  clear = () => {
    keys(localStorage).forEach(key => {
      if (!NOT_REMOVE_FROM_STORE.includes(key)) {
        localStorage.removeItem(key);
      }
    })
  };
  clearAll = () => {
    localStorage.clear();
  }
}

export default new Storages();
export const COLORS = [
  "gray.500",
  "red.500",
  "gray.800",
  "green.500",
  "blue.500",
  "blue.800",
  "yellow.500",
  "orange.500",
  "purple.500",
  "pink.500"
];

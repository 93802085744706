import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Skeleton
} from "@chakra-ui/react";
import { ClipboardCheck, DoubleCheck } from "iconoir-react";
import { observer } from "mobx-react";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import CompareTags from "./CompareTags";
import {
  DeployStore,
  GamesStore,
  ProgressStore,
  FilterStore,
  PermissionsStore
} from "../../../stores";
import Permissions from "../../Permissions";
import DeployTable from "./DeployTable";
import DeployTableCell from "./DeployTableCell";
import { CURRENT, VALID_STATE_COLUMN_NAME, WIDTH_FOR_BUTTON } from "./constants"
import HistoryTags from "./HistoryTags";
import { formatCreateTime } from "src/helpers/formatCreateTime";
import AddUpdateTagModal from "./Modals/AddUpdateTagModal"
import ConfirmDeployModal from "./Modals/ConfirmDeploy";
import { keys } from "lodash";
import { getJSONstringify } from "src/helpers/JSONSstringifyHelper";
import ShowFilterButton from "src/components/Filter/ShowFilterButton";
import FilterBlock from "../Configs/FiltersBlock";
import ActionConfirmation from "../ActionConfirmation";
import Progress from "src/components/Progress";
import { copyToBuffer } from "src/helpers/copyToBuffer";

const Deploy = observer(({type}) => {
  const {
    tags,
    loadConfigTags,
    getConfigTagsJSON
  } = DeployStore;
  const { showProgress } = ProgressStore;
  const { permissions } = PermissionsStore
  const { updateConfigsTag, removeSelectedTags, validateAllTags, validationDataForTags } = DeployStore;
  const { projectid, environment } = GamesStore;
  const { clearFilter } = FilterStore;
  const [openSaveTagModal, setOpenSaveTagModal] = useState(false);
  const [confirmedDeployInfo, setConfirmedDeployInfo] = useState(null);
  const [selectedTags, setSelectedTags] = useState({})
  const [isLoading, setIsLoading] = useState(false);

  const tableColumns = useMemo(
    () => [
      "create_t",
      "update_t",
      "tag",
      "state"
    ],
    []
  );

  const columns = useMemo(() => {
    return (
      tableColumns.map((field) => ({
        Header: field === "state" ? VALID_STATE_COLUMN_NAME : field,
        accessor: field,
        Cell: DeployTableCell,
      })) || []
    );
  }, [tableColumns]);

  const data = useMemo(() => {
    let values = [];

    if (tags?.data)
      values = tags?.data.map(
        (value) =>
          Object.assign(
            ...tableColumns.map((field) => ({ [field]: value.data[field] }))
          ) || []
      );

    return values;
  }, [tags, tableColumns]);


  const copyCurrentToBuffer = async () => {
    const currentConfigJSON = await getConfigTagsJSON( CURRENT );

    if(currentConfigJSON) {
      copyToBuffer(getJSONstringify(currentConfigJSON, '\t'))
    }
  }


  useEffect(() => {
    permissions.length && loadConfigTags()
  }, [permissions, loadConfigTags])

  useEffect(() => {
    clearFilter();
  }, [ clearFilter])

  useEffect(() => {
    validationDataForTags.clear()
  }, [projectid, environment, validationDataForTags])

  const lastDeployedTag = useCallback(() => {
    const tagName = tags?.deployed_tag;
    const tagTime = tags?.deployed_time;
    return(
      <Fragment>{tagName && (
        <Heading as="h6" size="xs" mb={3}>
          {`Last deployed tag:
          ${tagName || ''}
          ${tagTime ? formatCreateTime(tagTime) : ''}`
          }
        </Heading>
      )}</Fragment>
    )
  }, [tags]);

  const setDeployButtons = () => {
    return (
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        width='100%'
      >
        <Button
          size='xs'
          colorScheme='green'
          variant='solid'
          onClick={() => {
            setIsLoading(true);
            validateAllTags().then(
              () => setIsLoading(false)
            )
          }}
          leftIcon={<DoubleCheck />}
          isDisabled={showProgress}
          minW={WIDTH_FOR_BUTTON}
        >
          Valid All
          {isLoading
            ? <Box position="absolute" left={3}>
                <Progress size={4}/>
              </Box>
            : null
          }
        </Button>
        <Button
          size='xs'
          colorScheme='green'
          variant='solid'
          onClick={copyCurrentToBuffer}
          leftIcon={<ClipboardCheck />}
          isDisabled={showProgress}
          w={WIDTH_FOR_BUTTON}
        >
          Copy current
        </Button>
        <div style={{width: WIDTH_FOR_BUTTON}} />

        <div style={{width: WIDTH_FOR_BUTTON}} />
        <div style={{width: "44px"}} />
      </Box>
    )
  }

  const updateTagConfigs = ( tag ) => updateConfigsTag(tag);

  const isShowFilter = type === "tags";

  return (
    <Permissions permission="can_view_tags" isLoaded={tags !== null}>
    <HStack spacing={1} pb={1}>
      <Heading as="h3" size="lg" mr={5}>
        Deploy {type === "history" ? 'history' : ''}
      </Heading>

      {type === "tags"
        ? (
          <>
             <Button
              variant="outline"
              onClick={() => setOpenSaveTagModal(true)}
              isDisabled={showProgress}
            >
              Add/Update Tag
            </Button>
            <ActionConfirmation
              marginRight='0'
              variant='outline'
              handleClick={() => removeSelectedTags(selectedTags)}
              dialogHeader={`Remove selected tags`}
              dialogBody={`Confirm selected tags removal?`}
              isDisabled={showProgress || !keys(selectedTags).length}
              value={'Delete selected'}
            />
            {isShowFilter ? <ShowFilterButton /> : null}
            <AddUpdateTagModal
              isOpen={openSaveTagModal}
              setIsOpen={setOpenSaveTagModal}
            />
          </>
        )
      : null
    }
    </HStack>
    {isShowFilter ? <FilterBlock handleLoadConfigs={loadConfigTags} /> : null}
    <Divider orientation="horizontal" />
    <Skeleton isLoaded={tags !== null}>
      <Box mt={3}>
        {lastDeployedTag()}
        {type === "tags" && (
          <Fragment>
            <DeployTable
              deployButtons={setDeployButtons}
              columns={columns}
              data={data}
              updateTagConfigs={updateTagConfigs}
              setConfirmedDeployInfo={setConfirmedDeployInfo}
              setSelectedTags={setSelectedTags}
            />
            <ConfirmDeployModal
              tagConfirmed={confirmedDeployInfo}
              setTagConfirmed={setConfirmedDeployInfo}
            />
          </Fragment>
        )}

        {type === "compare" && (
          <CompareTags />
        )}

        {type === "history" && (
          <HistoryTags />
        )}

      </Box>
    </Skeleton>
  </Permissions>
  );
});

export default Deploy;

import { action, flow, makeObservable } from "mobx";
import { getNameFromFunction, isJSLang, LANGUAGE } from "src/helpers/configLanguageHelper";
import { getDefaultParameterValue } from "src/helpers/makeOptionsFromData";
import { ConfigsViewStore, ConfigsStore, GamesStore, SchemaStore, MenuPageSelectorsStore } from ".";
import configsApi from "../api/configs";

class ConfigElementStore {
  constructor() {

    makeObservable(this, {
      isElementChanged: action.bound,
      addEmptyElement: action.bound,
      addElement: flow.bound,
      getElementByIdx: action.bound,
      removeElement: flow.bound,
      getConfigElement: action.bound,
      getDefaultInitValues: action.bound,
      changeElement: flow.bound,
      clearAllErrorsAndChanges: action.bound,
      setElementHasErrors: action.bound,
      getElementData: action.bound,
      getElementDataByLang: action.bound,
      clearConfigElement: action.bound,
      updateConfigValues: action.bound,
      getElementDataByKey: action.bound,
      setElementHasChanged: action.bound,
      setName: action.bound,
      setRemoveIsChanged: action.bound
    });
  }

  getElementDataByLang(element) {
    const { language } = ConfigsViewStore;
    switch (language) {
      case LANGUAGE.JS:
        return element?.body
      default:
        return element
    }
  }

  getElementData(idx) {
    const element = this.getElementByIdx(idx)?.data;
    if (element) {
      return this.getElementDataByLang(element)
    }
  }

  getElementDataByKey(key) {
    const { configs } = ConfigsStore;
    return configs?.data.find(config => config.internal.key === Number(key));
  }

  isElementChanged(key) {
    const { configsChanges } = ConfigsStore;
    return configsChanges.get(key)?.isChanged
  }

  setElementHasChanged(key, isChanged, changedData, changedLines) {
    const { configsChanges } = ConfigsStore;
    configsChanges.set(key, isChanged, changedData, changedLines);
  };

  setRemoveIsChanged(key) {
    const { configsChanges } = ConfigsStore;
    configsChanges.remove(key);
  }

  setElementHasErrors(key, errors) {
    const { jsonErrors } = ConfigsStore;
    jsonErrors.set(key, errors);
  };

  getElementByIdx(idx) {
    const { configs } = ConfigsStore;
    return configs?.data && configs.data[idx];
  }

  getConfigElement(id) {
    const { configs } = ConfigsStore;
    return configs.data.find(congig => congig.internal._id === id);
  }

  clearAllErrorsAndChanges() {
    const { jsonErrors, configsChanges } = ConfigsStore;

    jsonErrors.clear();
    configsChanges.clear();
  }

  addEmptyElement() {
    const { currentPageSelectorName } = MenuPageSelectorsStore;
    const { configs, namespaceList } = ConfigsStore;
    const { isUseNamespace, generateKey } = ConfigsViewStore;

    this.getDefaultInitValues();

    const addedData = {
      data: {
        ...this.defaultInitObject,
      },
      internal: {
        key: generateKey()
      },
      isNewAddedElement: true
    }
    if (isUseNamespace && namespaceList.length && currentPageSelectorName) {
      addedData.data.namespace = currentPageSelectorName;
    }

    configs?.data.unshift(addedData);
  }

  updateConfigValues(config, data, fields) {
    const { updateUniqueValues, configsChanges, updateSuccessUpdateTime } = ConfigsStore;
    const { language } = ConfigsViewStore;
    if (isJSLang(language)) {
      this.setName(fields)
    }
    config.data = fields;

    updateSuccessUpdateTime(data.successUpdateTime);

    if (config.isNewAddedElement) {
      config.internal.create_t =  data.successUpdateTime;
    }

    config.internal.hash = data.hash;
    config.internal._id = data._id || config.internal._id;
    config.isNewAddedElement = false;

    updateUniqueValues();

    configsChanges.remove(config.internal.key)
  }

  *addElement( fields, elementName, id ) {
    const { projectid, environment } = GamesStore;
    const { successUpdateTime, setFields } = ConfigsStore;

    fields = setFields(fields);

    try {
      const { data } = yield configsApi.addElement({
        projectid,
        environment,
        elementName,
        fields,
        successUpdateTime
      });

      const config = this.getElementByIdx(id);
      this.updateConfigValues(config, data, fields);

    } catch (error) {
      console.log(error);
    }
  }

  *removeElement( rowIndex, elementName ) {
    const { projectid, environment } = GamesStore;
    const {
      updateUniqueValues,
      configs,
      successUpdateTime,
      updateSuccessUpdateTime
    } = ConfigsStore;

    const config = this.getElementByIdx(rowIndex);
    const { _id: id = null, hash = null } = config.internal;

    try {
      if (id && !config.isNewAddedElement) {
        const {data} = yield configsApi.removeElement({
          projectid,
          environment,
          elementName,
          id,
          hash,
          successUpdateTime,
        });

        updateSuccessUpdateTime(data.successUpdateTime);
      }

      configs.data.splice(rowIndex, 1);
      updateUniqueValues();

    } catch (error) {
      console.log(error);
    }
  }

  getDefaultInitValues = () => {
    const { schema } = SchemaStore;
    const { language } = ConfigsViewStore;
    schema && Object.entries(schema.properties).forEach(([key, value]) => {
      if (value.first_init) {
        const defaultValue = value.init;
        const defaultParameterValue = getDefaultParameterValue(value, defaultValue);
        this.defaultInitObject = {
          ...this.defaultInitObject,
          [key]: defaultParameterValue
        }
      }
    });

    if (language === LANGUAGE.JS) {
      this.defaultInitObject.body = '';
    }
  }

  *changeElement(rowIndex, fields, elementName) {
    const { projectid, environment } = GamesStore;
    const { successUpdateTime, setFields, setIsNeedUpdateConfigs } = ConfigsStore;

    const config = this.getElementByIdx(rowIndex);
    const { _id: id, hash } = config.internal;

    fields = setFields(fields);

    setIsNeedUpdateConfigs(config.data.namespace !== fields.namespace)

    try {
      const { data } = yield configsApi.changeElement(
        { projectid, environment, elementName, id, hash, fields, successUpdateTime });

      this.setName(fields);
      this.updateConfigValues(config, data, fields);
    } catch (error) {
      console.log(error);
    }
  }

  setName(fields) {
    if (fields?.body && typeof fields.body === 'string') {
      const name = getNameFromFunction(fields.body);
      fields.name = name;
    }
  }

  clearConfigElement() {
    this.defaultInitObject = {};
  }

}

export default new ConfigElementStore();
import { Fragment, useCallback, useEffect, useState } from "react";
import { observer } from 'mobx-react';
import { Button, Menu, MenuButton, MenuList, MenuItem, MenuDivider, Tooltip } from "@chakra-ui/react";
import { ConfigsStore, MenuPageSelectorsStore } from "../../stores";
import { Plus, Menu as MenuIcon } from "iconoir-react";
import AddPageSelectorModal from "./AddPageSelectorModal";
import './style.scss';
import { handleEventWithChanges } from "src/helpers/handleEventWithChanges";

const MenuPanel = observer(() => {
  const {
    setPageSelector,
    pageSelectors,
    removePageSelector,
    nameForAddingNewPageSelector,
    addPageSelector,
    addNewPageSelectorWithModal,
    handleRemovePageSelector,
    typeForAddingNewPageSelector,
    handleAddPageSelector,
    currentPageSelectorIndex,
    headerPageSelector,
    currentPageSelector,
    handleChangeSelector,
    isAddPageSelectorDisable,
    hintForDisableAdd
  } = MenuPageSelectorsStore;

  const [isOpenModal, setIsOpenModal] = useState(false);
  const {isConfigChanges} = ConfigsStore;

  const [searchStr, setSearchStr] = useState('');
  const [list, setList] = useState([]);

  const isDisableAddPageSelector =
    pageSelectors.find(selector => selector.name === nameForAddingNewPageSelector) || isAddPageSelectorDisable;

  const handleDelete = (event, idx) => {
    event.stopPropagation();
    clearSearch();
    if (pageSelectors.length > 1) {
      if (handleRemovePageSelector) {
        handleRemovePageSelector(idx);
      }
      removePageSelector(idx);
    }
  }

  const handleAdd = () => {
    if (!isDisableAddPageSelector) {
      addPageSelector(nameForAddingNewPageSelector, true, typeForAddingNewPageSelector, true);
    }
    if (handleAddPageSelector) {
      handleAddPageSelector();
    }
  }

  const changePage = (idx) => {
    setPageSelector(idx);
    if (handleChangeSelector) {
      handleChangeSelector(pageSelectors[idx])
    }
  }

  const handleChangePageSelector = (idx) => {
    handleEventWithChanges(isConfigChanges, () => changePage(idx))
  }

  const clearSearch = useCallback(() => {
    setSearchStr('');
    setList(pageSelectors);
  }, [pageSelectors]);

  const clearBackspaceEvent = useCallback((event) => {
    if (event.key === 'Backspace') {
      clearSearch();
    }
  }, [clearSearch]);

  useEffect(() => {
    window.addEventListener('keydown', clearBackspaceEvent);
    return () => {
      window.removeEventListener('keydown', clearBackspaceEvent);
    };
  }, [clearBackspaceEvent]);

  const handleKeyDown = (event) => {
    if ((event.which >= 65 && event.which <= 90)
      || (event.which >= 48 && event.which <= 57)
      || (event.which >= 96 && event.which <= 105))
    {
      const newSearchStr = searchStr + event.key;
      setList(list.map(listElement => listElement.name.startsWith(newSearchStr) ? listElement : null));
      setSearchStr(newSearchStr)
    }
  }

  const handleClick = () => {
    clearSearch();
  }

  return (
    <Fragment >
      {pageSelectors.length ? (
        <Menu closeOnSelect={false} id="panel">
          <MenuButton as={Button} rightIcon={<MenuIcon />} minW="auto" onClick={handleClick}>
            Current {headerPageSelector}: {currentPageSelector.name}
          </MenuButton>
          <MenuList className="menu-panel" onKeyDown={handleKeyDown}>
          {nameForAddingNewPageSelector
              ? <>
                <Tooltip label={isDisableAddPageSelector ? hintForDisableAdd : ''}>
                  <div>
                    <MenuItem
                      variant="outline"
                      ml="5px"
                      className="add-button"
                      isDisabled={isDisableAddPageSelector}
                      onClick={() => addNewPageSelectorWithModal
                        ? setIsOpenModal(true)
                        : handleAdd()
                      }
                    >
                      {`Add ${headerPageSelector} `}<Plus />
                    </MenuItem>
                  </div>
                </Tooltip>
                <MenuDivider />
              </>
              : null}
            {list.map((item, idx) => {
              return (
                item
                  && <MenuItem
                      className={currentPageSelectorIndex === idx ? 'selected' : ''}
                      key={idx}
                      onClick={() => handleChangePageSelector(idx)}
                      command={
                        <div>
                          {item.closable && (
                            <span
                              className="menu-block__delete-icon"
                              onClick={(e) => handleDelete(e, idx)}
                            >
                              ✕
                            </span>
                          )}
                        </div>
                      }
                    >
                      <span className='menu-block__name'>{item.name}</span>
                    </MenuItem>
                )
            })}

          </MenuList>
        </Menu>
      ) : null}
      <AddPageSelectorModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
      />
    </Fragment>
  )
});

export default MenuPanel
import {
  Checkbox,
  Flex,
  FormLabel,
  Heading,
  HStack,
  Input
 } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useState, useRef } from "react";
import ButtonWithTooltip from "src/components/ButtonWithTooltip";
import { DEFAULT_BG_COLOR } from "src/constants";
import { getFileByLang, LANGUAGE } from "src/helpers/configLanguageHelper";
import { getConfigFile } from "../../../helpers/downloadConfig";
import { uploadFile } from "../../../helpers/uploadConfig";
import { ConfigElementStore, ConfigsViewStore, ConfigsStore, MenuPageSelectorsStore } from "../../../stores";
import ActionConfirmation from "../ActionConfirmation";
import UploadConfigModal from "./UploadConfigModal";
import ShowFilterButton from "src/components/Filter/ShowFilterButton";
import FilterBlock from "./FiltersBlock";
import { Settings } from "iconoir-react";
import ChangeVisibilityForColumns from "src/components/ChangeVisibilityForColumns";
import { ALWAYS_HIDDEN_COLUMNS } from "./constants";

const HeadingConfigButtons = observer(({
  elementName,
  configName,
  handleChangeForChecked,
  load,
  handleLoadConfigs,
  onToggle,
  isOpen,
  onClose,
  visibleColumns,
  updateVisibleColumns,
  columns,
  headerName
}) => {
  const { saveAllConfigList, removeChecked, isConfigChanges, loadConfigList, uploadConfigList } = ConfigsStore;
  const { addEmptyElement } = ConfigElementStore;
  const { currentPageSelectorName } = MenuPageSelectorsStore;

  const { checkedConfigs, addExpandedElement, isUseNamespace, language, isJSONLang } = ConfigsViewStore;

  const [groupByNamespace, setGroupByNamespace] = useState(false);
  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
  const [uploadData, setUploadData] = useState([]);

  const hiddenColumnsDefault = columns.filter(column => !ALWAYS_HIDDEN_COLUMNS.includes(column.Header));

  const addNewElement = () => {
    addEmptyElement();
    addExpandedElement();
  }
  const uploadFiles = useRef();

  const getFile = (event) => {
    uploadFile(event, language).then((data) => {
      uploadFiles.current.value = '';
      if (data) {
        setIsOpenUploadModal(true)
        setUploadData(data);
      }
    })
  };

  const getConfigDataByLang = (lang, config) => {
    switch (lang) {
      case LANGUAGE.JS:
        return config.data
      default:
        return config
    }
  }

  const getConfig = async () => {
    const config = await loadConfigList(elementName, groupByNamespace, configName);
    getConfigFile(
      getConfigDataByLang(language, config),
      language
    );
  };

  return (
    <HStack
      spacing={1}
      p={1}
      position="sticky"
      zIndex={1000}
      top={0}
      flexWrap="wrap"
      backgroundColor={DEFAULT_BG_COLOR}
    >
      <Flex w={"100%"} flexWrap="wrap">
        <Heading as="h3" size="lg" mr={5}>
          {headerName}
        </Heading>

        <ButtonWithTooltip
          variant="outline"
          onClick={addNewElement}
          accessPermission={`can_change_${configName}`}
        >
          Add {elementName}
        </ButtonWithTooltip>

        <ButtonWithTooltip
          variant="outline"
          isDisabled={!isConfigChanges}
          onClick={() => saveAllConfigList(elementName)}
          accessPermission={`can_change_${configName}`}
        >
          Save all
        </ButtonWithTooltip>

        {isJSONLang &&
          <ButtonWithTooltip
            variant="outline"
            isDisabled={!checkedConfigs.size}
            onClick={handleChangeForChecked}
            accessPermission={`can_change_${configName}`}
          >
            Change selected
          </ButtonWithTooltip>
        }

        <ActionConfirmation
          marginRight='0'
          variant='outline'
          handleClick={() => removeChecked(elementName)}
          dialogHeader={`Remove selected ${elementName}s elements`}
          dialogBody={`Confirm selected ${elementName}s removal?`}
          accessPermission={`can_change_${configName}`}
          isDisabled={!checkedConfigs.size}
          value={'Delete selected'}
        />

        <ButtonWithTooltip
          variant="outline"
          style={{ marginLeft: "auto" }}
          accessPermission={`can_change_${configName}`}
        >
          <Input
            type="file"
            height="100%"
            width="100%"
            position="absolute"
            top="0"
            left="0"
            opacity="0"
            aria-hidden="true"
            accept={getFileByLang(language).accept}
            onChange={getFile}
            ref={uploadFiles}
          />
          Upload config
        </ButtonWithTooltip>

        {!isUseNamespace
          ? <ButtonWithTooltip
              variant="outline"
              marginRight='0'
              onClick={getConfig}
            >
              Download config
            </ButtonWithTooltip>
          : <ActionConfirmation
              marginRight='0'
              variant='outline'
              handleClick={getConfig}
              dialogHeader={`Grouping by namespace?`}
              dialogBody={
                <Flex>
                  <FormLabel mb={0}>Group by namespace</FormLabel>
                  <Checkbox
                    isChecked={groupByNamespace}
                    onChange={(e) => setGroupByNamespace(e.target.checked)}
                  />
                </Flex>
              }
              value={'Download config'}
            />
        }
        <ShowFilterButton />
        <ButtonWithTooltip
          size="sm"
          ml="5px"
          tooltipValue="Change visibility for configs fields"
          onClick={onToggle}
        >
          <Settings/>
        </ButtonWithTooltip>
        <ChangeVisibilityForColumns
          columns={hiddenColumnsDefault}
          isOpen={isOpen}
          className="configs-block"
          onClose={onClose}
          visibleColumns={visibleColumns}
          updateVisibleColumns={updateVisibleColumns}
        />
      </Flex>
      <FilterBlock handleLoadConfigs={(query) => handleLoadConfigs(currentPageSelectorName, query)} />
      <UploadConfigModal
        isOpen={isOpenUploadModal}
        uploadData={uploadData}
        setIsOpen={setIsOpenUploadModal}
        elementName={elementName}
        reload={load}
        loadAction={(name, namespace) => loadConfigList(name, namespace, configName)}
        uploadAction={(uploadData) => uploadConfigList(uploadData, elementName, configName)}
      />
    </HStack>
  );
})

export default HeadingConfigButtons;